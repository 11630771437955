import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';
import Report_Individual from './Report_Individual';
import Reports_Infinitel from './Reports_Infinite';

const Reports = () => {
    const [searchParems] = useSearchParams();
    const reportId = searchParems.get("Id");
    
    return reportId ? <Report_Individual reportId={reportId} /> : <Reports_Infinitel />
}

export default Reports;