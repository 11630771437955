import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/navbar";
import Insights from "./components/insights";
import Footer from "./components/footer";
import Errorpage from "./components/errorpage";
import PrivacyPolicy from "./components/footer/privacyPolicy";
import CookiePolicy from "./components/footer/cookiePolicy";
import Login from "./components/auth/login";
import Register from "./components/auth/register";
import ResetpasswordPage from "./components/auth/ResetpasswordPage.jsx";
import PeopleIntelligence from "./components/peopleIntelligence/peopleIntelligence";
import SectorIntelligence from "./components/sectorIntelligence/sectorIntelligence";
import CompanyIntelligence from "./components/companyIntelligence/companyIntelligence";
import InvestorIntelligence from "./components/investorIntelligence/investorIntelligence";
import NewsIntelligence from "./components/news/newsIntelligence";
import DealIntelligence from "./components/dealsIntelligence/dealIntelligence";
import PatentIntelligence from "./components/patents/patentIntelligence";
import Leaderboards from "./components/leaderboards/leaderboards";
import MyFeed from "./components/myFeed/MyFeed.jsx";
import SideBar from "../src/components/sideBarMain/index.jsx";
import axios from "axios";
import { TRACK_USER_API } from "./constant/api.js";
import { useAuth } from "./components/auth/authContext.js";
import Profile from "./components/profile/Profile.jsx";
import ChatWithGroot from "./components/chatWithGroot/ChatWithGroot.jsx"

const NotSidebarUrls = [
  "/",
  "/factacy/privacy-policy",
  "/factacy/cookie-policy",
  "/login",
  "/register",
  "/resetpassword"
];


function App() {
  const location = useLocation();
  const { user } = useAuth();
  const isHomepage = location.pathname === "/";
  const isLoginPage = location.pathname === "/login";
  const isRegisterPage = location.pathname === "/register";
  const [selectedPage, setSelectedPage] = useState("home");
  const [isTopOfPage, setIsTopOfPage] = useState(true);


  useEffect(() => {
    async function track() {
      try {
        if (user.isAuthenticated) {
          const res = await axios.post(TRACK_USER_API, {
            email: sessionStorage.getItem("email"),
            site: window.location.href
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
    track();
  }, [window.location.href]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) setIsTopOfPage(true);
      if (window.scrollY !== 0) setIsTopOfPage(false);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (

    <div
      className="w-screen flex flex-col relative"
      style={{
        backgroundImage: "linear-gradient(to bottom, #BAE0FF 60%, #fff)"
      }}
    >
      {!isLoginPage && !isRegisterPage && (
        <Navbar
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          isTopOfPage={isTopOfPage}
        />
      )}

      {/* <ChatWithGroot /> */}
      <div
        className={` flex ${
          !NotSidebarUrls.includes(window.location.pathname)
            ? "flex-row"
            : "flex-col"
        } w-screen h-screen`}
      >
        {!NotSidebarUrls.includes(window.location.pathname) && <SideBar />}
        <div
          className={`${
            !NotSidebarUrls.includes(window.location.pathname)
              ? " lg:w-5/6 sm:w-full"
              : "w-full"
          }`}
        >
          <Routes>
            <Route path="/" element={<Insights />} />
            {/* <Route path="/my-profile" element={<Profile />} /> */}
            <Route
              path="/people-intelligence/*"
              element={<PeopleIntelligence />}
            />
            <Route
              path="/patent-intelligence/*"
              element={<PatentIntelligence />}
            />
            <Route path="/news-intelligence/*" element={<NewsIntelligence />} />
            <Route path="/deal-intelligence/*" element={<DealIntelligence />} />
            <Route
              path="/investor-intelligence/*"
              element={<InvestorIntelligence />}
            />
            <Route
              path="/company-intelligence/*"
              element={<CompanyIntelligence />}
            />
            <Route
              path="/sector-intelligence/*"
              element={<SectorIntelligence />}
            />
            <Route path="/leaderboards/*" element={<Leaderboards />} />

            <Route path="/factacy/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/factacy/cookie-policy" element={<CookiePolicy />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/resetpassword" element={<ResetpasswordPage />} />
            <Route path="/home/*" element={<MyFeed />} />
            <Route path="*" element={<Errorpage />} />
          </Routes>
        </div>
        {isHomepage && <Footer />}
      </div>
    </div>

  );
}

export default App;
