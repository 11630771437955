import React, { useEffect, useState } from 'react';
import Loader from "../../tearSheet/loader";
import IndividualNews from "../../IndividualNews";
import useFetchInvestorActions from "../../../hooks/useFetchInvestorActions";
import { useSelector } from 'react-redux';
import { useAuth } from '../../auth/authContext';
import useAddMetaTagsInvestor from '../../../hooks/MetaTagsAdd/useAddMetaTagsInvestor';

const InvestorNews = ({ investorId , investor}) => {
    const [loading, setLoading] = useState(false);
    const { getInvestorNews } = useFetchInvestorActions();
    const artIds = useSelector(store => store?.investor?.investorDealsData?.[investorId]?.map(item => item?.Art_Id)?.flat().join(","));
    const news = useSelector(store => store?.investor?.investorNews?.[investorId]);

    useEffect(() => {
        if (!news) {
            setLoading(true);
            getInvestorNews(artIds, investorId)
                .then(res => setLoading(false))
                .catch(res => setLoading(false));
        }
    }, [artIds]);

  useAddMetaTagsInvestor(investor, "News & Insights", news?.[0]?.summary_of_article)

    return (
        <div
            className={`section bg-white w-full rounded-b-xl`}
            style={{
                boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)",
            }}
        >
            {loading ? (<div className="flex flex-col items-center justify-center min-h-[24rem]">
                <Loader />
            </div>) : (<div className="flex flex-col w-full gap-5">
                {news
                    ? news.map((news, index) => {
                        if (!news || !news?.Unique_date_time) {
                            return null;
                        }
                        return <IndividualNews news={news} />;
                    })
                    : "No results found :("}
                <div className="flex justify-center p-3"></div>
            </div>)}
        </div>
    )

}

export default InvestorNews;