import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_HOME_NEWS } from '../../store/slices/news_intelligence';
import { HOME_NEWS } from '../../constant/api';

const useFetchHomeNews = () => {
    const dispatch = useDispatch();
    const homeNewsAlreadyExists = useSelector(store => store?.news_intelligence?.homeNews?.length > 0 ? true : false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const homeNewsFetcher = async () => {
        if (!homeNewsAlreadyExists) {
            try {
                setLoading(true);
                const res = await fetch(HOME_NEWS);
                const data = await res.json();

                if (res.ok) {
                    const newslist = data[0];
                    dispatch(ADD_HOME_NEWS({ newslist }));
                    setLoading(false)
                }
                else {
                    setLoading(false)
                    setError("Somthing Went wrong");
                }

            }
            catch (err) {
                setLoading(false)
                setError("Somthing Went wrong");
            }
        }

    }
    return [loading, error, homeNewsFetcher];
}

export default useFetchHomeNews;