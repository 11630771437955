import React from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  INVESTOR_SEARCH_LOADING,
  INVESTOR_SEARCH_FAIL,
  INVESTOR_DATA_SUCCESS,
  INVESTOR_DEALS_SUCCESS,
  INVESTOR_COHORT_SUCCESS,
  INVESTOR_NEWS_SUCCESS,
  INVESTOR_SECTOR_CHART,
} from "../store/slices/investorReducer";
import { COHORT_BY_INVESTOR_NAME, GET_ALL_DEALS, GET_INVESTOR, GETARTS } from "../constant/api";

const useFetchInvestorActions = (investorId, investorDealsData) => {
  const doesInvestorDataExist = useSelector(
    (state) => state.investor.investorData[investorId]
  );
  const doesInvestorNewsExist = useSelector(
    (state) => state.investor
  ).investorNews;

  const Art_Id = investorDealsData?.map((inv) => inv?.Art_Id);
  const dispatch = useDispatch();

  const getInvestor = async (investor) => {
    try {
      if (!doesInvestorDataExist) {
        dispatch(INVESTOR_SEARCH_LOADING());
        const res = await axios.get(
         GET_INVESTOR + investor,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application",
            },
          }
        );
        dispatch(
          INVESTOR_DATA_SUCCESS({ data: res.data, investorName: investor })
        );

        if (res.data[0].Investor_Deal_Ids) {
          const formattedIds = res.data[0].Investor_Deal_Ids?.join(",");

          try {
            const ress = await axios.get(
              GET_ALL_DEALS + formattedIds,
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application",
                },
              }
            );
            dispatch(
              INVESTOR_DEALS_SUCCESS({
                investorDealsData: ress.data,
                investorName: res.data[0].id,
              })
            );

            const summaryICArrays =
              ress.data && ress.data.map((item) => item?.Sector);

            if (
              summaryICArrays &&
              Array.isArray(summaryICArrays) &&
              summaryICArrays.length > 0
            ) {
              const allSummaryICValues = summaryICArrays.flat();
              const uniqueSectors = {};
              allSummaryICValues.forEach((summaryIC) => {
                if (!uniqueSectors[summaryIC]) {
                  uniqueSectors[summaryIC] = 1; // Initialize count for new sector
                } else {
                  uniqueSectors[summaryIC] += 1; // Increment count for existing sector
                }
              });
              const valueArrayData = Object.keys(uniqueSectors).map(
                (sector) => ({
                  sector,
                  count: uniqueSectors[sector],
                })
              );

              dispatch(
                INVESTOR_SECTOR_CHART({
                  investorSectorsChartData: valueArrayData,
                  investorId: res.data[0].id,
                })
              );
            }
          } catch (err) {}
        }
      }
    } catch (err) {
      dispatch(INVESTOR_SEARCH_FAIL());
    }

    return "";
  };

  const getCohortData = async (investorName) => {
    try {
      const response = await axios.get( COHORT_BY_INVESTOR_NAME + investorName);
      dispatch(INVESTOR_COHORT_SUCCESS({ peopleCohort: response?.data[0] }));
      dispatch(INVESTOR_COHORT_SUCCESS({ companyCohort: response?.data[1] }));
    } catch (e) {}
  };

  const getInvestorNews = async (Art_Id, investorId) => {
    const formattedIds = Array.isArray(Art_Id) ? Art_Id?.join(",") : Art_Id;
    try {
      const res = await fetch(
       GETARTS +  formattedIds,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application",
          },
        }
      );
      const data = await res.json();
      if (data && data != [] && data.length > 0) {
        dispatch(
          INVESTOR_NEWS_SUCCESS({
            investorNews: data,
            investorId,
          })
        );
      }

      // }
    } catch (err) {}
  };

  return { getInvestor, getCohortData, getInvestorNews };
};

export default useFetchInvestorActions;
