import { configureStore } from '@reduxjs/toolkit';
import companyReducer from './slices/companyReducer';
import investorReducer from './slices/investorReducer';
import personReducer from './slices/personReducer';
import sectorReducerr from './slices/sectorReducer';
import stageReducer from './slices/stageReducer';
import news_intelligence from './slices/news_intelligence';

const store = configureStore({
    reducer: {
        news_intelligence,
        company : companyReducer,
        investor: investorReducer,
        person : personReducer,
        sector : sectorReducerr,
        stage : stageReducer,
    },
});

export default store;