export const falseValues = [
  "NO",
  "No",
  "no",
  "None",
  "none",
  "NONE",
  "NULL",
  "Null",
  "null",
  undefined,
  null
];


