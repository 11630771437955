import React, { useRef, useEffect, useState } from 'react';
import { goToCompanyPage } from '../../constant/function';
import useFetch from '../../hooks/useFetch';
import Loader from '../tearSheet/loader';
import { Alphanumeric_Date } from '../../constant/function';
import { falseValues } from '../../constant/constant';
import { SINGLE_REPORT } from '../../constant/api';

const Sumary = ({ data }) => {


  const P = ({ item }) => <span>{item}</span>;

  if (!falseValues.includes(data.Reference_Id) && !falseValues.includes(data.Company_Name)) {
    const Clickable = () => <span className=' text-primaryBlue font-semibold cursor-pointer' 
    onClick={() => goToCompanyPage(data?.Reference_Id, [data?.Company_Name], data?.Brand_Id, [data?.Brand_Name])}>
      {" " + data?.Brand_Name + " "}</span>;
    const Deal_Summary = data?.Deal_Summary?.split(data?.Brand_Name);

    return <div>
      {Deal_Summary.map((item, index) => {
        if (Deal_Summary?.length - 1 === index) {
          return (<React.Fragment key={index}><P item={item} /></React.Fragment>)
        } else {
          return (<React.Fragment key={index}><P item={item} /><Clickable /></React.Fragment>)
        }
      })}
    </div>
  }
  else {
    return <><P item={data?.Deal_Summary} /></>
  }

}
const Report_Individual = ({ reportId }) => {

  const [existingMetaTags, setExistingMetaTags] = useState({
    description: document?.querySelector('meta[name="description"]')?.getAttribute("content"),
    keywords: document?.querySelector('meta[name="keywords"]')?.getAttribute("content"),
    title: document.getElementsByTagName("title")[0].innerText,
  });

  const [loading, error, reports, reportFetcher] = useFetch(SINGLE_REPORT + `?id=${reportId}`);
  const brandNames = [];
  reports?.[0]?.Summary_Data?.forEach((item) => !brandNames.includes(item?.Brand_Name) && brandNames.push(item?.Brand_Name));
  const heading = `${brandNames.join(', ')} - Deals tracked by factacyinsights.com`;

  useEffect(() => {

    if (reports?.[0]) {
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", reports[0].Introduction);
      document
        .querySelector('meta[name="keywords"]')
        .setAttribute("content", reports[0]?.keywords);

      document.getElementsByTagName("title")[0].innerText = heading;
    }


    return () => {
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", existingMetaTags.description);

      document
        .querySelector('meta[name="keywords"]')
        .setAttribute("content", existingMetaTags.keywords);

      document.getElementsByTagName("title")[0].innerText =
        existingMetaTags.title;
    };
  }, [reports]);

  useEffect(() => {
    reportFetcher();
    const linkTag = document.createElement("link");
    linkTag.setAttribute("rel", "canonical");
    linkTag.href = window.location.href;
    document.head.appendChild(linkTag);

  }, []);

  return loading ?
    (<div className=' w-full flex justify-center'> <Loader /></div>)
    :
    (
      <div className=" m-2 mt-14 lg:mt-2 p-4 lg:p-8 flex flex-col gap-2 min-h-screen bg-white rounded-lg">
        <h1 className='text-3xl font-bold '>{heading}</h1>
        <span className=" px-2 py-1 rounded-[3px] bg-gray-100 w-fit text-[0.8rem]">{Alphanumeric_Date(reports?.[0]?.Epoch * 1000)?.split("-").join(" ")}</span>
        <p className=' text-xl font-semibold'>{reports?.[0]?.Introduction}</p>
        <div className=' flex flex-col gap-5'>
          <div className=' flex flex-col gap-5'>
            {reports?.[0]?.Summary_Data?.map((item, index) => <Sumary data={item} key={index} />)}
          </div>
        </div>
        <p className=' text-lg'>{reports?.[0]?.Conclusion}</p>
        <p className=' my-2 italic'>Factacy Insights' AI engines track deals and investments to give you financial insights round the clock. Please sign up on <a href='https://www.factacyinsights.com/' className=' text-primaryBlue'>www.factacyinsights.com </a>
          and gain from our AI powered Financial Intelligence Platform.</p>
      </div>)
}
export default Report_Individual;