import React from "react";
import DealCard from "../../pages/dealCard";
import { motion, useAnimation } from "framer-motion";

const DealModal = ({ isOpen, onClose, deal }) => {
  return (
    <>
      {/* Overlay */}
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.5 }}
          transition={{ duration: 0.25, ease: "easeInOut" }}
          className="fixed inset-0 bg-black z-50"
        ></motion.div>
      )}

      {/* Modal */}
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.25, ease: "easeInOut" }}
          className="fixed inset-0 flex items-center justify-center z-50"
        >
          <div className="bg-white w-max h-max p-6 rounded-lg shadow-lg transition-all duration-300">
            <div className="flex justify-end pb-3">
              <button
                className="text-gray-600 hover:text-gray-800"
                onClick={onClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div>
              <DealCard sortedData={deal} />
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default DealModal;
