import React from 'react'
import closeLine from '../../assets/close-icon.svg'
import { useState } from 'react';

import useFetchSearchSuggestions from "../../hooks/Search/useFetchSearchSuggestions";
import { goToCompanyPage } from '../../constant/function';

const CompanySearch = () => {
  const [input, setInput] = useState('')
  const [loading, error, { companySuggestions }] = useFetchSearchSuggestions(input);

  return (
    <div className='w-full flex flex-col items-center pt-40 h-full'>
      <div className='px-4 w-full lg:px-0 lg:w-1/2 h-full flex flex-col gap-3 items-center'>

        <div className={`w-full bg-white rounded-xl`}
          // required
          // target="_blank"
          style={{ boxShadow: "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)" }}>
          <div className="flex justify-between h-12 gap-3 px-4 items-center">
            <svg
              width="23"
              height="21"
              viewBox="0 0 27 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.5214 18.7084L26.0029 24.1899L24.1928 26L18.7113 20.5184C16.7404 22.0953 14.2408 23.0386 11.5222 23.0386C5.16358 23.0386 0.00292969 17.878 0.00292969 11.5193C0.00292969 5.16065 5.16358 0 11.5222 0C17.8809 0 23.0415 5.16065 23.0415 11.5193C23.0415 14.2379 22.0982 16.7374 20.5214 18.7084ZM17.9535 17.7587C19.5186 16.1457 20.4817 13.9455 20.4817 11.5193C20.4817 6.56921 16.4723 2.55985 11.5222 2.55985C6.57213 2.55985 2.56278 6.56921 2.56278 11.5193C2.56278 16.4694 6.57213 20.4788 11.5222 20.4788C13.9485 20.4788 16.1486 19.5156 17.7616 17.9505L17.9535 17.7587Z"
                fill="#71717A"
              />
            </svg>

            <input
              placeholder="Search for Tearsheet indication on startups, companies, vendors, clients and more"
              className="h-12 w-full appearance-none focus:outline-none uppercase"
              required
              type="text"
              onChange={(e) => setInput(e.target.value)}
              value={input}
            ></input>

            {input.length > 2 &&
              <button onClick={() => setInput('')}><img src={closeLine} /></button>
            }

          </div>
          <div className="">
            {input.length > 2 && (
              <div className="bg-white w-full gap-2 h-full rounded-b-xl relative z-0">
                <ul
                  className={` uppercase h-72 overflow-y-auto`}
                >
                  {companySuggestions?.length > 0 ? companySuggestions?.map((company, index) => (
                    <li
                      key={index}
                      className="cursor-pointer sm:text-xs lg:text-base px-4 py-2 hover:bg-slate-50 flex gap-1"
                      onClick={(e) => {
                        e.preventDefault();
                        goToCompanyPage(company.ID, company.NAME, company.BRAND_ID, company.ALIAS)
                      }}
                    >
                      {company.ALIAS?.[0] !== "Null" ? (
                        <p className="text-primaryBlue">
                          {company.ALIAS + " -"}{" "}
                        </p>
                      ) : (
                        ""
                      )}
                      {company.NAME}
                    </li>
                  )) : <p className="sm:text-xs lg:text-base px-4 py-2 ">No results found.</p>}
                </ul>

              </div>
            )}
          </div>
        </div>
      </div>

    </div>
  )
}

export default CompanySearch