import axios from "axios";
import { PERSON_SEARCH_LOADING, PERSON_DATA_SUCCESS, PERSON_SEARCH_FAIL } from '../store/slices/personReducer';
import { useDispatch } from 'react-redux';
import { GET_PERSON } from "../constant/api";

const useFetchPersionActions = () => {
    const dispatch = useDispatch();

    return async (person) => {
        const options4 = {
            url: GET_PERSON + person,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/txt;charset=UTF-8'
            },
            data: {}
        };
        try {

            dispatch(PERSON_SEARCH_LOADING());
            const personResponse = await axios(options4)
            dispatch(PERSON_DATA_SUCCESS({ data : personResponse.data, personName: person }));
        }
        catch (e) {
            dispatch(PERSON_SEARCH_FAIL());
        }
    }
}

export default useFetchPersionActions;