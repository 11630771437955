import React from "react";
import { useState } from "react";
import useFetchInvestorActions from "../../hooks/useFetchInvestorActions";
import {
  goToCompanyPage,
  goToInvestorPage,
  howOldTimeUpdater,
} from "../../constant/function";
import { Sector_Pill } from "../../constant/Tiny_Components";


const Summary = ({
  data,
  cin,
  lei,
  input,
  setActiveSection,
  competitorDetails,
  businessNews,
}) => {
  const { getInvestor } = useFetchInvestorActions();


  // CAPITALIZE INITIAL CHARACTER
  function capitalizeFirstLetter(str) {
    return str
      ?.toLowerCase()
      ?.split(" ")
      ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
      ?.join(" ");
  }

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const handleInvestorClick = async (investor, name) => {
    getInvestor(investor);
    if (investor !== "NULL" && investor !== "NUll") {
      goToInvestorPage(name, investor);
    } else {
      setShowErrorMessage(true);
      setTimeout(() => {
        setShowErrorMessage(false);
      }, 3000);
    }
  };

  return (
    <div className="bg-white sm:w-full rounded-b-xl flex flex-col lg:p-8 sm:p-4 gap-6">
      <div className="flex flex-col gap-4">
        <p className="leading-6 lg:text-base sm:text-sm text-[#71717A]">
          {data[0]?.Summary ? data[0]?.Summary : ""}
        </p>
      </div>

      <div className="flex lg:flex-row sm:flex-col gap-3 w-full justify-between">
        <div className="bg-[#E8F5FF] flex flex-col gap-6 p-4 rounded-xl lg:w-1/5 sm:w-full">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_6_1721)">
              <path
                d="M18.364 17.9021L12 24.266L5.63604 17.9021C2.12132 14.3873 2.12132 8.68885 5.63604 5.17413C9.15076 1.65941 14.8492 1.65941 18.364 5.17413C21.8787 8.68885 21.8787 14.3873 18.364 17.9021ZM12 15.5381C14.2091 15.5381 16 13.7472 16 11.5381C16 9.32895 14.2091 7.53809 12 7.53809C9.79086 7.53809 8 9.32895 8 11.5381C8 13.7472 9.79086 15.5381 12 15.5381ZM12 13.5381C10.8954 13.5381 10 12.6427 10 11.5381C10 10.4335 10.8954 9.53809 12 9.53809C13.1046 9.53809 14 10.4335 14 11.5381C14 12.6427 13.1046 13.5381 12 13.5381Z"
                fill="#3E91EE"
              />
              <path
                d="M18.364 17.9011L12 24.265L5.63604 17.9011C2.12132 14.3863 2.12132 8.68787 5.63604 5.17315C9.15076 1.65843 14.8492 1.65843 18.364 5.17315C21.8787 8.68787 21.8787 14.3863 18.364 17.9011ZM12 15.5371C14.2091 15.5371 16 13.7462 16 11.5371C16 9.32797 14.2091 7.53711 12 7.53711C9.79086 7.53711 8 9.32797 8 11.5371C8 13.7462 9.79086 15.5371 12 15.5371Z"
                fill="#3E91EE"
              />
            </g>
            <defs>
              <clipPath id="clip0_6_1721">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(0 0.537598)"
                />
              </clipPath>
            </defs>
          </svg>
          <p className="font-bold font-roboto">
            {capitalizeFirstLetter(data[0]?.State) ??
              data[0]?.City ??
              "Unconfirmed"}
          </p>
        </div>

        <div className="bg-[#F5FFF8] flex flex-col gap-6 p-4 rounded-xl lg:w-1/5 sm:w-full">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 12.5376H7V21.5376H3V12.5376ZM17 8.5376H21V21.5376H17V8.5376ZM10 2.5376H14V21.5376H10V2.5376Z"
              fill="#B5EEC3"
            />
            <path
              d="M3 12.5371H7V21.5371H3V12.5371ZM17 8.53711H21V21.5371H17V8.53711Z"
              fill="#B5EEC3"
            />
          </svg>
          <p className="font-bold font-roboto">
            {capitalizeFirstLetter(data[0]?.Company_Type) ?? "Unconfirmed"}
          </p>
        </div>

        <div className="bg-[#FFFFE6] flex flex-col gap-6 p-4 rounded-xl lg:w-1/5 sm:w-full">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 22.5405C6.47716 22.5405 2 18.0634 2 12.5405C2 7.01768 6.47716 2.54053 12 2.54053C17.5228 2.54053 22 7.01768 22 12.5405C22 18.0634 17.5228 22.5405 12 22.5405ZM12 20.5405C16.4183 20.5405 20 16.9588 20 12.5405C20 8.12225 16.4183 4.54053 12 4.54053C7.58172 4.54053 4 8.12225 4 12.5405C4 16.9588 7.58172 20.5405 12 20.5405ZM8.5 14.5405H14C14.2761 14.5405 14.5 14.3167 14.5 14.0405C14.5 13.7644 14.2761 13.5405 14 13.5405H10C8.61929 13.5405 7.5 12.4212 7.5 11.0405C7.5 9.65982 8.61929 8.54053 10 8.54053H11V6.54053H13V8.54053H15.5V10.5405H10C9.72386 10.5405 9.5 10.7644 9.5 11.0405C9.5 11.3167 9.72386 11.5405 10 11.5405H14C15.3807 11.5405 16.5 12.6598 16.5 14.0405C16.5 15.4212 15.3807 16.5405 14 16.5405H13V18.5405H11V16.5405H8.5V14.5405Z"
              fill="#FAE243"
            />
            <path
              d="M12 22.54C6.47716 22.54 2 18.0629 2 12.54C2 7.01719 6.47716 2.54004 12 2.54004C17.5228 2.54004 22 7.01719 22 12.54C22 18.0629 17.5228 22.54 12 22.54ZM12 20.54C16.4183 20.54 20 16.9583 20 12.54C20 8.12176 16.4183 4.54004 12 4.54004C7.58172 4.54004 4 8.12176 4 12.54C4 16.9583 7.58172 20.54 12 20.54Z"
              fill="#FAE243"
            />
          </svg>
          <p className="font-bold font-roboto">
            {" "}
            {data &&
            data.length > 0 &&
            data[0]?.Amount &&
            Array.isArray(data[0]?.Series)
              ? `(${data[0]?.Amount}) ${data[0]?.Series[0]}`
              : "Unconfirmed"}
          </p>
        </div>

        <div className="bg-[#FFECEC] flex flex-col gap-6 p-4 rounded-xl lg:w-1/5 sm:w-full">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.5083 22.5381C1.5083 18.1198 5.09002 14.5381 9.5083 14.5381C13.9266 14.5381 17.5083 18.1198 17.5083 22.5381H1.5083ZM9.5083 13.5381C6.1933 13.5381 3.5083 10.8531 3.5083 7.53809C3.5083 4.22309 6.1933 1.53809 9.5083 1.53809C12.8233 1.53809 15.5083 4.22309 15.5083 7.53809C15.5083 10.8531 12.8233 13.5381 9.5083 13.5381ZM16.8711 15.7713C19.9565 16.5598 22.2762 19.2616 22.4919 22.5381H19.5083C19.5083 19.9283 18.5085 17.552 16.8711 15.7713ZM14.8484 13.495C16.4811 12.0303 17.5083 9.90416 17.5083 7.53809C17.5083 6.12075 17.1397 4.7895 16.4932 3.63496C18.7836 4.09206 20.5083 6.11274 20.5083 8.53809C20.5083 11.3006 18.2708 13.5381 15.5083 13.5381C15.2846 13.5381 15.0643 13.5234 14.8484 13.495Z"
              fill="#FF7F7F"
            />
            <path
              d="M1.5083 22.5381C1.5083 18.1198 5.09002 14.5381 9.5083 14.5381C13.9266 14.5381 17.5083 18.1198 17.5083 22.5381H1.5083ZM9.5083 13.5381C6.1933 13.5381 3.5083 10.8531 3.5083 7.53809C3.5083 4.22309 6.1933 1.53809 9.5083 1.53809C12.8233 1.53809 15.5083 4.22309 15.5083 7.53809C15.5083 10.8531 12.8233 13.5381 9.5083 13.5381Z"
              fill="#FF7F7F"
            />
          </svg>
          <p className="font-bold font-roboto">Unconfirmed</p>
        </div>

        <div className="bg-[#F4E8FF] flex flex-col gap-6 p-4 rounded-xl lg:w-1/5 sm:w-full">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 3.5376V19.5376H21V21.5376H3V3.5376H5ZM20.2929 6.83049L21.7071 8.24471L16 13.9518L13 10.9526L8.70711 15.2447L7.29289 13.8305L13 8.12339L16 11.1226L20.2929 6.83049Z"
              fill="#8000FF"
            />
          </svg>
          <p className="font-bold font-roboto">
            {Array.isArray(data[0]?.Series) && data[0].Series[1] !== "Null"
              ? data[0]?.Series[1]
              : "Unconfirmed"}
          </p>
        </div>
      </div>

      <hr />

      <div className="flex lg:flex-row sm:flex-col w-full gap-4">
        <div className="flex flex-col lg:w-2/3 gap-4 sm:w-full justify-between">
          <div className=" flex lg:flex-row sm:flex-col sm:gap-6 ">
            <div className="flex-wrap flex flex-col lg:w-1/2 sm:w-full gap-6">
              <div>
                <p className="text-[#A1A1AA] text-xs font-roboto">CIN</p>
                <p className="font-roboto">
                  {data[0].Cin ?? cin ?? "Unconfirmed"}
                </p>
              </div>
              <div>
                <p className="text-[#A1A1AA] text-xs font-roboto">
                  Last Funding (Amount/Series/Date)
                </p>
                <p className="font-roboto">
                  {data &&
                  data.length > 0 &&
                  data[0]?.Amount &&
                  Array.isArray(data[0]?.Series)
                    ? `(${data[0]?.Amount}) 
                    ${data[0]?.Series[0]}`
                    : "Unconfirmed"}
                </p>
              </div>
              <div>
                <p className="text-[#A1A1AA] text-xs font-roboto">
                  Investments (Amount/Series)
                </p>
                <p className="font-roboto">
                  {data[0]?.C_Last_Investor ??
                    data[0]?.C_Last_Amount_Raised ??
                    "Unconfirmed"}
                </p>
              </div>
              <div>
                <p className="text-[#A1A1AA] text-xs font-roboto">GST Number</p>
                <p className="font-roboto">
                  {data[0]?.Gst_Number ?? "Unconfirmed"}
                </p>
              </div>
              <div>
                <p className="text-[#A1A1AA] text-xs font-roboto">Category</p>
                <p className="font-roboto">
                  {data[0]?.Category ?? "Unconfirmed"}
                </p>
              </div>
              <div>
                <p className="text-[#A1A1AA] text-xs font-roboto">LEI</p>
                <p className="font-roboto">
                  {data[0]?.Lei ?? lei ?? "Unconfirmed"}
                </p>
              </div>
              <div>
                <p className="text-[#A1A1AA] text-xs font-roboto">Status</p>
                <p className="font-roboto text-[#20BE06]">
                  {capitalizeFirstLetter(data[0]?.Status) ?? "Unconfirmed"}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-[#A1A1AA] text-xs font-roboto">
                  Industry classification (FACTACY)
                </p>
                <p className="font-roboto flex gap-2 flex-wrap">
                  {data[0]?.Factacy_Industrial_Classification ? (
                    Array.isArray(
                      data[0]?.Factacy_Industrial_Classification
                    ) ? (
                      data[0]?.Factacy_Industrial_Classification.every(
                        Array.isArray
                      ) ? (
                        data[0]?.Factacy_Industrial_Classification.map(
                          (classification, index) => (
                            <Sector_Pill sector={classification} key={index} />
                          )
                        )
                      ) : (
                        <Sector_Pill
                          sector={data[0]?.Factacy_Industrial_Classification}
                        />
                      )
                    ) : (
                      <Sector_Pill
                        sector={data[0]?.Factacy_Industrial_Classification}
                      />
                    )
                  ) : (
                    "Unconfirmed"
                  )}
                </p>
              </div>
            </div>

            <div className="flex-wrap flex flex-col lg:w-1/2 sm:w-full gap-6">
              <div className="w-full">
                <p className="text-[#A1A1AA] text-xs font-roboto">Brand Name</p>
                <div className="flex  w-full items-center gap-2">
                  <p className="font-roboto uppercase">
                    {data[0]?.Brand ?? "Unconfirmed"}
                  </p>
                  {data[0].Brand_Id && (
                    <div className="flex flex-1 gap-1 overflow-auto">
                      {Object.keys(data[0].Brand_Id).map(
                        (brand, index) =>
                          brand !== data[0].Brand && (
                            <p
                              className="text-xs hover:underline py-[1px] px-2 bg-gray-50 rounded-full border-[1px] border-gray-300 uppercase font-semibold cursor-pointer hover:text-primaryBlue min-w-fit"
                              key={index}
                              onClick={() =>
                                goToCompanyPage(
                                  input,
                                  [data[0].Company_Name],
                                  Object.values(data[0].Brand_Id)[index],
                                  [brand]
                                )
                              }
                            >
                              {brand}{" "}
                            </p>
                          )
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <p className="text-[#A1A1AA] text-xs font-roboto">
                  Registered Name
                </p>
                <p className="font-roboto uppercase">
                  {data[0]?.Company_Name ?? "Unconfirmed"}
                </p>
              </div>
              <div>
                <p className="text-[#A1A1AA] text-xs font-roboto">
                  Recent Investors
                </p>
                <div className="font-roboto">
                  {data[0]?.Investors != "-" && data[0]?.Investors != null
                    ? data[0]?.Investors?.map((series, i) => (
                        <p
                          className="hover:text-primaryBlue cursor-pointer hover:underline"
                          key={i}
                          onClick={() =>
                            handleInvestorClick(series[0], series[1])
                          }
                        >
                          {" "}
                          {series[1]}{" "}
                        </p>
                      ))
                    : "Unconfirmed"}
                </div>
              </div>
              <div>
                <p className="text-[#A1A1AA] text-xs font-roboto">
                  Activity Code
                </p>
                <p className="font-roboto">
                  {data[0]?.Activity_Code ?? "Unconfirmed"}
                </p>
              </div>
              <div>
                <p className="text-[#A1A1AA] text-xs font-roboto">Founded</p>
                <p className="font-roboto">
                  {data[0]?.Date_Of_Registration ?? "Unconfirmed"}
                </p>
              </div>
              <div>
                <p className="text-[#A1A1AA] text-xs font-roboto">
                  Company Type
                </p>
                <p className="font-roboto">
                  {capitalizeFirstLetter(data[0]?.Company_Type) ??
                    "Unconfirmed"}
                </p>
              </div>
              <div>
                <p className="text-[#A1A1AA] text-xs font-roboto">
                  MCA Industry classification
                </p>
                <p className="font-roboto">
                  {data[0]?.Activity_Description
                    ? capitalizeFirstLetter(data[0]?.Activity_Description)
                    : "Unconfirmed"}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="lg:w-1/3 sm:w-full flex flex-col gap-6">

          <div className="flex flex-col gap-2">
            <p className="font-medium text-[25px] tracking-tight ">
              Latest News for{" "}
              <span className="uppercase">
                {data[0]?.Brand ?? data[0]?.Company_Name}
              </span>
            </p>
            <div className="gap-3 flex flex-col">
              {businessNews &&
                businessNews?.slice(0, 3).map((news, i) => {
                  if (news) {
                    return (
                      <div className="flex flex-col pt-1" key={i}>
                        <p
                          onClick={() => {
                            // handleSectionClick("news insights");
                            setActiveSection("news insights");
                            window.scrollTo(0, 0);
                          }}
                          className="font-semibold hover:text-primaryBlue cursor-pointer hover:underline"
                        >
                          {news.headline}
                        </p>
                        <div className="flex items-center text-sm gap-2 font-roboto text-[#71717A]">
                          <svg
                            width="18"
                            height="19"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.49984 1.73926V3.40592H12.4998V1.73926H14.1665V3.40592H17.4998C17.9601 3.40592 18.3332 3.77902 18.3332 4.23926V17.5726C18.3332 18.0328 17.9601 18.4059 17.4998 18.4059H2.49984C2.0396 18.4059 1.6665 18.0328 1.6665 17.5726V4.23926C1.6665 3.77902 2.0396 3.40592 2.49984 3.40592H5.83317V1.73926H7.49984ZM16.6665 10.0726H3.33317V16.7393H16.6665V10.0726ZM5.83317 5.07259H3.33317V8.40592H16.6665V5.07259H14.1665V6.73926H12.4998V5.07259H7.49984V6.73926H5.83317V5.07259Z"
                              fill="#71717A"
                            />
                          </svg>
                          <p>{howOldTimeUpdater(news?.Unique_date_time)}</p>
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
            <div
              className="flex gap-2 cursor-pointer"
              onClick={() => {
                // handleSectionClick("news insights");
                setActiveSection("news insights");
                window.scrollTo(0, 0);
              }}
            >
              <p className="text-[#3E91EE] underline font-roboto">
                View All Latest News
              </p>
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.2002 12.384C6.91205 12.384 6.67846 12.6176 6.67846 12.9058C6.67846 13.1939 6.91205 13.4275 7.2002 13.4275L7.2002 12.384ZM17.1691 13.2747C17.3729 13.0709 17.3729 12.7406 17.1691 12.5368L13.8488 9.21651C13.645 9.01276 13.3147 9.01276 13.1109 9.21651C12.9072 9.42026 12.9072 9.75061 13.1109 9.95436L16.0623 12.9058L13.1109 15.8572C12.9072 16.0609 12.9072 16.3913 13.1109 16.595C13.3147 16.7988 13.645 16.7988 13.8488 16.595L17.1691 13.2747ZM7.2002 13.4275L16.8002 13.4275L16.8002 12.384L7.2002 12.384L7.2002 13.4275Z"
                  fill="#3E91EE"
                />
                <rect
                  x="0.521739"
                  y="1.4275"
                  width="22.9565"
                  height="22.9565"
                  rx="11.4783"
                  stroke="#3E91EE"
                  strokeWidth="1.04348"
                />
              </svg>
            </div>
          </div>

          <hr />

          <div className="flex flex-col gap-4">
            <p className="font-medium text-[25px] tracking-tight ">
              Competitors of{" "}
              <span className="uppercase">
                {data[0]?.Brand ?? data[0]?.Company_Name}
              </span>
            </p>

            <div className="flex gap-4 items-center">
              <div className="flex flex-col gap-3 items-start">
                {competitorDetails?.slice(0, 3)?.map((comp, index) => (
                  <div className="flex gap-3" key={index}>
                    <img className="h-4 w-12 object-contain" src={comp.Logo} />
                    <p className="font-semibold">{comp.Brand}</p>
                  </div>
                ))}
              </div>
            </div>
            <div
              className="flex gap-2 cursor-pointer"
              onClick={() => {
                // handleSectionClick("competitors");
                setActiveSection("competitors");
                window.scrollTo(0, 0);
              }}
            >
              <p className="text-[#3E91EE] underline font-roboto">
                View All Competition
              </p>
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.2002 12.384C6.91205 12.384 6.67846 12.6176 6.67846 12.9058C6.67846 13.1939 6.91205 13.4275 7.2002 13.4275L7.2002 12.384ZM17.1691 13.2747C17.3729 13.0709 17.3729 12.7406 17.1691 12.5368L13.8488 9.21651C13.645 9.01276 13.3147 9.01276 13.1109 9.21651C12.9072 9.42026 12.9072 9.75061 13.1109 9.95436L16.0623 12.9058L13.1109 15.8572C12.9072 16.0609 12.9072 16.3913 13.1109 16.595C13.3147 16.7988 13.645 16.7988 13.8488 16.595L17.1691 13.2747ZM7.2002 13.4275L16.8002 13.4275L16.8002 12.384L7.2002 12.384L7.2002 13.4275Z"
                  fill="#3E91EE"
                />
                <rect
                  x="0.521739"
                  y="1.4275"
                  width="22.9565"
                  height="22.9565"
                  rx="11.4783"
                  stroke="#3E91EE"
                  strokeWidth="1.04348"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      {showErrorMessage && (
        <div className="absolute bottom-0 right-0 lg:m-10 sm:m-4 lg:p-3 sm:p-2 lg:text-base sm:text-sm border-gray-200 border-2 bg-slate-100 shadow-lg rounded-lg font-semibold font-roboto">
          We are expanding our coverage daily. Please check back again later.
        </div>
      )}
    </div>
  );
};

export default Summary;
