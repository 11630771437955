// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router";
import { LOGIN_API, REGISTER_API } from "../../constant/api";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const Navigate = useNavigate();
  const [user, setUser] = useState({ isAuthenticated: sessionStorage.getItem("accessToken") ? true : false });
  const [error, setErr] = useState("");

  const [redirect_url, setRedirect_url] = useState('');

  // Function to log the user in
  const login = async (userData) => {
    const userLogin = { email: userData.email, password: userData.password };
    try {
      const response = await axios.post(LOGIN_API, userLogin);

      if (response.status === 200) {
        const { data } = response;
        sessionStorage.setItem("accessToken", data.token);
        setUser({ isAuthenticated: true });
        sessionStorage.setItem("email", userData.email);
        Navigate(redirect_url === "/" ? '/home/my-feed' : redirect_url );

      }
    } catch (error) {
      if (error.response.status === 500) {
        setErr("Internal Server Error. Try again later.");
      } else if (error.response.status === 400) {
        setErr("Invalid email or password");
      }
    }
  };

  // Function to register the user
  const register = async (userData) => {
    const userRegister = {
      first_name: userData.firstName,
      last_name: userData.lastName,
      email: userData.email,
      company: userData.companyName,
      phone: userData.phoneNumber,
      password: userData.password,
      username: userData.userName,
      gstin: userData.gstin,
      company: userData.companyName,
    };
    try {
    const response = await axios.post(REGISTER_API + `?site=${"FactacyInsights"}`, userRegister, {withCredentials: true});
      if (response) {
        const { data } = response;
        if (data.token) {
          sessionStorage.setItem("accessToken", data.token);
          sessionStorage.setItem("email", userData.email);
          setUser({ isAuthenticated: true });

          Navigate(redirect_url ? redirect_url : '/');

        } else if (data === "email id already exist") {
          setErr("An account from this email already exists.");
        }
      }
    } catch (error) {
      if (error.response.status == 400) {
        setErr("An account from this email already exists.");
      } else {
        setErr("Invalid Email or Password");
      }
    }
  };

  // Function to log the user out
  const logout = () => {
    sessionStorage.clear()
    setUser({ isAuthenticated: false });
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, register, error, setRedirect_url }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
