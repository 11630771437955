import { useEffect, useState } from "react";
import useDebounce from "../useDebounce";
import { SEARCH_API_WITH_REFRENCE_ID_BRAND_ID, PATENT_SEARCH, SOLRCORE_SECTOR_NEWS } from "../../constant/api";
const useFetchSearchSuggestions = (input, type) => {

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [companySuggestions, setCompanySuggestions] = useState([]);
    const [investorSuggestions, setInvestorSuggestions] = useState([]);
    const [personSuggestions, setPersonSuggestions] = useState([]);
    const [sectorSuggestions, setSectorSuggestions] = useState([]);
    const [patentSuggestions, setPatentSuggestions] = useState([]);
    const [newsSuggestions, setNewsSuggestions] = useState([]);

    const fetchFunction = async (input) => {
        try {
            setLoading(true);
            const [response1, response2, response3] = await Promise.all([
                fetch(SEARCH_API_WITH_REFRENCE_ID_BRAND_ID + input),
                fetch(PATENT_SEARCH + input),
                fetch(SOLRCORE_SECTOR_NEWS + input)
              ]);
            const data = await response1.json();
            const patentData = await response2.json();
            const newsData = await response3.json();            

            if (response1.ok || response2.ok || response3.ok) {
                setSuggestions(data);
                setCompanySuggestions(data?.filter((item) => item?.PROPERTY?.includes('Mca Company') || item?.PROPERTY?.includes('Lei Company') || item?.PROPERTY?.includes('Brand')));               
                setInvestorSuggestions(data?.filter((item) => item?.PROPERTY?.includes('Investor')));
                setPersonSuggestions(data?.filter((item) => item?.PROPERTY?.includes('Person')));
                setSectorSuggestions(data?.filter((item) => item?.PROPERTY?.includes('Sector')));
                setPatentSuggestions(patentData)
                setNewsSuggestions(newsData)
                setLoading(false);
            }
        }
        catch (error) {
            setSuggestions([]);
            setLoading(false);
        }
    }
    const optimizedFunction = useDebounce(fetchFunction, 300);

    useEffect(() => {
        if (input) {
            optimizedFunction(input);
        }
    }, [input]);

    return [error, loading, { suggestions, companySuggestions, investorSuggestions, personSuggestions, sectorSuggestions, patentSuggestions, newsSuggestions }];
}

export default useFetchSearchSuggestions;