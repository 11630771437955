import React from "react";
import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { useAuth } from "../auth/authContext";
import LoginButton from "../auth/loginButton";
import Loader from "../tearSheet/loader";
import { useDispatch, useSelector } from "react-redux";
import Summary from "../tearSheet/summary";
import KeyPeople from "../tearSheet/keyPeople";
import Financials from "../tearSheet/financials/financials";
import EsgScore from "../tearSheet/esgScore";
import Patents from "../tearSheet/patents";
import NewsInsights from "../tearSheet/newsInsights";
import PotentialInvestors from "../tearSheet/potentialInvestors";
import Competitor from "../tearSheet/competitor";
import inventor from "../../assets/Factacy INVENTOR badge.svg";
import investorr from "../../assets/Factacy Investor Badge.svg";
import funded from "../../assets/Factacy Funded company Badge.svg";
import unicorn from "../../assets/Factacy Unicorn Badge.svg";
import useFetchInvestorActions from "../../hooks/useFetchInvestorActions";
import useFetchCompanyActions from "../../hooks/useFetchCompanyActions";
import InvestorComponent from "./InvestorComponent";
import { GETARTS } from "../../constant/api";
const InvestorPage = () => {
  const { getCompany, getPatentsData } = useFetchCompanyActions();
  const dispatch = useDispatch();
  const { getInvestor } = useFetchInvestorActions();
  const param = useParams();
  const oginput = param["*"];
  const investorId = oginput?.split("/")[1];
  const investorState = useSelector((state) => state.investor);
  const [activeSection, setActiveSection] = useState("deals");
  const [activeSection1, setActiveSection1] = useState("summary");
  const { user } = useAuth();
  const [isLoading, setLoading] = useState(false);
  const [activeStatement, setActiveStatement] = useState("investorProfile");
  const companyState = useSelector((state) => state.company);
  const [newsData, setNewsData] = useState([]);
  // const getarts = process.env.REACT_APP_GETARTS_ARTICLES;
  const [companySos, setCompanySos] = useState([]);
  const [cin, setCin] = useState("");



  const handleCompanyClick = async (cin) => {
    // setCin(cin);
    // getCompany(cin);
  };

  //FETCHING ARTICLES FROM COMPANY NAMES
  const fetchArtIds = async () => {
    try {
      setLoading(true);
      const response = await axios(
        `https://factacyinsights.in/getone?input1=cdb-L1&input2=Business-News&input3=Output_CIN&input4=${cin}`
      );
      const sos = response.data[0].Company_SOS;
      setCompanySos(sos);
      const artId = response.data[0]?.Art_Id?.slice(0, 50);
      const formattedIds = artId && artId.join(",");
      const articles = await fetchArticlesForArtId(formattedIds);
      const sortedData = articles.sort((a, b) => {
        return b.Unique_date_time - a.Unique_date_time;
      });
      setNewsData(sortedData);

      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  //FETCHING ARTICLES FROM ARTICLE IDS
  const fetchArticlesForArtId = async (artId) => {
    const getArtsOptions = {
      url: GETARTS + artId,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/txt;charset=UTF-8",
      },
      // params: { input: artId },
      data: {},
    };

    try {
      const response = await axios(getArtsOptions);
      return response.data;
    } catch {
      return null;
    }
  };

  // GET INVESTOR FUNCTION
  useEffect(() => {
    getInvestor(investorId);

  const linkTag = document.createElement("link");
  linkTag.setAttribute("rel", "canonical");
  linkTag.href = window.location.href;
  document.head.appendChild(linkTag);
  }, [investorId]);

  // HANDLE SECTION CLICK
  const handleSectionClick1 = useCallback(
    (activeSection1) => {
      setActiveSection1(activeSection1);
      if (activeSection1 === "patents") {
        getPatentsData(cin);
      }
      if (activeSection1 === "insights") {
        fetchArtIds(cin);
      }
    },
    [dispatch, investorId, activeSection1, cin, companyState.patentData]
  );

  const handleSectionClick = useCallback(
    (section) => {
      setActiveSection(section);
    },
    [activeSection]
  );

  const showData = () => {
    const investorData = investorState.investorData[investorId];
    const companyData = companyState.companyData[cin];
    const patentData = companyState.patentsData[cin];
    const financialsData = companyState.financialsData[cin];
    if (investorState.investorData[investorId]) {
      return (
        <div>
          {investorData?.map((investor, index) => (
            <div className="flex flex-col w-full gap-4" key={index}>
              <div
                className="flex w-full rounded-xl bg-white lg:px-8 sm:px-4 lg:py-6 sm:py-3 flex-col gap-3"
                style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
              >
                <div className="flex items-center gap-6">
                  {/* <img className='lg:w-[70px] lg:h-[70px] sm:w-[60px] sm:h-[60px] object-contain' src={investor.Logo_Url}></img> */}
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-3">
                      <p className="font-roboto lg:text-3xl sm:text-lg font-bold leading-9">
                        {investor.Investor}
                      </p>
                      {companyData &&
                        companyData.map((company, i) => (
                          <div className="flex gap-1 items-center" key={i}>
                            {company &&
                              company.Badges &&
                              company.Badges.includes("Unicorn") && (
                                <img
                                alt="unicorn"
                                  src={unicorn}
                                  className="w-10 h-10 cursor-pointer drop-shadow-lg  "
                                  title="Unicorn"
                                />
                              )}
                            {company &&
                              company.Badges &&
                              company.Badges.includes("Inventor") && (
                                <img
                                alt="inventor"
                                  src={inventor}
                                  className="w-9 h-9 cursor-pointer drop-shadow-lg"
                                  title="Inventor"
                                />
                              )}
                            {company &&
                              company.Badges &&
                              company.Badges.includes("Investor") && (
                                <img
                                alt="investor"
                                  src={investorr}
                                  className="w-10 h-10 cursor-pointer drop-shadow-lg"
                                  title="Investor"
                                />
                              )}
                            {company &&
                              company.Badges &&
                              company.Badges.includes("Funded") && (
                                <img
                                alt="funded"
                                  src={funded}
                                  className="w-10 h-10 cursor-pointer drop-shadow-lg"
                                  title="Funded Company"
                                />
                              )}
                          </div>
                        ))}
                    </div>
                    {/* <p className='font-normal lg:text-base sm:text-sm text-neutral-600 font-roboto'>{investor.FACTACY_INDUSTRIAL_CLASSIFICATION}</p> */}
                    <div className="flex gap-3">
                      <p className="px-2 py-1 rounded-lg border-blue border-[1px]">Deals in 6 months: <span className="font-semibold">{investor.Deal_Count_6}</span></p>
                      <p className="px-2 py-1 rounded-lg border-blue  border-[1px]">Deals in 12 months: <span className="font-semibold">{investor.Deal_Count_12}</span></p>
                      <p className="px-2 py-1 rounded-lg border-blue  border-[1px]">Top Sector: <span className="font-semibold">{investor.Top_Sector}</span></p>
                    </div>
                  </div>
                </div>

                {/* TOGGLE TOGGLE */}
                {/* {investor.Output_CIN && investor.Output_CIN.length > 4 ? (
                  <div
                    className="bg-[#ffffff] rounded-full flex p-2 w-max"
                    style={{
                      boxShadow:
                        "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
                    }}
                  >
                    <button
                      className={`py-2 px-3 sm:text-[10px] lg:text-sm ${
                        activeStatement === "investorProfile"
                          ? "active text-white font-semibold rounded-full bg-primaryBlue"
                          : ""
                      }`}
                      onClick={() => setActiveStatement("investorProfile")}
                    >
                      Investor Profile
                    </button>
                    <button
                      className={`py-2 px-3 sm:text-[10px] lg:text-sm ${
                        activeStatement === "companyProfile"
                          ? "active text-white font-semibold rounded-full bg-primaryBlue"
                          : ""
                      }`}
                      onClick={() => handleCompanyClick(investor.Output_CIN)}
                    >
                      <p onClick={() => setActiveStatement("companyProfile")}>
                        Company Profile
                      </p>
                    </button>
                  </div>
                ) : (
                  ""
                )} */}
              </div>

              {/* details */}
              <InvestorComponent investorId={investorId} />

              {/* details2 */}
              <div
                className={`lg:w-full sm:w-full h-max bg-[#FAFAFA] rounded-xl ${activeStatement === "companyProfile" ? "active" : "hidden"
                  }`}
              >
                <div
                  className="flex justify-between text-center w-full sm:overflow-x-auto lg:overflow-auto"
                  id="scroller"
                >
                  <div
                    className={`flex flex-col w-full item lg:text-sm sm:text-xs rounded-ss-xl px-4 py-4  font-roboto hover:bg-white ${activeSection1 === "summary"
                      ? "bg-white text-primaryBlue font-bold"
                      : "text-[#A1A1AA]"
                      } `}
                    onClick={() => handleSectionClick1("summary")}
                  >
                    <div className="cursor-pointer sm:w-max lg:w-full">
                      Summary
                    </div>
                  </div>

                  <div
                    className={`flex flex-col w-full h-full item lg:text-sm sm:text-xs  px-4 py-4  font-roboto hover:bg-white ${activeSection1 === "financials"
                      ? "bg-white text-primaryBlue font-bold"
                      : "text-[#A1A1AA]"
                      } `}
                    onClick={() => handleSectionClick1("financials")}
                  >
                    <div className="cursor-pointer sm:w-max lg:w-full">
                      Financials
                    </div>
                  </div>

                  <div
                    className={`flex flex-col w-full h-full item lg:text-sm sm:text-xs px-4 py-4 font-roboto hover:bg-white ${activeSection1 === "potential"
                      ? "bg-white text-primaryBlue font-bold"
                      : "text-[#A1A1AA]"
                      }`}
                    onClick={() => handleSectionClick1("potential")}
                  >
                    <div className="cursor-pointer sm:w-max lg:w-full">
                      Proposed Investors
                    </div>
                  </div>

                  <div
                    className={`flex flex-col w-full h-full item lg:text-sm sm:text-xs px-4 py-4 font-roboto hover:bg-white ${activeSection1 === "key_people"
                      ? "bg-white text-primaryBlue font-bold"
                      : "text-[#A1A1AA]"
                      }`}
                    onClick={() => handleSectionClick1("key_people")}
                  >
                    <div className="cursor-pointer sm:w-max lg:w-full">
                      Key People
                    </div>
                  </div>

                  <div
                    className={`flex flex-col w-full h-full item lg:text-sm sm:text-xs px-4 py-4 font-roboto hover:bg-white ${activeSection1 === "competitors"
                      ? "bg-white text-primaryBlue font-bold"
                      : "text-[#A1A1AA]"
                      }`}
                    onClick={() => handleSectionClick1("competitors")}
                  >
                    <div className="cursor-pointer sm:w-max lg:w-full">
                      Competitors
                    </div>
                  </div>

                  <div
                    className={`flex flex-col w-full h-full item lg:text-sm sm:text-xs px-4 py-4 font-roboto hover:bg-white ${activeSection1 === "esg"
                      ? "bg-white text-primaryBlue font-bold"
                      : "text-[#A1A1AA]"
                      }`}
                    onClick={() => handleSectionClick1("esg")}
                  >
                    <div className="cursor-pointer sm:w-max lg:w-full">
                      ESG/CSR
                    </div>
                  </div>

                  <div
                    className={`flex flex-col w-full h-full item lg:text-sm sm:text-xs px-4 py-4 font-roboto hover:bg-white ${activeSection1 === "patents"
                      ? "bg-white text-primaryBlue font-bold"
                      : "text-[#A1A1AA]"
                      }`}
                    onClick={() => handleSectionClick1("patents")}
                  >
                    <div className="cursor-pointer sm:w-max lg:w-full">
                      Patent Info
                    </div>
                  </div>

                  <div
                    className={`flex flex-col w-full h-full rounded-tr-xl item lg:text-sm sm:text-xs px-4 py-4 font-roboto hover:bg-white ${activeSection1 === "insights"
                      ? "bg-white text-primaryBlue font-bold"
                      : "text-[#A1A1AA]"
                      }`}
                    onClick={() => handleSectionClick1("insights")}
                  >
                    <div className="cursor-pointer sm:w-max lg:w-full">
                      News & Insights
                    </div>
                  </div>
                </div>

                {/* Summary */}
                <div
                  className={`section ${activeSection1 === "summary" ? "active" : "hidden"
                    } bg-white sm:w-full rounded-b-xl flex flex-col lg:p-8 sm:p-4 gap-6`}
                  style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
                >
                  {companyData && (
                    <Summary
                      input={cin}
                      handleSectionClick={handleSectionClick1}
                      homeNewsData={newsData}
                      fetchArtIds={fetchArtIds}
                      data={companyData}
                    />
                  )}
                </div>

                {/* Financials */}
                {user.isAuthenticated == true ? (
                  <div
                    className={`section ${activeSection1 === "financials" ? "active" : "hidden"
                      } bg-white w-full rounded-b-xl`}
                    style={{
                      boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)",
                    }}
                  >
                    {financialsData &&
                      financialsData.length > 0 &&
                      companyData &&
                      companyData.length > 0 && (
                        <Financials
                          financialsData={financialsData}
                          company={companyData}
                        />
                      )}
                  </div>
                ) : (
                  <div className="flex items-center justify-center flex-col gap-3 h-screen">
                    Please login first <LoginButton className="w-5" />
                  </div>
                )}

                {/* Potential investors */}
                <div
                  className={`section ${activeSection1 === "potential" ? "active" : "hidden"
                    } key_people bg-white sm:w-full rounded-b-xl sm:p-4 lg:p-8`}
                  style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
                >
                  {companyData && companyData.length > 0 && (
                    <PotentialInvestors company={companyData} />
                  )}
                </div>

                {/* Key People */}
                <div
                  className={`section ${activeSection1 === "key_people" ? "active" : "hidden"
                    } key_people bg-white sm:w-full rounded-b-xl sm:p-4 lg:p-8`}
                  style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
                >
                  {companyData && companyData.length > 0 && (
                    <KeyPeople company={companyData} />
                  )}
                </div>

                {/* Competitors */}
                <div
                  className={`section ${activeSection1 === "competitors" ? "active" : "hidden"
                    } competitors bg-white sm:w-full rounded-b-xl lg:p-8 sm:p-4 `}
                  style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
                >
                  <p className="font-medium text-[25px] tracking-tight leading-9">
                    Competitors
                  </p>
                  {companyData && companyData.length > 0 && (
                    <Competitor company={companyData} />
                  )}
                </div>

                {/* ESG */}
                <div
                  className={`section ${activeSection1 === "esg" ? "active" : "hidden"
                    } esg bg-white sm:w-full rounded-b-xl lg:p-8 sm:p-4 `}
                  style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
                >
                  {companyData && companyData.length > 0 && (
                    <EsgScore company={companyData} />
                  )}
                </div>

                {/* Patents */}
                <div
                  key={index}
                  className={`section ${activeSection1 === "patents" ? "active" : "hidden"
                    } patents bg-white sm:w-full rounded-b-xl  lg:p-8 sm:p-4`}
                  style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
                >
                  {patentData && patentData.length > 0 && (
                    <Patents patentData={patentData} />
                  )}
                </div>

                {/* News and Insights */}
                <div
                  className={`section ${activeSection1 === "insights" ? "active" : "hidden"
                    } insights bg-white sm:w-full rounded-b-xl sm:p-4 lg:p-8`}
                  style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
                >
                  {companyData &&
                    companyData.length > 0 &&
                    newsData &&
                    newsData.length > 0 && (
                      <NewsInsights
                        company={companyData}
                        companySos={companySos}
                        newsData={newsData}
                      />
                    )}
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    }

    if (investorState.loading) {
      return (
        <div className="flex flex-col items-center justify-center h-[90vh]">
          <Loader />
        </div>
      );
    }

    if (investorState.errorMsg !== "") {
      return <p>{investorState.errorMsg}</p>;
    }

    return <p>Error getting Investors Data</p>;
  };

  return <div className="px-4 py-0 sm:w-full lg:w-full">{showData()}</div>;
};


export default InvestorPage;