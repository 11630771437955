import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../loader";
import Funding_Information from "./Funding_Information";
import Key_Indicators from "./Key_Indicators";
import Balance_Sheet from "./Balance_Sheet";
import Profit_Loss from "./Profit_Loss";
import Cashflow from "./Cashflow";
import Share_Capital from "./Share_Capital";
import Shareholders from "./Shareholders";
import RequestAiProfileButton from "../requestAiProfileButton";
import EodReports from "./EodReports";

const Financials = ({
  input,
  loading,
  getFinancials,
  companyData,
  company
}) => {
  useEffect(() => {
    getFinancials(input);
  }, [input]);

  const financialsData = useSelector((state) => state.company).financialsData[
    input
  ];
  const eodReports = useSelector((state) => state.company).eodReportsData[
    input
  ];



  const Tabs = {
    "Profit & Loss Statement": <Profit_Loss financialsData={financialsData} />,
    "Cashflow Statement": <Cashflow financialsData={financialsData} />,
    "Balance Sheet": <Balance_Sheet financialsData={financialsData} />,
    "Share Capital": <Share_Capital financialsData={financialsData} />,
    Shareholders: <Shareholders financialsData={financialsData} />
  };
  const [activeSection, setActiveSection] = useState(Object.keys(Tabs)[0]);



  return (
    <div className="flex flex-col gap-4 ">
      {loading && (
        <div className=" w-full h-full min-h-[22rem] flex items-center justify-center bg-white">
          {" "}
          <Loader />
        </div>
      )}

      {/* {eodReports && eodReports[0] && <EodReports eodReports={eodReports} />} */}
      <Funding_Information referenceId={input} />

      {/* key indicators */}
      {financialsData?.[0]?.RATIO_AND_SUMMARY && (
        <Key_Indicators
          RATIO_AND_SUMMARY={financialsData?.[0]?.RATIO_AND_SUMMARY}
        />
      )}

      {/* second part */}
      <div>
        <div
          className="flex justify-between text-center w-full sm:overflow-x-auto lg:overflow-auto rounded-t-xl bg-[#FAFAFA]"
          id="scroller"
        >
          {Object.keys(Tabs).map((item) => (
            <div
              key={item}
              className={`flex flex-col w-full item lg:text-sm sm:text-xs px-4 py-4  font-roboto hover:bg-white ${
                activeSection === item
                  ? "bg-white text-primaryBlue font-bold"
                  : "text-[#A1A1AA]"
              } `}
              onClick={() => setActiveSection(item)}
            >
              <div className="cursor-pointer sm:w-max lg:w-full">{item}</div>
            </div>
          ))}
        </div>

        <div
          className="flex flex-col gap-2 sm:p-4 lg:p-8 rounded-b-xl bg-white"
          style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
        >
          {financialsData != "" &&
          financialsData != [] &&
          financialsData?.length > 0 ? (
            <>{Tabs[activeSection]}</>
          ) : (
            <div className="w-full flex items-center gap-3 justify-center h-40">
              <p>
                To request data for this section, please initiate AI profiling.
              </p>
              <div className="flex flex-col justify-between items-end gap-3">
                <RequestAiProfileButton
                  input={input}
                  company={company}
                  cin={companyData?.Cin ?? companyData?.Lei}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Financials;
