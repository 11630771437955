import React from "react";
import Table2 from "./Table2";

const Shareholders = ({ financialsData }) => {
  return (
    <div className={`w-full gap-4 flex flex-col`}>
      <Table2 Data={financialsData?.[0].SHAREHOLDERS} text="Shareholders" />

      <Table2
        Data={financialsData?.[0].PROMOTER_DETAILS}
        text="Promoters, Non-Promoters and Debentures"
      />
    </div>
  );
};

export default Shareholders;