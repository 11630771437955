import React, { useEffect, useState } from "react";

import axios from "axios";
import Loader from "../tearSheet/loader";

import InvestorCard from "../../pages/InvestorCard";
import { GET_ALL_DEALS, LEADING_INVESTOR } from "../../constant/api";

const LeadingInvestors = () => {
  const [leadingInvestorData, setLeadingInvestorData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [valueArray, setValueArray] = useState([]);
  const [dealIdRecord, setDealIdRecord] = useState([]);




  useEffect(() => {
    const fetchLeadingInvestors = async () => {
      setLoading(true);
      try {
        const response = await axios.get(LEADING_INVESTOR);
        setLeadingInvestorData(response.data);

        if (
          response.data &&
          response.data[0]?.length > 0
          // leadingInvestorData[0]?.Investor_Deal_Ids
        ) {

          const dealIds = response.data
            ?.map((item) => item.Investor_Deal_Ids)
        setDealIdRecord(dealIds);

            
          const formattedIds = dealIds && dealIds.flat(1).join(",");

          try {
            
            const responses = await axios.get(`${GET_ALL_DEALS + formattedIds}`);

            const sortedData = responses.data?.slice().sort((a, b) => {
              const lastDateA =
                a.Unique_date_time && a.Unique_date_time.length > 0
                  ? a.Unique_date_time[a.Unique_date_time.length - 1]
                  : null;
              const lastDateB =
                b.Unique_date_time && b.Unique_date_time.length > 0
                  ? b.Unique_date_time[b.Unique_date_time.length - 1]
                  : null;

              if (lastDateA !== null && lastDateB !== null) {
                return lastDateB - lastDateA; // Sort in descending order based on epoch time
              } else if (lastDateA === null && lastDateB !== null) {
                return 1; // Put empty or non-existent dates at the end
              } else if (lastDateA !== null && lastDateB === null) {
                return -1; // Put empty or non-existent dates at the end
              }
              return 0;
            });
            
            const store = {};
            sortedData?.forEach( item => store[item.id] = item);
            setSortedData(store);

            const summaryICArrays = sortedData.map((item) => item?.Sector);
            if (
              summaryICArrays &&
              Array.isArray(summaryICArrays) &&
              summaryICArrays.length > 0
            ) {
              const allSummaryICValues = summaryICArrays.flat();
              const uniqueSectors = {};
              allSummaryICValues.forEach((summaryIC) => {
                if (!uniqueSectors[summaryIC]) {
                  uniqueSectors[summaryIC] = 1; // Initialize count for new sector
                } else {
                  uniqueSectors[summaryIC] += 1; // Increment count for existing sector
                }
              });
              const valueArrayData = Object.keys(uniqueSectors).map(
                (sector) => ({
                  sector,
                  count: uniqueSectors[sector],
                })
              );

              setValueArray(valueArrayData);
            } else {
              setValueArray([]);
            }

            
          } catch (err) {
            console.log(err);
          }
        }

        setLoading(false);
      } catch {}

    };

    fetchLeadingInvestors();
  }, []);

  return (
    <div className="flex flex-col w-full px-4 lg:py-0 lg:px-6 gap-4">
      <h1 className="text-2xl font-semibold">Leading Investors</h1>
      {loading ? <div className="h-screen flex items-center w-full justify-center"> <Loader /> </div>: <>
      {leadingInvestorData &&
        leadingInvestorData.map((data, index) => (

          <InvestorCard dealIds = {dealIdRecord[index]} data={data} sortedData={sortedData} index={index} />

        ))}
        </> }
    </div>
  );
};

export default LeadingInvestors;
