import React from "react";
import Table1 from "./Table1";

const Profit_Loss = ({ financialsData }) => {
  return (
    <div className={`w-full gap-4 flex flex-col`}>
      <p className="text-2xl font-bold">Profit and Loss Statement</p>
      <Table1 Data={financialsData?.[0].PROFIT_AND_LOSS} chart="line" />
    </div>
  );
};

export default Profit_Loss;
