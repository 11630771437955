import { useEffect } from "react";

const useAddMetaTagsInvestor = (name, title, description) => {
  useEffect(() => {
    // title
    document
      .getElementsByTagName("title")[0].innerText = `${name} - ${title}`;

    // meta
    document
      .querySelector('meta[name="title"]')
      .setAttribute("content", `${name} - ${title} - Factacyinsights`);
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", `${description}- Startup Data Provided by AI driven Financial Intelligence Platform for Unlisted Companies, Factacy Insights`);

    // og: meta
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", `${name} - ${title} - Factacyinsights`);
    document
      .querySelector('meta[property="og:description"]').setAttribute("content",`${description}- Startup Data Provided by AI driven Financial Intelligence Platform for Unlisted Companies, Factacy Insights`);
    document
      .querySelector('meta[property="og:url"]')
      .setAttribute("content", `${window.location.href}`);

    // canonical
    const linkTag = document.createElement("link");
    linkTag.setAttribute("rel", "canonical");
    linkTag.href = window.location.href;
    document.head.appendChild(linkTag);

    return () => {
      linkTag.remove();
    };
  }, [window.location.search, name, title, description]);
};

export default useAddMetaTagsInvestor;