import React, { useEffect, useState } from 'react'
import DealCard from "../../../pages/dealCard";
import { useSelector } from 'react-redux';
import Loader from '../../tearSheet/loader';
import LoginComponent from '../../loginComponent';
import { useAuth } from '../../auth/authContext';
import { INVESTOR_SUMMARY_SEARCH } from '../../../constant/api';
import useAddMetaTagsInvestor from '../../../hooks/MetaTagsAdd/useAddMetaTagsInvestor';

const Portfolio = ({ investorId }) => {
    const investor = useSelector(store => store?.investor?.investorData?.[investorId]?.[0]);
    const deals = useSelector(store => store?.investor?.investorDealsData?.[investorId]);
    const sortedDeals = deals?.slice().sort((a, b) => {
        return b.Deal_Date - a.Deal_Date
    })
    const loading = useSelector(store => store?.investor?.loading);
    const { user } = useAuth()
    const [summary, setSumary] = useState('');

  useAddMetaTagsInvestor(investor?.Investor, "Investor profile, portfolio companies, News & more", investor?.Investor_Bio)


    useEffect(() => {

        (async () => {
            try {
                const res = await fetch(INVESTOR_SUMMARY_SEARCH + investorId);
                const data = await res.json();
                if (res.ok) {
                    setSumary(data?.[0]?.Investor_Info?.Summary);
                }
            }
            catch (err) { }

        })();

    }, [investorId]);

    return loading ? (<div className="flex w-full items-center justify-center h-[22rem]"><Loader /></div>)
        :
        (
            <div
                className={`section  bg-white sm:w-full rounded-b-xl flex lg:p-8 sm:p-2 gap-7 flex-wrap w-full`}
                style={{
                    boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)",
                }}
            >
                {investor?.Investor_Bio && (
                    <div>

                        <p className="font-medium text-[25px] tracking-tight leading-9">
                            Portfolio Information
                        </p>
                        <p>{investor?.Investor_Bio}</p>
                    </div>
                )}
                <div className="flex flex-col gap-2 w-full">
                    <p className="font-medium text-[25px] tracking-tight leading-9">
                        Portfolio Companies
                    </p>

                </div>
                {loading ? <div className="flex w-full items-center justify-center h-[6rem]"><Loader /> </div> : user.isAuthenticated ? (<DealCard sortedData={sortedDeals} />) : <LoginComponent />}
                {summary && (
                    <div>
                        <p className="font-medium text-[25px] tracking-tight leading-9">
                            About {investor?.Investor}
                        </p>
                        <div className=' flex flex-col gap-4'>{summary?.split('\n')?.filter(item => item)?.map((item, index) => (<p key={index}>{item}</p>))}</div>
                    </div>
                )}
            </div>
        )
}

export default Portfolio;