import React, { useRef, useEffect } from "react";
import { goToCompanyPage, Alphanumeric_Date } from "../../constant/function";
import { falseValues } from "../../constant/constant";

const Sumary = ({ data }) => {
  const P = ({ item }) => <span>{item}</span>;

  if (!falseValues.includes(data.Reference_Id) && !falseValues.includes(data.Company_Name)) {

    const Clickable = () => (
      <span
        className=" text-primaryBlue font-semibold cursor-pointer"
        onClick={() => goToCompanyPage(data?.Reference_Id, [data?.Company_Name], data?.Brand_Id, [data?.Brand_Name])}
      >
        {" " + data?.Brand_Name + " "}
      </span>
    );
    const Deal_Summary = data?.Deal_Summary?.split(data?.Brand_Name);

    return (
      <div className="">
        {Deal_Summary.map((item, index) => {
          if (Deal_Summary?.length - 1 === index) {
            return (<React.Fragment key={index}><P item={item} /></React.Fragment>)
          } else {
            return (<React.Fragment key={index}><P item={item} /><Clickable /></React.Fragment>)
          }
        })}
      </div>
    );
  } else {
    return (
      <>
        <P item={data?.Deal_Summary} />
      </>
    );
  }
};

const Indi = ({ report }) => {
  const trigerElement = useRef();

  const brandNames = [];
  report?.Summary_Data?.forEach((item) => !brandNames.includes(item?.Brand_Name) && brandNames.push(item?.Brand_Name));

  const urlMaker = (epoch, brandNames) => `deals-for-${Alphanumeric_Date(epoch * 1000)}-${brandNames?.map((one) => one.split(" ").join("-")).filter((item) => item).join("-")}`;

  useEffect(() => {
    const element = trigerElement.current;
    const observer = new IntersectionObserver(
      (items, obs) => {
        if (items[0].isIntersecting) {
          window.history.replaceState(
            {},
            "",

            `/deal-intelligence/reports/${urlMaker(report.Epoch, brandNames)}?Id=${report?.id}`

          );
          if (report) {
            document.getElementsByTagName(
              "title"
            )[0].innerText = `${report?.Summary_Data?.map(
              (one) => one.Brand_Name
            )
              .filter((item) => item && item !== "No")
              .join(", ")}  - Deals tracked by factacyinsights.com`;
          }
          if (report) {
            document
              .querySelector('meta[name="description"]')
              .setAttribute("content", report?.Introduction);
            document
              .querySelector('meta[name="keywords"]')
              .setAttribute("content", report?.keywords);
          }
        }
      },
      {
        rootMargin: "0px",
      }
    );
    observer.observe(element);
    return () => observer.unobserve(element);
  }, []);
  return (
    <div

      ref={trigerElement}
      className="p-4 lg:p-8 flex flex-col gap-2 min-h-screen bg-white rounded-3xl"
    >
      <h1 className="text-3xl font-bold ">{`${brandNames.join(', ')} - Deals tracked by factacyinsights.com`}</h1>
      <span className=" px-2 py-1 rounded-[3px] bg-gray-100 w-fit text-[0.8rem]">{Alphanumeric_Date(report.Epoch * 1000)?.split("-").join(" ")}</span>

      <p className=" text-xl font-semibold">{report?.Introduction}</p>
      <div className=" flex flex-col gap-5">
        <div className=" flex flex-col gap-5">
          {report?.Summary_Data?.map((item, index) => (
            <Sumary data={item} key={index} />
          ))}
        </div>
      </div>
      <p className=" text-lg ">{report?.Conclusion}</p>
    </div>
  );
};

export default Indi;