import React, { useState, useEffect } from 'react'
import { useCallback } from 'react'
import SectorsInNews from './sectorsInNews'
import FundingNews from './fundingNews'
import CompanyInNews from './companyInNews'
import Deals from './deals'
import HomeNews from './homeNews'
import useMediaQuery from '../../hooks/useMediaQuery'
import SectorsPanelNews from './sectorsPanelNews'
import { Link, Route, Routes } from 'react-router-dom'
import SidebarLayout from './sidebarLayout'
import EsgNews from './esgNews'
import Sidebar from '../sideBarMain'
import Errorpage from '../errorpage'
import MetaTagesAdder from '../MetaTagesAdder'
import SingleNews from './singleNews'



const NewsIntelligence = () => {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const increment = 10;

  const [activeSection, setActiveSection] = useState('home');
  const [activeText, setActiveText] = useState('');
  const [showIndustries, setShowIndustries] = useState(isDesktop);
  const [clickedText, setClickedText] = useState([]);

  const [loading, setLoading] = useState(false);
  const [industryNews, setIndsutryNews] = useState([]);


  //TOGGLE SHOW INDUSTRIES MENU
  const toggleShowIndustries = () => {
    setShowIndustries(!showIndustries)
  }

  //TO HANDLE THE INDUSTRY NAME CLICKED
  const addToList = (event) => {
    const text = event.target.textContent
    setClickedText(text)
  }

  //HANDLE WHICH SECTION IS ACTIVE
  const handleTextClick = (text) => {
    setActiveText(text);
    if (!isDesktop) {
      toggleShowIndustries()
    }
  }



  return (
    <div className="w-full h-screen overflow-y-auto pt-20">
      <Routes>
        <Route className={`w-full section `} element={<MetaTagesAdder><HomeNews /></MetaTagesAdder>} path='/home-news' />
        <Route className={`w-full section `} element={<MetaTagesAdder><SectorsInNews /></MetaTagesAdder>} path='/sectors' />
        <Route className={`w-full section `} element={<MetaTagesAdder><CompanyInNews /></MetaTagesAdder>} path='/company-in-news' />
        <Route className={`w-full section `} element={<MetaTagesAdder><FundingNews /></MetaTagesAdder>} path='/funding' />
        <Route className={`w-full section `} element={<MetaTagesAdder><Deals /></MetaTagesAdder>} path='/deals-news' />
        <Route path="/news/:id/:headline" element={<SingleNews />} />
        <Route element={<MetaTagesAdder><EsgNews /></MetaTagesAdder>} path='/esg' />
        <Route element={<Errorpage />} path="/*" />
        <Route className={`section w-full`} element={<SectorsPanelNews industryNews={industryNews} loading={loading} />} path='/sectors/*' />
      </Routes>
    </div>
  )
}

export default NewsIntelligence
