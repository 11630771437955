import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import factacy from "../../assets/factacy_logo.svg";
import arrow from "../../assets/arrow-down-double-line.png";
import axios from "axios";
import Loader from "../tearSheet/loader";
import { handleSectorClick } from "../../constant/function";
import Competitor from "../tearSheet/competitor";
import { goToCompanyPage, getCurrentMonth } from "../../constant/function";
import { useAuth } from "../auth/authContext";
import LoginButton from "../auth/loginButton";
import { DEALS_BY_ART_IDS, COMPANY_SEARCH_BY_CIN, GET_ALL_DEALS, ALT_COMPANY_DEALS } from '../../constant/api';


const Individual = ({ item }) => {

    const { referenceId } = useParams();
    const [deals, setDeals] = useState([]);
    const [clickedDealId, setClickedDealId] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [competitors, setcompetitors] = useState([]);

    const [isSourceOpen, setSourceOpen] = useState(false);
    const handleSourceClick = (dealId) => {
        setSourceOpen((prevState) => ({
            ...prevState,
            [dealId]: !prevState[dealId],
        }));
    };


    const handleAltClick = async (alt, cin) => {
        setDeals([]);
        setcompetitors([]);
        setLoading(true);
        const dealIds = alt && Array.isArray(alt) && alt.map((id) => id[0]);
        const formattedIds = dealIds && dealIds.slice(0, 350).join(",");
        try {
            const responses = await axios.get(DEALS_BY_ART_IDS + formattedIds);

            if (cin !== undefined && cin !== null && cin !== "No") {
                const compResponses = await axios.get(COMPANY_SEARCH_BY_CIN + cin);
                setcompetitors(compResponses.data);
            }
            setDeals(responses.data);
            setLoading(false);
        } catch { }
        setLoading(false);
    };



    return (<div >
        {item.ALT_Investee && (
            <div
                className="bg-white w-full p-3 lg:p-4 shadow-lg flex flex-col h-max gap-5 rounded-lg"
            >
                <div className={`w-full flex  lg:flex-row sm:flex-col lg:gap-0 sm:gap-6 justify-between ${clickedDealId === item.id ? "bg-gray-100 p-2" : ""}`}>
                    <div className="flex flex-col lg:w-2/6 sm:w-full">
                        <p className="text-xs">Portfolio Company</p>
                        <div className="flex items-center gap-3">
                            {item.Logo_Url ? (
                                <img
                                    src={item.Logo_Url}
                                    className="w-12 h-10 object-contain py-2"
                                />
                            ) : (
                                <img src={factacy} className="w-12 h-12" />
                            )}

                            <p className="font-bold text-xl">
                                {" "}
                                {item.Corrected_Investee}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col lg:w-2/4 sm:w-full">
                        <p className="text-xs">Sector</p>
                        <p>
                            {item.Sector_Classification ? (
                                item.Sector_Classification.map((ar1, indexo) => (
                                    <span
                                        className={`cursor-pointer hover:underline flex flex-col hover:text-primaryBlue font-bold text-sm`}
                                        key={indexo}
                                    // onClick={() => handleSectorClick(ar1[0])}
                                    >
                                        {indexo < 2 && ar1 != null && ar1[1]}
                                    </span>
                                ))
                            ) : (
                                <span className="font-bold sm:text-base lg:text-base">
                                    {item.Sector ? item.Sector.toUpperCase() : "-"}
                                </span>
                            )}
                        </p>
                    </div>
                    <div className="flex flex-col lg:w-1/4 sm:w-full">
                        <p className="text-xs">Round</p>
                        <p className="font-bold">
                            {" "}
                            {item?.Corrected_Series?.toUpperCase()}
                        </p>
                    </div>
                    <div className="flex flex-col lg:w-1/4 sm:w-full">
                        <p className="text-xs">Published Date</p>
                        <p className="font-bold">
                            {item.Unique_date_time &&
                                getCurrentMonth(
                                    item.Unique_date_time[item.Unique_date_time.length - 1]
                                )
                                ? `${getCurrentMonth(
                                    item.Unique_date_time[
                                    item.Unique_date_time.length - 1
                                    ]
                                ).month
                                } ${getCurrentMonth(
                                    item.Unique_date_time[
                                    item.Unique_date_time.length - 1
                                    ]
                                ).year
                                }`
                                : "Date Not Available"}
                        </p>
                    </div>

                    <div
                        className="flex lg:w-1/4 sm:w-full items-center justify-center gapy-1 px-2 hover:text-primaryBlue hover:underline cursor-pointer"
                        onClick={() => {
                            if (clickedDealId !== item.id) {
                                {
                                    handleAltClick(item.ALT_Investee, item.Output_CIN);
                                    setClickedDealId(item.id);
                                    handleSourceClick(item.id);

                                    // fetchCompetitors(item.Output_CIN)
                                }
                            } else {
                                setClickedDealId(null);
                            }
                        }}
                    >
                        <p className="text-base font-bold"> Alternate Companies</p>
                        <img src={arrow} className="w-4 h-4" />
                    </div>
                </div>

                {clickedDealId === item.id && (
                    <>
                        <p className="font-medium text-[25px] tracking-tight leading-9">
                            Alternate Funded Companies
                        </p>
                        <div className="w-full rounded-lg border border-slate-400 overflow-x-scroll">
                            <table className="table-auto w-full ">
                                <thead>
                                    <tr className="text-left">
                                        <th className="border-b p-2 bg-gray-100 rounded-tl-lg">
                                            Alternate Companies
                                        </th>
                                        <th className="border-b p-2 bg-gray-100">Sector</th>
                                        <th className="border-b p-2 bg-gray-100">Round</th>
                                        <th className="border-b p-2 bg-gray-100 rounded-tr-lg">Published Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {deals.map((deal, ind) => (
                                        <tr key={ind}>
                                            <td className="flex gap-2 py-1 px-4">
                                                {deal.Logo_Url ? (
                                                    <img
                                                        src={deal.Logo_Url}
                                                        className="w-5 h-5 lg:w-8 lg:h-8 object-contain"
                                                    />
                                                ) : (
                                                    <img src={factacy} className="w-5 h-5 lg:w-8 lg:h-8" />
                                                )}

                                                <p
                                                    className="text-sm hover:underline hover:text-primaryBlue cursor-pointer"
                                                    onClick={() =>
                                                        goToCompanyPage(deal["Output_CIN"])
                                                    }
                                                >
                                                    {deal.Corrected_Investee}
                                                </p>
                                            </td>

                                            <td className="py-1 px-2">
                                                {deal.Sector_Classification ? (
                                                    deal.Sector_Classification.map(
                                                        (ar1, indexo) => (
                                                            <span
                                                                className={`cursor-pointer hover:underline hover:text-primaryBlue text-sm`}
                                                                key={indexo}
                                                                onClick={() =>
                                                                    handleSectorClick(ar1[0])
                                                                }
                                                            >
                                                                {indexo < 2 &&
                                                                    ar1 != null &&
                                                                    ar1[1] + ", "}
                                                            </span>
                                                        )
                                                    )
                                                ) : (
                                                    <p className="sm:text-base lg:text-sm">
                                                        {deal.Sector
                                                            ? deal.Sector.toUpperCase()
                                                            : "-"}
                                                    </p>
                                                )}
                                            </td>

                                            <td className="py-1 px-2">
                                                {deal && deal.Corrected_Series && deal.Corrected_Series.toUpperCase()}
                                            </td>

                                            <td className="py-1 px-2">
                                                {" "}
                                                {deal.Unique_date_time &&
                                                    getCurrentMonth(
                                                        deal.Unique_date_time[
                                                        deal.Unique_date_time.length - 1
                                                        ]
                                                    )
                                                    ? `${getCurrentMonth(
                                                        deal.Unique_date_time[
                                                        deal.Unique_date_time.length - 1
                                                        ]
                                                    ).month
                                                    } ${getCurrentMonth(
                                                        deal.Unique_date_time[
                                                        deal.Unique_date_time.length - 1
                                                        ]
                                                    ).year
                                                    }`
                                                    : "Date Not Available"}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <p className="font-medium text-[25px] tracking-tight leading-9">
                                Other Competitors
                            </p>
                            {competitors && competitors.length > 0 && (
                                <Competitor company={competitors} />
                            )}
                        </div>
                        {isLoading && <Loader />}
                    </>
                )}
            </div>
        )}
    </div>)
}
const AltDealsPage = () => {

    const firstApiStatusText = { loading: "loading", error: "error", succes: "succes", initial: "initial" }
    const secondApiStatusText = { ...firstApiStatusText };

    const { referenceId } = useParams();
    const [firstApiStatus, setFirstApiStatus] = useState(firstApiStatusText.initial);
    const [secondApiStatus, setSecondApiStatus] = useState(secondApiStatusText.initial);

    const { user } = useAuth();
    const [companyData, setCompanyData] = useState({});
    const [altInvesteesData, setAltInvesteeData] = useState([]);
    const altInvesteeIds = companyData?.ALT_Investee?.slice(0, 50)?.map(item => item[0])?.join(",");

    useEffect(() => {
        if (altInvesteeIds) {

            (async () => {
                try {
                    setSecondApiStatus(secondApiStatusText.loading);
                    const res = await fetch(GET_ALL_DEALS + altInvesteeIds);
                    if (res.ok) {
                        const data = await res.json();
                        setAltInvesteeData(data);
                        setSecondApiStatus(secondApiStatusText.succes);
                    }
                    else {
                        throw new Error(" ");
                    }
                } catch (error) {
                    setSecondApiStatus(secondApiStatusText.error);
                }
            }
            )();
        }
    }, [altInvesteeIds]);

    useEffect(() => {

        if (referenceId) {
            (async () => {
                try {
                    setFirstApiStatus(firstApiStatusText.loading);
                    const res = await fetch(ALT_COMPANY_DEALS + referenceId);
                    if (res.ok) {
                        const data = await res.json();
                        setCompanyData(data[0]);
                        setFirstApiStatus(firstApiStatusText.succes);
                    }
                    else {
                        throw new Error(" ");
                    }
                } catch (error) {
                    setFirstApiStatus(firstApiStatusText.error);
                }
            }
            )();
        }

    }, [referenceId]);

    return (
        <>
            {
                user.isAuthenticated ? (
                    <>
                        {
                            firstApiStatus === firstApiStatusText.loading && (<div className=' w-full h-screen flex items-center justify-center'>
                                <Loader />
                            </div>)
                        }
                        {
                            firstApiStatus === firstApiStatusText.error && (<div className=' w-full p-4'>
                                <h4 className='text-xl'></h4>
                            </div>)
                        }
                        {
                            firstApiStatus === firstApiStatusText.succes &&

                            (<div className="sm:mt-10 lg:mt-0 px-4 lg:px-4 lg:py-0 flex flex-col gap-4 ">
                                {companyData ? (
                                    <div
                                        className="flex w-full rounded-xl bg-white lg:px-8 sm:px-4 lg:py-6 sm:py-3 flex-col gap-3"
                                        style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
                                    >
                                        <div className="flex items-center">
                                            <p className="font-roboto lg:text-3xl sm:text-lg font-bold leading-9">
                                                {companyData?.Investee}
                                            </p>
                                        </div>
                                    </div>
                                )
                                    : (
                                        <div className=' w-full p-4'>
                                            <h4 className='text-xl'> There is Nothing for this Investor</h4>
                                        </div>
                                    )
                                }
                                {secondApiStatus === secondApiStatusText.loading && (<div className=' w-full h-screen flex items-center justify-center'>
                                    <Loader />
                                </div>)
                                }
                                {
                                    secondApiStatus === secondApiStatusText.succes && (<div className=' flex flex-col gap-4 '>
                                        {altInvesteesData?.map((item, index) => <Individual key={index} item={item} />)}
                                    </div>)
                                }
                            </div>)
                        }
                    </>
                )
                    :
                    (<div className=' w-full h-screen flex items-center justify-center'>
                        <LoginButton />
                    </div>)
            }
        </>
    );

    // return user.isAuthenticated ? loading === statusTexts.loading ? (
    //     <div className=' w-full h-screen flex items-center justify-center'>
    //         <Loader />
    //     </div>
    // ) : (
    //     <div className="sm:mt-10 lg:mt-0 px-2 py-2 lg:px-6 lg:py-2 flex flex-col gap-4 ">
    //         {companyData?.Investee && (
    //             <div
    //                 className="flex w-full rounded-xl bg-white lg:px-8 sm:px-4 lg:py-6 sm:py-3 flex-col gap-3"
    //                 style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
    //             >
    //                 <div className="flex items-center">
    //                     <p className="font-roboto lg:text-3xl sm:text-lg font-bold leading-9">
    //                         {companyData?.Investee}
    //                     </p>
    //                 </div>
    //             </div>
    //         )}

    //         {loading ? (<div className=' w-full h-screen flex items-center justify-center'>
    //             <Loader />
    //         </div>) :
    //             <div className=' flex flex-col gap-4 '>
    //                 {altInvesteesData?.map((item, index) => <Individual key={index} item={item} />)}
    //             </div>
    //         }
    //     </div>
    // ) : (<div className=' w-full h-screen flex items-center justify-center'>
    //     <LoginButton />
    // </div>)
}

export default AltDealsPage;