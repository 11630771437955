import React from 'react'
import SideBarLayoutPerson from './sideBarLayoutPerson'
import { Routes, Route } from 'react-router-dom'
import PersonPage from './personPage'
import PeopleSearch from './peopleSearch'
import SideBar from "../sideBarMain/index"
import Key_People from './Key_People'
import UnderConstruction from '../underConstruction'
import Errorpage from '../errorpage'
import MetaTagesAdder from '../MetaTagesAdder'

const PeopleIntelligence = () => {
  return (
    <div className="w-full h-screen overflow-y-auto pt-20">
          <Routes>
            {/* <Route className='w-full' element={<InvestorDashboard />} path='/' /> */}
            <Route className='w-full' element={<MetaTagesAdder><PersonPage /></MetaTagesAdder>} path='/person/:person' />
            <Route className='w-full' element={<MetaTagesAdder><PeopleSearch /></MetaTagesAdder>} path='/person-search' />
            <Route className='w-full' element={<MetaTagesAdder><Key_People /></MetaTagesAdder>} path='/key-people' />
            <Route className='w-full' element={<MetaTagesAdder><UnderConstruction /></MetaTagesAdder>} path='/cohorts-alumni-connections' />
            <Route className='w-full' element={<MetaTagesAdder><UnderConstruction /></MetaTagesAdder>} path='/person-watch' />
            <Route className='w-full' element={<MetaTagesAdder><UnderConstruction /></MetaTagesAdder>} path='/contacts' />
            <Route className='w-full' element={<MetaTagesAdder><UnderConstruction /></MetaTagesAdder>} path='/inventors-innovators' />
            <Route className='w-full' element={<MetaTagesAdder><UnderConstruction /></MetaTagesAdder>} path='/send-outreach' />
            <Route className='w-full' element={<Errorpage />} path='/*' />
          </Routes>
        </div>
  )
}

export default PeopleIntelligence;