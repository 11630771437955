import React from "react";
import LoginButton from "./auth/loginButton";

const LoginComponent = () => {
  return (
    <div className="flex items-center justify-center flex-col gap-3 h-80 w-full bg-white rounded-b-xl">
      Please login first <LoginButton className="w-5" />
    </div>
  );
};

export default LoginComponent;
