import React, { useEffect, useState } from 'react'
import InvestorPage from './investorPage'
import InvestorDashboard from './investorDashboard'
import { Routes, Route } from 'react-router-dom'
import SideBarLayoutInvestor from './sideBarLayoutInvestor'
import InvestorSearch from './investorSearch'
import AltPortfolioSearch from './altPortfolioSearch'
import AltPortfolioPage from './altPortfolioPage'
import SectorWiseActivity from './sectorWiseActivity'
import StageWiseActivity from './stageWiseActivity'
import PotentialInvestorsPage from './PotentialInvestorsPage';
import StagePage from './stagePage'
import InvestorLeaderboard from './investorLeaderboard'
import Sidebar from '../sideBarMain'
import InvestmentShifts from './investmentShifts'
import Errorpage from '../errorpage'
import MetaTagesAdder from '../MetaTagesAdder'
import UnderConstruction from '../underConstruction'


// const Redirect = () => {

//   const button = document.createElement('a')
//   button.setAttribute("href", "https://www.startupinvestors.ai/");
//   button.setAttribute("target", "_blank");
//   useEffect(()=>{button.click()},[])
//   return '';
// }

const InvestorIntelligence = () => {

  return (
    <div className="w-full h-screen overflow-y-auto pt-20">
      <Routes>
        {/* <Route element={<Redirect />} path='/xyz' /> */}
        <Route element={<MetaTagesAdder><InvestorDashboard /></MetaTagesAdder>} path='/investor-dashboard' />
        <Route element={<MetaTagesAdder><InvestorLeaderboard /></MetaTagesAdder>} path='/investor-leaderboard' />
        <Route element={<MetaTagesAdder><InvestorPage /></MetaTagesAdder>} path='/investor/*' />
        <Route element={<UnderConstruction />} path='/investor-watch' />
        <Route element={<MetaTagesAdder><InvestorSearch /></MetaTagesAdder>} path='/investor-search' />
        <Route element={<MetaTagesAdder><AltPortfolioSearch /></MetaTagesAdder>} path='/alt-portfolio' />
        <Route element={<MetaTagesAdder><AltPortfolioPage /></MetaTagesAdder>} path='/alt-portfolio/:investor' />
        <Route element={<MetaTagesAdder><SectorWiseActivity /></MetaTagesAdder>} path='/investor-activity-sectorwise' />
        <Route element={<MetaTagesAdder><StageWiseActivity /></MetaTagesAdder>} path='/investor-activity-stagewise' />
        <Route element={<MetaTagesAdder><StagePage /></MetaTagesAdder>} path='/investor-activity-stagewise/:stage' />
        <Route element={<MetaTagesAdder><PotentialInvestorsPage /></MetaTagesAdder>} path='/potential-investors' />
        <Route element={<MetaTagesAdder><InvestmentShifts /></MetaTagesAdder>} path='/investment-shifts-trends' />
        <Route element={<Errorpage />} path='/*' />
      </Routes>
    </div>
  )
}

export default InvestorIntelligence