
const metaTags = {

    // search

    "/company-intelligence/company-search": {
        keywords: "Tearsheet information, Startup Tearsheet data, Company Tearsheet details, Vendor Tearsheet search, Client Tearsheet information, Tearsheet database, Business Tearsheet resources",
        title: "Factacy Insights | Company Intelligence | Company search",
        description: " Search for Tearsheet information on startups, companies, vendors, clients and more",
    },

    "/people-intelligence/person-search": {
        title: "Factacy Insights | People Intelligence | Person search ",
        description: "Search for person of interests, investors and inventors.",
        keywords: "Person of interest search, Investor information search, Inventor profiles, Find investors, Discover inventors, Investor directory, Inventor search too",
    },

    "/investor-intelligence/investor-search": {
        title: "Factacy Insights | Investor Intelligence | Investor search",
        description: "Search for investors, PE, VC, Funds for their deals and investments",
        keywords: "Investor search, Private equity investors, Venture capital investors, Investment funds search, Investor deal tracking, PE and VC investments, Find investors for deals, Investor and fund database, Private equity deals, Venture capital funding",
    },

    "/sector-intelligence/sector-search": {
        title: "Factacy Insights | Sector Intelligence | Sector search",
        description: "Search for industries, sector for investment activity and innovations",
        keywords: "Industry investment search, Sector investment activity, Innovation in industries, Investment activity by sector, Industry innovation tracking, Search for sector investments, Investment trends by industry, Sector innovation search, Industry investment data",
    },

    "/patent-intelligence/patent-search": {
        title: "Factacy Insights | Patent Intelligence | Patent search",
        description: "Search for a patent, patent holder",
        keywords: "Patent search, Find patents, Patent holder lookup, Patent database search, Search for patent holders, Patent information search, Locate patent holders, Patent registry search, Patent holder information, Patent lookup tool, Search for registered patents",
    },



    // Deal Intelligence

    "/deal-intelligence/deals-dashboard": {
        title: "Factacy Insights | Deal Intelligence | Deals dashboard",
        description: " Discover detailed insights into funding rounds, mergers, acquisitions, and venture capital investments across various industries",
        keywords: "Deals dashboard, Annual deal tracking, Yearly deal summary, Yearly investment deals, Deal trends by year, Deal activity by year, Yearly deal insights, Yearly deal performance",
    },
    "/deal-intelligence/deals": {
        title: "Factacy Insights | Deal Intelligence | Deals",
        description: "Discover which companies have secured significant funding in their latest rounds, including key details on investors and funding amounts",
        keywords: "Latest deals, Recent investments, New funding rounds, Current financing news, Fresh capital raises, Recent funding updates, Latest funding rounds, Company funding news, Recent deal highlights, Fundraising updates",
    },
    "/deal-intelligence/historical-deals": {
        title: "Factacy Insights | Deal Intelligence | Historical deals",
        description: "Explore the historical business deals worldwide. Easily search by company name, sector, and more",
        keywords: "Historical deals, Past company deals, Deal history search, Company investment history, Historical investment deals, Search past deals, Company deal archives, Sector-specific deal history, Historical funding rounds, Deal records by year",
    },
    "/deal-intelligence/open-deals": {
        title: "Factacy Insights | Deal Intelligence | Open deals",
        description: "Explore upcoming funding rounds, acquisitions, and strategic partnerships planned by companies",
        keywords: "Open deals, Current funding opportunities, Active investment deals, Ongoing fundraising rounds, Current financing rounds, Open funding deals, Ongoing capital raises, Open fundraising opportunities, Active investment rounds",
    },
    "/deal-intelligence/alt-deals": {
        title: "Factacy Insights | Deal Intelligence | Alt deals",
        description: "Explore alternative investment opportunities with our Alt Investors database.",
        keywords: "Alt investors, Alternative investment sources, Alternative investors, New investor search, Discover alternative investors, Alt portfolio investors",
    },
    "/deal-intelligence/exits": {},
    "/deal-intelligence/investment-trends-themes": {},
    "deal-intelligence/reports/latest-every-day": {},

    // Company Intelligence
    "/company-intelligence/company-dashboard": {
        title: "Factacy Insights | Company Intelligence | Company dashboard",
        description: "Gain valuable insights into your company's performance across every state in India with our comprehensive Company Dashboard.",
        keywords: "Company dashboard, State-wise company data, Company performance by state, India company dashboard, State-specific company insights, Company data by Indian state, Regional company statistics, State-level business data, Company metrics by state, Indian states company dashboard, State-wise business performance, Company analytics by state",
    },
    "/company-intelligence/company-watch": {
        title: "Factacy Insights | Company Intelligence | Company watch",
        description: "Effortlessly search and discover detailed Tearsheet information on startups, companies, vendors, clients, and more with our Company Watch service.",
        keywords: "Company watch, Startup Tearsheet, Company Tearsheet, Vendor Tearsheet, Client Tearsheet, Tearsheet information, Tearsheet data, Startup information, Company details, Vendor details, Client details",
    },
    "/company-intelligence/competitor-watch": {
        title: "Factacy Insights | Company Intelligence | Competitor watch",
        description: " Discover key insights into rival companies across industries, including market strategies, product offerings, financial performance, and more.",
        keywords: "Competitor watch Competitor analysis Find competitors Competitor search Competitor tracking Competitor monitoring Competitor discovery Identify competitors Competitor database",
    },

    // Investor Intelligence
    "/investor-intelligence/investor-dashboard": {
        title: "Factacy Insights | Investor Intelligence | Investor dashboard",
        description: "Gain valuable information on investor preferences, sector interests, and investment trends.",
        keywords: "Investor dashboard, Investor data analysis, Sector-specific investments, Investor sector insight, Investor activity tracking, Investor sector data, Sector-wise investment analysis, Sector investment trends",
    },
    "/investor-intelligence": {},
    "/investor-intelligence/investor-activity-sectorwise": {
        title: "Factacy Insights | Investor Intelligence | Investor activity sectorwise",
        description: "Discover the latest news and insights on investments, funding rounds, and strategic partnerships within your chosen industry.",
        keywords: "Sector-specific investment news, Investor activity by sector, Sector investment deals, Sector investment tracking, Sector investment updates, Investment deals by sector, Sector-focused investment news, Sector-specific investor news, Investment activity by sector",
    },
    "/investor-intelligence/investor-activity-stagewise": {
        title: "Factacy Insights | Investor Intelligence | Investor activity stagewise",
        description: "Stay updated with stage-wise investor activity and deal news on our platform. Explore detailed insights on funding rounds, including the amount invested and the series stage, such as Seed, Series A, B, and beyond.",
        keywords: "Stage-wise investor activity, Investment stages tracking, Investor deals by stage, Stage-specific investment news, Investment activity by series, Series-based investor activity, Stage-focused investment deals, Investor activity by stage",
    },
    "/investor-intelligence/investor-leaderboard": {
        title: "Factacy Insights | Investor Intelligence | Investor leaderboard",
        description: "Get insights into their most frequent investments, preferred companies, and strategic focus areas. ",
        keywords: "Investor leaderboard, Top investors, Leading investors, Top 5 investors, Investor rankings, Top investor trends, Leading investor activity, Top investor investments, Frequent investor trends",
    },
    "/investor-intelligence/investment-shifts-trends": {
        title: "Factacy Insights | Investor Intelligence | Investment shifts trends",
        description: "Stay informed with our Investment Shifts and Trends updates. Access monthly summaries of deal activities, including the number and value of deals closed.",
        keywords: "Investment shifts and trends, Deal activity by month, Monthly investment shifts, Investment trends analysis, Monthly deal tracking, Investment pattern shifts, Monthly investment data, Deal trends by month, Investment shifts report, Deal flow analysis",
    },
    "/investor-intelligence/alt-portfolio": {
        title: "Factacy Insights | Investor Intelligence | Alt portfolio",
        description: "Explore potential investment opportunities with our ALT Portfolio. Search for any company and gain detailed insights along with a curated list of similar companies ripe for investment.",
        keywords: "ALT Portfolio, Alternative investment opportunities, Investor alternative portfolio, Alternative company investments, Discover new investments, Alt investment options, Alternative investment data, New company investment options, Investor alternative choices, Alt investment portfolio",
    },
    "/investor-intelligence/potential-investors": {},

    //Sector Intelligence
    "/sector-intelligence/sector-dashboard": {
        title: "Factacy Insights | Sector Intelligence | Sector dashboard",
        description: "Discover key investment trends with our Sector Dashboard. Access comprehensive data on sectors receiving extensive investor interest and funding.",
        keywords: "Sector dashboard, Investment by sector, Sector investment data, Sector investment trends, Sector analysis dashboard, Sector investment overview, Investment sectors dashboard, Sector performance data, Sector investment activity",
    },
    "/sector-intelligence/investors-smes": {},
    "/sector-intelligence/relevent-companies": {},
    "/sector-intelligence/sector-innovations": {},

    // Innovations
    "/patent-intelligence/patent-dashboard": {
        title: "Factacy Insights | Patent Intelligence | Patent dashboard",
        description: " Explore our Patent Dashboard for detailed insights into company patents, categorized by sector",
        keywords: "Patent dashboard, Company patents overview, Sector-wise patents, Patent portfolio dashboard, Company patent analysis, Sector-based patent data, Company patent inventory, Sector-wise patent distribution, Patent portfolio by sector, Sector-specific patent data, Patent activity dashboard",
    },
    "/patent-intelligence/patent-search-by-industry": {
        title: "Factacy Insights | Patent Intelligence | Patent search by industry",
        description: "Discover comprehensive insights into patents categorized by industry with our Patents by Industry page.",
        keywords: "Patents by industry, Industry-specific patents, Patent distribution by industry, Industry patent insights, Patents by industry sector, Industry-based patent data, Patent portfolio by industry, Industry patent analysis, Patents categorized by industry, Industry-focused patents",
    },
    "/patent-intelligence/patent-search-by-company": {
        title: "Factacy Insights | Patent Intelligence | Patent search by company",
        description: "Explore detailed insights into patents categorized by company with our Patents by Company page. ",
        keywords: "Patents by company, Company-specific patents, Patent distribution by company, Company patent insights, Patents by company name, Company-based patent data, Patent portfolio by company, Company patent analysis, Patents categorized by company, Company-focused patents",
    },
    "/patent-intelligence/patent-search-by-investor": {},


    // people Intelligence
    "/people-intelligence/cohorts-alumni-connections": {},
    "/people-intelligence/contacts": {},
    "/people-intelligence/person-watch": {},
    "/people-intelligence/inventors-innovators": {},
    "/people-intelligence/key-people": {
        title: "Factacy Insights | People Intelligence | Key people",
        description: "Discover detailed information about influential individuals with our Key People page. Access data on key figures, including their designations and associated companies.",
        keywords: "Key people, Company executives, Leadership team, Executive profiles, Key personnel, Management team, Company officers, Leadership roles, Executive team, Key figures, Corporate leadership, Board of directors",
    },
    "/people-intelligence/send-outreach": {},


    //  news Intelligence
    "/news-intelligence/home-news": {
        title: "Factacy Insights | News Intelligence | Home news",
        description: "Explore the latest news and hot topics in the business world with our dedicated Hot Topics platform.",
        keywords: "Hot topics, Latest company news, Company updates, Corporate news, Industry news, Business headlines, Company announcements, Latest business news, Company developments, Corporate updates",
    },
    "/news-intelligence/company-in-news": {
        title: "Factacy Insights | News Intelligence | Company in news",
        description: "Stay updated with the latest happenings on our Companies. Access current news and updates on companies making headlines.",
        keywords: "Latest company updates, Trending companies, Company news highlights, News-worthy companies, Companies making headlines, Featured companies, Breaking company news, Companies in the spotlight, Popular companies in news, Current company news, Companies featured in news",
    },
    "/news-intelligence/deals-news": {},
    "/news-intelligence/funding": {
        title: "Factacy Insights | News Intelligence | Deals news",
        description: " Stay updated with our Funding News section, featuring the latest updates on company funding rounds.",
        keywords: "Funding news, Investment updates, Funding announcements, Company funding news, Latest funding rounds, Investment news, Funding rounds updates, Capital raises news, Venture funding news, Funding round announcements, Investment funding updates",
    },
    "/news-intelligence/sectors": {
        title: "Factacy Insights | News Intelligence | Sectors",
        description: "Stay informed with our Sectors in News section, where you can explore the latest updates and developments across various industries",
        keywords: "Sectors in news, Industry news updates, Sector-specific news, Latest sector news, Sector news updates, Industry updates, Business sector news, Sector news headlines, Current sector news, News by industry sector",
    },
    "/news-intelligence/esg": {
        title: "Factacy Insights | News Intelligence | ESG",
        description: "Explore the latest Environmental, Social, and Governance (ESG) news and updates on our platform",
        keywords: "ESG news, Environmental news, Social governance news, ESG updates, ESG trends, Environmental news updates, Social governance updates, ESG developments, ESG initiatives, ESG reports",
    },

    // leaderbords
    "/leaderboards/leading-investors": {},
    "/leaderboards/leading-sectors": {
        title: "Factacy Insights |  leaderboards | Leading Sectors",
        description: "Discover leading sectors with our comprehensive data on industries with the highest number of deals",
        keywords: "Leading sectors, Top sectors by deals, Sector with most deals, Highest deal sectors, Sectors with highest investments, Most active sectors, Leading industries by deals, Top deal-making sectors, Key sectors for investments, Sector deal leaders, Major deal sectors",
    },
    "/leaderboards/unicorns": {
        title: "Factacy Insights |  leaderboards | Unicorns",
        description: "Explore our Unicorn Companies page for insights into the latest unicorn startups. ",
        keywords: "Unicorn companies, Unicorn startups, Billion-dollar startups, Tech unicorns, High-value startups, Unicorn company profiles, Unicorn news, Top unicorn companies, Unicorn investments, Unicorn trends, Latest unicorn updates",
    },
    "/leaderboards/soonicorns": {},
    "/leaderboards/shark-tank-companies": {
        title: "Factacy Insights |  leaderboards | Soonicorns",
        description: "Explore insights into companies featured on Shark Tank India with our dedicated platform. Discover innovative startups, their pitches, and investor interactions.",
        keywords: "Shark Tank India companies, Companies from Shark Tank India, Shark Tank India startups, Shark Tank India pitches, Shark Tank India entrepreneurs, Shark Tank India investors, Shark Tank India deals, Shark Tank India participants, Shark Tank India business ideas",
    },
}

export default metaTags;