import React, { useEffect } from "react";
import Loader from "./loader";
import { useSelector } from "react-redux";
import KeyPeopleCard from "../../pages/keyPeopleCard";

const KeyPeople = ({ loading, input, getPersonData }) => {
  useEffect(() => {
    getPersonData(input);
  }, [input]);
  const personData = useSelector((state) => state.company).keyPeopleData[input];

  // useAddMetaTags(company?.Brand ?? company?.Company_Name, "Key People, Founders, Officers", company?.Keywords, company?.KP_Description)


  return (
    <div className="bg-white sm:w-full rounded-b-xl flex flex-col lg:p-8 sm:p-4 gap-6">
      {/* <p className="font-medium text-[25px] tracking-tight leading-9">
        Key People
      </p> */}

      {loading ? (
        <div className="h-screen flex items-center w-full justify-center">
          {" "}
          <Loader />{" "}
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <p className="font-semibold text-2xl">Officers and Directors</p>

        <div className="flex w-full flex-wrap gap-6 justify-between">

          {personData?.filter((ppl) =>ppl?.Person_Data?.Relation !== "Investor" && ppl?.Person_Data?.Relation !== "Shareholder")?.map((ppl, i) => (
            <KeyPeopleCard ppl={ppl} i={i} />
          ))}
          </div>


        <div className="flex flex-col gap-4 pt-3">
        <p className="font-semibold text-2xl">Investors and Shareholders</p>
        <div className="flex w-full flex-wrap gap-6 justify-between">

          {personData?.filter((ppl) =>ppl?.Person_Data?.Relation === "Investor" || ppl?.Person_Data?.Relation === "Shareholder")?.map((ppl, i) => (
            <KeyPeopleCard ppl={ppl} i={i} />
          ))}
          </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default KeyPeople;


// <div
//               className="lg:w-5/12 sm:w-full flex flex-col  rounded-lg cursor-pointer hover:scale-105 duration-200"
//               key={i}
//               style={{
//                 boxShadow:
//                   "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
//               }}
//             >
//               <div className="lg:py-4 sm:py-4 px-4 flex flex-col gap-4">
//                 <div className="flex justify-between items-center">
//                   <div className="flex gap-3 items-center">
//                     <img src={person} className="w-[72px] h-72px]"></img>
//                     <div className="flex flex-col gap-1">
//                       <p className="font-medium lg:text-xl sm:text-lg hover:text-primaryBlue hover:underline" onClick={() => window.open(`/people-intelligence/person/${ppl.id}`)}>
//                         {ppl.Person_Data.Name ?? "Unconfirmed"}
//                       </p>
//                       <p className="lg:text-base sm:text-sm">
//                         {ppl.Person_Data.Designation ?? "Unconfirmed"} {ppl.Person_Data.Relation?", " +ppl.Person_Data.Relation : ""}
//                       </p>
//                     </div>
//                   </div>
//                   <div className="flex gap-2" onClick={() => ppl.Person_Data.Linkedin && window.open(`${ppl.Person_Data.Linkedin}`)}>
//                     <svg
//                       width="25"
//                       height="25"
//                       viewBox="0 0 21 21"
//                       fill="none"
//                       xmlns="http://www.w3.org/2000/svg"
//                     >
//                       <circle cx="10.9109" cy="10.1343" r="10" fill="#3E91EE" />
//                       <g clipPath="url(#clip0_6_677)">
//                         <path
//                           d="M9.16975 7.0038C9.16964 7.21811 9.0844 7.42361 8.93278 7.57508C8.78116 7.72655 8.57558 7.81158 8.36127 7.81147C8.14695 7.81137 7.94145 7.72613 7.78998 7.57451C7.63852 7.42289 7.55348 7.21731 7.55359 7.00299C7.5537 6.78867 7.63894 6.58318 7.79056 6.43171C7.94218 6.28024 8.14776 6.19521 8.36207 6.19531C8.57639 6.19542 8.78189 6.28066 8.93335 6.43228C9.08482 6.5839 9.16986 6.78948 9.16975 7.0038ZM9.19399 8.40986H7.57783V13.4684H9.19399V8.40986ZM11.7475 8.40986H10.1394V13.4684H11.7314V10.8139C11.7314 9.33511 13.6586 9.19774 13.6586 10.8139V13.4684H15.2546V10.2644C15.2546 7.77147 12.4021 7.8644 11.7314 9.08865L11.7475 8.40986Z"
//                           fill="white"
//                         />
//                       </g>
//                       <defs>
//                         <clipPath id="clip0_6_677">
//                           <rect
//                             width="9.69697"
//                             height="9.69697"
//                             fill="white"
//                             transform="translate(6.36548 4.98291)"
//                           />
//                         </clipPath>
//                       </defs>
//                     </svg>
//                     {/* <svg
//                       width="21"
//                       height="21"
//                       viewBox="0 0 21 21"
//                       fill="none"
//                       xmlns="http://www.w3.org/2000/svg"
//                     >
//                       <circle cx="10.9109" cy="10.1343" r="10" fill="#3E91EE" />
//                       <g clipPath="url(#clip0_6_682)">
//                         <path
//                           d="M15.3198 7.87403C15.0113 8.01047 14.6842 8.10008 14.3493 8.13989C14.7023 7.92875 14.9665 7.59646 15.0927 7.20494C14.7614 7.40211 14.3982 7.54029 14.0196 7.61504C13.7653 7.34295 13.4282 7.1625 13.0608 7.10174C12.6933 7.04098 12.3161 7.10332 11.9878 7.27906C11.6594 7.4548 11.3983 7.73411 11.2451 8.07354C11.0918 8.41298 11.055 8.79354 11.1404 9.15605C10.4685 9.12238 9.81123 8.94778 9.21121 8.64359C8.6112 8.33941 8.08186 7.91243 7.65756 7.39039C7.50738 7.64835 7.42845 7.9416 7.42887 8.24009C7.42887 8.82595 7.72706 9.34353 8.18039 9.64656C7.91211 9.63811 7.64974 9.56566 7.41514 9.43524V9.45625C7.41522 9.84644 7.55024 10.2246 7.7973 10.5266C8.04436 10.8286 8.38826 11.0358 8.77069 11.1132C8.52165 11.1807 8.26051 11.1907 8.00706 11.1423C8.11488 11.4782 8.32504 11.7719 8.6081 11.9824C8.89117 12.1928 9.23297 12.3095 9.58564 12.3161C9.23513 12.5913 8.8338 12.7948 8.40458 12.9149C7.97537 13.035 7.5267 13.0693 7.08423 13.0158C7.85663 13.5126 8.75578 13.7763 9.67413 13.7754C12.7824 13.7754 14.4822 11.2005 14.4822 8.96736C14.4822 8.89464 14.4802 8.8211 14.477 8.74918C14.8078 8.51006 15.0934 8.21384 15.3202 7.87444L15.3198 7.87403Z"
//                           fill="white"
//                         />
//                       </g>
//                       <defs>
//                         <clipPath id="clip0_6_682">
//                           <rect
//                             width="9.69697"
//                             height="9.69697"
//                             fill="white"
//                             transform="translate(6.36548 5.58887)"
//                           />
//                         </clipPath>
//                       </defs>
//                     </svg> */}
//                   </div>
//                 </div>
//                 <div className="flex sm:flex-wrap sm:gap-8 justify-between">
//                   <div className="flex flex-col">
//                     <p className="text-xs text-[#A1A1AA]">Department</p>
//                     <p className="lg:text-base sm:text-sm">Unconfirmed</p>
//                   </div>
//                   <div className="flex flex-col">
//                     <p className="text-xs text-[#A1A1AA]">Seniority</p>
//                     <p className="lg:text-base sm:text-sm">Unconfirmed</p>
//                   </div>
//                   <div className="flex flex-col">
//                     <p className="text-xs text-[#A1A1AA]">Contact Number</p>
//                     <p className="lg:text-base sm:text-sm">Unconfirmed</p>
//                   </div>
//                 </div>
//               </div>
//             </div>
