import React, { useEffect, useState } from "react";
import { KEY_PEOPLE_TOP_25 } from "../../constant/api";
import Persion_Card from "./Persion_Card";
import Loder from "../tearSheet/loader";
import { useAuth } from "../auth/authContext";
import LoginButton from "../auth/loginButton";

const Key_People = () => {
  const designations = [
    "Chief Commercial Officer",
    "Executive Director",
    "Vice President",
    "Equity Research Analyst",
    "Founder",
    "President",
    "Managing Director",
    "Director",
    "Chief Financial Officer",
    "Chairman",
    "Senior Vice President",
    "Co-Founder",
    "Research Analyst",
    "Chief Business Officer",
    "Chief Technology Officer",
    "Auditor",
    "Principal",
    "Manager",
    "Economist",
    "Chief Operating Officer",
    "Chief Executive Officer",
    "Chief Human Resources Officer",
    "Co-Founder And Ceo",
    "Head Of Retail Research",
    "Chief Business Officer",
    "Secretary",
    "Chief Marketing Officer",
    "Chief Legal Officer",
    "Senior Executive Director And Business Head",
    "Chief Technology Officer",
    "Co-Founder And Chief Business Officer",
    "Chief Growth Officer",
    "Chief Marketing Officer",
    "Executive Vice President & Chief Technology Officer",
    "Chief Risk Officer",
    "Executive Vice President",
    "Senior Vice President And General Manager",
    "Chief Revenue Officer",
    "Chief Strategy Officer",
    "Chairman And Managing Director",
    "Co-Founder And Managing Director",
    "Chairman Of The Board",
    "Managing Director And Chief Executive Officer",
    "General Manager",
    "Founder And Ceo",
    "Joint Managing Director",
    "Director And Chairman",
    "Chief Investment Officer",
    "Founder And Chief Executive Officer",
    "Chief Economist",
    "Chief Financial Officer",
    "Hr Advisor",
    "Deputy Director",
    "Executive Chairman",
    "Architect",
    "Chief Product Officer",
    "Hr",
    "Vice President",
    "Chief Sales Officer",
    "Joint Director",
    "Vice President & Company Secretary",
    "Senior Managing Director",
    "Managing Director (Global)",
    "Chairperson And Senior Managing Director (India)",
    "Group Chief Executive - Technology And Chief Technology Officer",
    "Managing Director And Technology Sustainability Innovation Lead",
    "Managing Director And Lead",
    "Chief Information Security Officer & Chief Technology Officer",
    "Secretary And Joint President",
    "Co-Founder And Chief Operating Officer",
    "Chief Marketing Officer And Head - Products",
    "Co-Founder And Chief Operating Officer",
    "Ex-Chief Executive Officer",
    "Chairman & Managing Director",
    "Co-Founder And Chief Technology Officer",
    "Partner And Chief Credit Officer",
    "Chief Business Officer",
    "Heart And Lung Transplant Surgeon",
    "Head And Neck Surgeon",
    "Group Lead - Sustainability & Esg And Vice President - Public Affairs",
    "Group Medical Director",
    "Unit Head & Vice President",
    "Co-Chairperson",
    "Deputy General Manager",
    "Senior Consultant Rheumatologist",
    "Chairman And Managing Director",
    "Vice President",
    "Senior Vice-President",
    "Chief Information Officer",
    "Tourism Manager",
    "Deputy Ceo",
    "Chief Product And Technology Officer",
    "Chief Development Officer (Cdo)",
    "Managing Director And Co-Founder",
    "Co-Founder And Chief Platform Architect",
    "Chief Executive Officer And Co-Founder",
    "Vice President (International Business)",
    "Chief Operating Officer (Cinemas)",
    "Chief Operating Officer (Live Entertainment & Venues)",
    "Co-Founder And Former Ceo",
    "Managing Director And Ceo",
    "Chief Executive Officer & Managing Director",
    "Executive Director And Managing Director",
    "Additional Secretary (Dot)",
    "Co-Founder & Ceo",
    "Co-Founder And Chief Innovation Officer",
    "Co-Founder And Director",
    "Founder And Design Director",
    "Founder And Managing Director",
    "Co-Founder And Chief Executive Officer",
    "Chief Executive Officer (Used Car Business)",
    "Chief Technology Officer And Chief Business Officer",
    "Co-Founder And Chief Operating Officer",
    "Vice Chairman",
    "Research Analyst",
    "Vice-Chairman",
    "Chief Executive Officer And Co-Founder",
    "Founder & Director",
    "Vice President Of Marketing And Co-Founder",
    "Founder And Chief Technology Officer",
    "Chief Executive Officer And Founder",
    "Founder & Ceo",
    "Co-Founder & Advisor",
    "Co-Founder Chief Operating Officer And Chief Product Officer",
    "Joint Company Secretary",
    "Vice President-Sales",
    "President And Chief Business Officer",
    "Chief Financial Officer And Key Managerial Personnel",
    "Vice-President Of Digital, Innovation, And Architecture",
    "Creative Director",
    "Senior Vice-President",
    "Chief Technology Officer And Co-Founder",
    "Co-Chief Executive Officer And Founder",
    "Vice President, Human Resources",
    "Non-Executive Chairman",
    "Executive Director & Chief Business Officer",
    "Deputy Manager Legal",
    "Senior Executive Director & Business Head",
    "Senior Vice President & Chief Marketing Officer",
    "Executive Director Hospitality",
    "Co-Founder And Chief Technology Officer",
    "Founder And Chief Engineer",
    "Co-Founder And Chief Product Officer",
    "Co-Founder And Managing Director",
    "Chief Executive Officer And Co-Founder",
    "Chartered Accountant",
    "Chief Operating Officer",
    "Co-Founder And Chief Executive Officer",
    "Co-Founder And Chief Marketing Officer",
    "Product Manager",
    "Brand Manager",
    "Senior Director",
    "Chief People Officer",
    "Founder And Ceo",
    "Chief Customer And Marketing Officer",
    "Chief Legal And Compliance Officer",
    "Vice President (User Lifecycle)",
    "Associate Vice President-Equity Research",
    "Interim President And Chief Executive Officer",
    "Non-Executive Director",
    "Senior Vice President And Chief People Officer",
    "Former Senior Director Manufacturing",
    "Vice-President",
    "Co-Founder And Chief Executive Officer",
    "Senior Executive",
    "Chief Technology Officer And Co-Founder",
    "Managing Director And Chief Executive Officer",
    "Co-Founder And Head Of Customer Success",
    "Chief Executive Officer And Co-Founder",
    "President And Chief Executive Officer",
    "Chief Compliance Officer",
    "Chairman Emeritus",
    "Interim Chairperson And Chief Executive Officer",
    "Co-Founder & Chief Operating Officer",
    "Senior Vice President-Human Resources",
    "Director Human Resources",
    "Chief Executive Officer & Co-Founder",
    "Senior Vice President (Future Mobility)",
    "Executive Vice Chairman",
    "Senior Vice President (Mobility And Business Operations)",
    "Founder, Ceo, And Managing Partner",
    "Regional General Manager",
    "Director Of Delivery Product Management",
    "Vice President (Sales & Marketing)",
    "Chief Executive Officer And Director",
    "Vice President (Ccaas Ecosystem, Global Alliances & Partnerships)",
    "Co-Founder & President",
    "Director, Senior Corporate Trainer And Consultant",
    "Co-Founder & Chief Executive Officer",
    "Senior Vice President (Business)",
    "Director (Marketing)",
    "Vice President (Engineering)",
    "Chairperson",
    "Vice President And Co-Founder",
    "Chief Executive Officer And Managing Director",
    "Vice President (Global Partner Ecosystem)",
    "Chairman And Chief Executive Officer",
    "Member Of Board Of Directors",
    "Chief Executive Officer (Apmea)",
    "Co-Founder And Chairman",
    "Co-Founder And Deputy Managing Director",
    "Chief Technology Officer And Automotive Lead",
    "Chief Executive Officer (Food Delivery)",
    "Director (Growth And Strategy)",
    "Co-Founder And Chief Marketing Officer",
    "Vice President (Technical Research)",
    "Chief Sustainability Officer",
    "Chief Business Officer & Co-Founder",
    "Chief Executive Officer",
    "Executive Chairman And Co-Founder",
    "Co-Founder And Chief Marketing Officer",
    "Vice President (Growth And Strategy)",
    "Mce Manager (Digital Marketing)",
    "Principal Security Engineer",
    "Chief Scientific Officer",
    "Chairman And Chief Executive Officer",
    "President (Electronics)",
    "Chief Digital Officer",
    "Finance Controller",
    "Associate Director And Head",
    "Director Of Marketing",
    "Company Secretary",
    "Chief Executive Officer - Americas",
    "Hr Head",
    "Senior Vice President",
    "Vice President-Lending",
    "Chief Innovation Officer",
    "Chief Operating Officer And President",
    "Senior Vice President",
    "Co-Founder And Chief Marketing Officer",
    "Vice President Research",
    "Randd Manager",
    "Head Of Human Resources",
    "Former Chief Marketing Officer",
    "Former Chief Technology Officer",
    "Former Chief Marketing Officer",
    "Assistant Vice President",
    "Director Of Sales And Marketing",
    "Former Chief Executive Officer",
    "Head Of Growth",
    "Chief Information Officer",
    "Portfolio Manager",
    "Fund Manager",
    "Professor",
    "Chief Development Officer",
    "Senior Research Analyst",
    "Marketing Manager",
    "Chief Investment Strategist",
    "Board Member",
    "Executive Vice President",
    "Divisional Manager",
    "Leader",
    "Principal Economist",
    "President (R&D)",
    "Chief Data Scientist",
    "Chief Experience Officer",
    "Scientific Director",
    "Senior Vice President - Commodity Research",
    "Senior Manager",
    "Chairperson And Non-Executive Director",
    "Former Vice President",
    "Finance Controller",
    "Vice President (Marketplaces)",
    "Former Chief Financial Officer",
    "Senior Vice President",
    "Head Of Human Resources",
    "Vice President (Product And Partnerships)",
    "Growth Manager",
    "Vice President (Global Growth)",
    "Associate Director (Product)",
    "Cofounder And Chief People Officer",
    "Chief Product Officer",
    "Cofounder And Chief Technology Officer",
    "Former Chief Executive Officer (Apmea)",
    "Equity Derivatives Analyst",
    "Founder And Chief",
  ];
  const [defaultDesignations, setDefaultDesignation] = useState([
    "Chief Commercial Officer",
    "Executive Director",
    "Vice President",
  ]);
  // const designations = ["All", "Chief Executive Officer", "Chief Commercial Officer"];
  const { user } = useAuth();
  const [input, setInput] = useState("");
  const [filter, setFilter] = useState(designations[0]);
  const [loading, setLoading] = useState(false);
  const [suggestion, setSuggestion] = useState([]);
  useEffect(() => {
    const suffex = `${filter === designations[0] ? "" : `?input=${filter}`}`;
    const url = KEY_PEOPLE_TOP_25 + suffex;
    (async () => {
      try {
        setLoading(true);
        const res = await fetch(url);
        if (res.ok) {
          const data = await res.json();
          setSuggestion(data);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
      }
    })();
  }, [filter]);
  return user.isAuthenticated ? (
    <div className="w-full p-4 pt-0 h-full ">
      <div className="flex sm:flex-col-reverse lg:flex-row gap-4 justify-between ">
        <div className="flex gap-2 flex-wrap items-center">
          {defaultDesignations?.slice(0, 3)?.map((item, index) => (
            <p
              key={index}
              className={` h-max px-4 py-2 rounded-full cursor-pointer border-2 ${
                item === filter
                  ? " bg-primaryBlue text-white border-none"
                  : " border-white text-gray-700"
              }`}
              onClick={() => setFilter(item)}
            >
              {item}
            </p>
          ))}
        </div>
        <div>
          <div className=" relative">
            <div className="flex justify-between gap-2 items-center rounded-xl bg-white px-4 py-1 h-fit">
              <svg
                className=" cursor-pointer"
                width="23"
                height="21"
                viewBox="0 0 27 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.5214 18.7084L26.0029 24.1899L24.1928 26L18.7113 20.5184C16.7404 22.0953 14.2408 23.0386 11.5222 23.0386C5.16358 23.0386 0.00292969 17.878 0.00292969 11.5193C0.00292969 5.16065 5.16358 0 11.5222 0C17.8809 0 23.0415 5.16065 23.0415 11.5193C23.0415 14.2379 22.0982 16.7374 20.5214 18.7084ZM17.9535 17.7587C19.5186 16.1457 20.4817 13.9455 20.4817 11.5193C20.4817 6.56921 16.4723 2.55985 11.5222 2.55985C6.57213 2.55985 2.56278 6.56921 2.56278 11.5193C2.56278 16.4694 6.57213 20.4788 11.5222 20.4788C13.9485 20.4788 16.1486 19.5156 17.7616 17.9505L17.9535 17.7587Z"
                  fill="#71717A"
                />
              </svg>
              <input
                placeholder="search for designations..."
                className="p-2 rounded-md w-[22rem] appearance-none focus:outline-none "
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
            </div>

            {
              <div className=" absolute top-full right-0 w-full">
                {(() => {
                  const list = designations
                    ?.filter((item) =>
                      item
                        ?.toLocaleLowerCase()
                        .includes(input?.toLocaleLowerCase())
                    )
                    ?.slice(0, 10);
                  return list?.length > 0 && input.length > 0 ? (
                    <ul className=" p-4 bg-white mt-2 rounded-md">
                      {list?.map((item, key) => (
                        <li
                          className=" p-2 hover:bg-gray-100 rounded-md cursor-pointer my-1"
                          key={key}
                          onClick={(e) => {
                            setFilter(e.target.textContent);
                            setDefaultDesignation((pre) =>
                              pre.includes(e.target.textContent)
                                ? pre
                                : [e.target.textContent, ...pre]
                            );
                            setInput("");
                          }}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <></>
                  );
                })()}
              </div>
            }
          </div>
        </div>
      </div>
      {loading ? (
        <div className="w-full p-4 pt-0 h-full flex items-center justify-center">
          <Loder />
        </div>
      ) : (
        <div className="flex gap-6 flex-wrap mt-6">
          {suggestion.map((persion, index) => (
            <Persion_Card key={index} persion={persion} />
          ))}
        </div>
      )}
    </div>
  ) : (
    <div className="w-full p-4 pt-0 h-full  flex items-center justify-center">
      <LoginButton />
    </div>
  );
};

export default Key_People;
