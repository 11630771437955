import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import KnowledgeGraph from "../../charts/knowledgeGraph";
import Loader from "../../tearSheet/loader";
import { COHORT_BY_INVESTOR_NAME } from '../../../constant/api';
import useAddMetaTagsInvestor from '../../../hooks/MetaTagsAdd/useAddMetaTagsInvestor';


const InvestorCohort = ({ investorId }) => {
    const investorName = useSelector(store => store?.investor?.investorData?.[investorId]?.[0]?.Investor);
    const [cohortData1, setCohortData1] = useState({});
    const [cohortData2, setCohortData2] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (
            async () => {

                if (investorName) {

                    try {
                        setLoading(true);
                        const response = await axios.get(COHORT_BY_INVESTOR_NAME + investorName);
                        if (Array.isArray(response.data)) {
                            setCohortData1(response.data[0]);
                            setCohortData2(response.data[1]);

                            // dispatch(INVESTOR_COHORT_SUCCESS({ peopleCohort: response?.data[0], companyCohort: response?.data[1] }));
                            setLoading(false);
                        } else {
                            setLoading(false);
                        }
                    } catch (err) {
                        setLoading(false);
                        console.log(err);
                    }
                }
            }
        )();
    }, [investorName]);

  useAddMetaTagsInvestor(investorName, "Cohort, Community, People and Company Connections", `Discover companies and People related to ${investorName}. Make connections through common connects.`)  

    return (
        <div
            className={`section  bg-white sm:w-full rounded-b-xl flex lg:p-8 sm:p-2 gap-7 flex-wrap w-full min-h-full`}
            style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
        >
            {loading ? (
                <div className="flex w-full items-center justify-center h-[22rem]">
                    <Loader />
                </div>
            ) : (
                <div className="flex flex-col gap-2">
                    <p className="font-medium text-[25px] tracking-tight leading-9">
                        People Cohort
                    </p>
                    {cohortData2 &&
                        Object.keys(cohortData2).length > 0 &&
                        cohortData2 != {} && (
                            <div className="flex flex-col items-start w-full h-full">
                                <KnowledgeGraph data={cohortData2} type={"person"} />
                            </div>
                        )}
                    <p className="font-medium text-[25px] tracking-tight leading-9">
                        Company Cohort
                    </p>
                    {cohortData1 &&
                        Object.keys(cohortData1).length > 0 &&
                        cohortData1 != {} && (
                            <div className="flex flex-col items-start w-full h-full">
                                <KnowledgeGraph data={cohortData1} type={"company"} />
                            </div>
                        )}
                </div>
            )}
        </div>
    )
}

export default InvestorCohort;



























// const InvestorCohort = ({ investorId }) => {
//     const investorName = useSelector(store => store?.investor?.investorData?.[investorId]?.[0]?.Investor);
//     const companyCohart = useSelector(store => store?.investor?.investorCohortData?.companyCohort);
//     const PeopleCohart = useSelector(store => store?.investor?.investorCohortData?.peopleCohort);

//     // const [loading, error] = useFetchInvestorCohort(investorName);
//     // const cohort = useSelector(store => store?.investors?.[investorId]?.cohort);
//     const { getCohortData } = useFetchInvestorActions();

//     useEffect(() => {
//         getCohortData(investorName);
//     }, [investorName]);
//     return (
//         <div
//             className={`section bg-white sm:w-full rounded-b-xl flex lg:p-8 sm:p-2 gap-7 flex-wrap w-full min-h-full`}
//             style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
//         >
//             {/* {loading ? (
//                 <div className="flex w-full items-center justify-center h-[22rem]">
//                     <Loader />
//                 </div>
//             ) : ( */}
//             <div className="flex flex-col gap-2">
//                 <p className="font-medium text-[25px] tracking-tight leading-9">
//                     People Cohort
//                 </p>

//                 <p className="font-medium text-[25px] tracking-tight leading-9">
//                     Company Cohort
//                 </p>

//             </div>
//             {/* )} */}
//         </div>
//     )
// }

// export default InvestorCohort;



// {companyCohart &&
//     Object.keys(companyCohart).length > 0 &&
//     companyCohart != {} && (
//         <div className="flex flex-col items-start w-full h-full">
//             <KnowledgeGraph data={companyCohart} />
//         </div>
//     )}


//     {PeopleCohart &&
//         Object.keys(PeopleCohart).length > 0 &&
//         PeopleCohart != {} && (
//             <div className="flex flex-col items-start w-full h-full">
//                 <KnowledgeGraph data={PeopleCohart} />
//             </div>
//         )}