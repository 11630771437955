import React, { useContext, useState } from "react";
import closeLine from "../../assets/close-icon.svg";
import useFetchSearchSuggestions from "../../hooks/Search/useFetchSearchSuggestions";
import { goToCompanyPage, goToInvestorPage } from "../../constant/function";
import PatentCard from "../../pages/PatentCard";
import NewsRow from "../myFeed/NewsRow";
import aiChat from "../../assets/ai-chat.png";
import ChatWithGroot from "../chatWithGroot/ChatWithGroot"

const Search = ({ setIsFocousOn }) => {
  const tabs = ["Company", "Person", "Investor", "Sector", "Patents", "News"];
  const [input, setInput] = useState("");
  const [activeSearch, setActiveSearch] = useState("Company");



  const [
    error,
    loading,
    {
      companySuggestions,
      investorSuggestions,
      personSuggestions,
      sectorSuggestions,
      patentSuggestions,
      newsSuggestions
    }
  ] = useFetchSearchSuggestions(input);
  const data = {
    Company: companySuggestions,
    Person: personSuggestions,
    Investor: investorSuggestions,
    Sector: sectorSuggestions,
    Patents: patentSuggestions,
    News: newsSuggestions
  };

  return (
    <>
    <div
      className={`flex flex-col lg:flex-row gap-2 ${
        input.length > 2 ? "items-start" : "lg:items-center"
      }`}
    >
      <form
        className={`w-full bg-white rounded-xl`}
        required
        target="_blank"
        style={{
          boxShadow: "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)"
        }}
      >
        <div className="flex justify-between h-12 gap-3 px-4 items-center">
          <svg
            width="23"
            height="21"
            viewBox="0 0 27 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.5214 18.7084L26.0029 24.1899L24.1928 26L18.7113 20.5184C16.7404 22.0953 14.2408 23.0386 11.5222 23.0386C5.16358 23.0386 0.00292969 17.878 0.00292969 11.5193C0.00292969 5.16065 5.16358 0 11.5222 0C17.8809 0 23.0415 5.16065 23.0415 11.5193C23.0415 14.2379 22.0982 16.7374 20.5214 18.7084ZM17.9535 17.7587C19.5186 16.1457 20.4817 13.9455 20.4817 11.5193C20.4817 6.56921 16.4723 2.55985 11.5222 2.55985C6.57213 2.55985 2.56278 6.56921 2.56278 11.5193C2.56278 16.4694 6.57213 20.4788 11.5222 20.4788C13.9485 20.4788 16.1486 19.5156 17.7616 17.9505L17.9535 17.7587Z"
              fill="#71717A"
            />
          </svg>

          <input
            placeholder="search for a company, investor, person, sector and more..."
            className="h-12 w-full appearance-none focus:outline-none uppercase"
            required
            type="text"
            onChange={(e) => setInput(e.target.value)}
            onFocus={() => setIsFocousOn && setIsFocousOn(true)}
            onBlur={() => setIsFocousOn && setIsFocousOn(false)}
            value={input}
          ></input>

          {input.length > 2 && (
            <button onClick={() => setInput("")}>
              <img src={closeLine} />
            </button>
          )}
        </div>
        {input.length > 2 && (
          <div className="bg-white w-full gap-2 h-full rounded-b-xl relative z-0">
            <div className="flex overflow-x-auto w-full justify-evenly bg-[#FAFAFA]">
              {tabs.map((item, index) => (
                <div
                  key={index}
                  className={`flex w-full items-center flex-col hover:bg-white py-2 px-6 lg:px-0 cursor-pointer ${
                    activeSearch === item
                      ? "bg-white text-primaryBlue font-bold"
                      : "text-[#A1A1AA] font-normal"
                  }`}
                  onClick={() => setActiveSearch(item)}
                >
                  <div className=" relative">
                    <span>{item}</span>
                    {data[item]?.length > 0 && (
                      <span className=" text-[1.5rem] absolute -top-3 -right-3 text-primaryBlue">
                        •
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <ul
              className={`${
                activeSearch === "Company" ? "active" : "hidden"
              } h-96 overflow-y-auto uppercase`}
              style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
            >
              {companySuggestions && companySuggestions.length > 0 ? (
                companySuggestions.map((company, index) => (
                  <li
                    key={index}
                    className="cursor-pointer sm:text-xs lg:text-base px-4 py-2 hover:bg-slate-50 flex gap-1"
                    onClick={(e) => {
                      e.preventDefault();
                      goToCompanyPage(
                        company.ID,
                        company.NAME,
                        company.BRAND_ID,
                        company.ALIAS
                      );
                    }}
                  >
                    {company.ALIAS?.[0] !== "Null" ? (
                      <p className="text-primaryBlue">{company.ALIAS?.[0]} </p>
                    ) : (
                      ""
                    )}

                    {company.ALIAS?.[0] !== "Null" &&
                      company.NAME?.[0] !== "Null" && (
                        <p className="text-primaryBlue">-</p>
                      )}
                    {company.NAME?.[0] !== "Null" ? <p>{company.NAME}</p> : ""}
                  </li>
                ))
              ) : (
                <p className="sm:text-xs lg:text-base px-4 py-2 ">
                  No results found.
                </p>
              )}
            </ul>

            <ul
              className={`${
                activeSearch === "Person" ? "active" : "hidden"
              } h-96 overflow-y-auto uppercase`}
              style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
            >
              {personSuggestions && personSuggestions.length > 0 ? (
                personSuggestions.map((companyData, index) => (
                  <div key={index}>
                    {companyData.NAME !== "Null" ? (
                      <li
                        className="cursor-pointer sm:text-xs lg:text-base px-4 py-2 justify-between items-center hover:bg-slate-50 flex gap-1"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            `/people-intelligence/person/${companyData.ID}`
                          );
                        }}
                      >
                        <p>{companyData.NAME}</p>
                        <div className="text-right">
                          <p className="text-xs text-gray-600">
                            {companyData.DESIGNATION &&
                            companyData.DESIGNATION !== "NULL"
                              ? companyData.DESIGNATION
                              : ""}
                          </p>
                          <p className="text-xs text-gray-600">
                            {companyData.ALIAS?.[0] &&
                            companyData.ALIAS?.[0] !== "NONE"
                              ? companyData.ALIAS?.[0]
                              : ""}
                          </p>
                        </div>
                      </li>
                    ) : (
                      ""
                    )}{" "}
                  </div>
                ))
              ) : (
                <p className="sm:text-xs lg:text-base px-4 py-2 ">
                  No results found.
                </p>
              )}
            </ul>

            <ul
              className={`${
                activeSearch === "Investor" ? "active" : "hidden"
              } h-96 overflow-y-auto uppercase`}
              style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
            >
              {investorSuggestions && investorSuggestions.length > 0 ? (
                investorSuggestions.map((companyData, index) => (
                  <div key={index}>
                    {companyData.NAME !== "" ? (
                      <li
                        className="cursor-pointer sm:text-xs lg:text-base px-4 py-2 hover:bg-slate-50 flex gap-1"
                        onClick={(e) => {
                          e.preventDefault();
                          goToInvestorPage(companyData.NAME[0], companyData.ID);
                        }}
                      >
                        <p>{companyData.NAME}</p>
                      </li>
                    ) : (
                      "No results found."
                    )}{" "}
                  </div>
                ))
              ) : (
                <p className="sm:text-xs lg:text-base px-4 py-2 ">
                  No results found.
                </p>
              )}
            </ul>

            <ul
              className={`${
                activeSearch === "Sector" ? "active" : "hidden"
              } h-96 overflow-y-auto uppercase`}
              style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
            >
              {sectorSuggestions && sectorSuggestions.length > 0 ? (
                sectorSuggestions.map((companyData, index) => (
                  <div key={index}>
                    {companyData.NAME !== "Null" ? (
                      <li
                        className="cursor-pointer sm:text-xs lg:text-base px-4 py-2 hover:bg-slate-50 flex gap-1"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            `/sector-intelligence/sector/${companyData.ID}`
                          );
                        }}
                      >
                        <p>{companyData.NAME}</p>
                      </li>
                    ) : (
                      ""
                    )}{" "}
                  </div>
                ))
              ) : (
                <p className="sm:text-xs lg:text-base px-4 py-2 ">
                  No results found.
                </p>
              )}
            </ul>

            <ul
              className={`${
                activeSearch === "Patents" ? "active" : "hidden"
              } h-96 overflow-y-auto w-full`}
              style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
            >
              {patentSuggestions && patentSuggestions.length > 0 ? (
                patentSuggestions.map((patent, index) => (
                  <div
                    key={index}
                    className="sm:text-xs lg:text-sm px-2 py-1 flex gap-1 w-full border-b-[1px]"
                  >
                    {patent ? (
                      <PatentCard
                        search={{
                          ...patent,
                          Abstract: undefined
                        }}
                        input={input}
                      />
                    ) : (
                      "No patents found."
                    )}
                  </div>
                ))
              ) : (
                <p className="sm:text-xs lg:text-base px-4 py-2 ">
                  No results found.
                </p>
              )}
            </ul>

            <ul
              className={`${
                activeSearch === "News" ? "active" : "hidden"
              } h-96 overflow-y-auto w-full`}
              style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
            >
              {newsSuggestions && newsSuggestions.length > 0 ? (
                newsSuggestions.map((news, index) => (
                  <div
                    key={index}
                    className="sm:text-xs lg:text-sm px-2 py-1 flex gap-1 w-full border-b-[1px]"
                  >
                    {news ? (
                      <NewsRow news={news} />
                    ) : (
                      "No patents found."
                    )}
                  </div>
                ))
              ) : (
                <p className="sm:text-xs lg:text-base px-4 py-2 ">
                  No results found.
                </p>
              )}
            </ul>
          </div>
        )}
      </form>

      {/* <div
        className={`${
          input.length > 2 ? "pt-[6px]" : "pt-0"
        } flex gap-2 items-center`}
      >
        <img
          className={`w-9 `}
          src={aiChat}
          alt="searchIcon"
          onClick={() => (!chatwithGrootOpen)}
        />
        <p className="w-full block lg:hidden">Ask me anything with Ai</p>
      </div> */}


      {/* <div className={`overflow-hidden flex items-center justify-center w-screen h-screen absolute ${chatwithGrootOpen ? " bg-black" : "w-0 hidden"}`}> */}

      {/* </div> */}
    </div>

          </>
  );
};

export default Search;
