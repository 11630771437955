import React, { useState } from "react";
import FinancialBalanceSheet from "../../charts/financialChart";
import LineGraphForPLStatement from "../../charts/LineGraphForPLStatement";

const Table1 = ({ Data, chart }) => {
  const [activeIndex, setActiveIndex] = useState(0); 
  const [ValueArray, setValueArray] = useState(() => {
    if (chart === "bar" && Data ) {
      const formattedData = Object.keys(Data).map((key, index) => {
        const fiscalYear = key
          .replace("_", " ")
          .replace("FY", "FY ")
          .replace(/_/g, "-"); 
        const value = Object.values(Data)[index][
          Object.keys(Object.values(Data)[index])[0]
        ]
          ? parseInt(
              Object.values(Data)[index][
                Object.keys(Object.values(Data)[index])[0]
              ]
            )
          : 0;
        return { name: fiscalYear, Value: value };
      });

      return formattedData;
    }
    return [];
  });

  const [lineGraphArray, setLineGraphArray] = useState(() => {
    if (chart === "line" && Data) {
      const val1Array = [];
      const val2Array = [];
      const val3Array = [];
  
      Object.keys(Data).forEach((key, index) => {
        const val1 = Object.values(Data)[index]["Profit before prior period items, exceptional items, extraordinary items and tax"];
        const val2 = Object.values(Data)[index]["Total Revenue"];
        const val3 = Object.values(Data)[index]["Profit before tax"];
  
        val1Array.push(val1);
        val2Array.push(val2);
        val3Array.push(val3);
      });
  
      return [val1Array, val2Array, val3Array];
    }
  });


  if (!Data) return null;

  const dataValues = Object.values(Data);

  const rowKeys = Object.keys(dataValues?.[0]);

  const headers = Object.keys(Data)?.map((key) =>
    key?.replace(/FY_/g, "FY 20").replace(/_/g, "-")
  );

  const formatDataForGraph = (key) => {
    return headers.map((header, idx) => {
      const value = Data[Object.keys(Data)[idx]][key];

      const intValue =
        value !== null && value !== undefined && value !== "-"
          ? parseInt(value, 10)
          : 0;

      return {
        name: header,
        Value: intValue,
      };
    });
  };

  return (
    <div className="flex lg:flex-row flex-col gap-4 lg:gap-0 w-full overflow-x-auto">
      {Data && (
        <table className="border w-2/3">
          <thead>
            <tr className="border-b">
              <th className="p-4 text-xs text-[#A1A1AA]  text-start">
                (Rs. Cr)
              </th>
              {headers.map((header, i) => (
                <th
                  className="p-4 text-xs text-[#A1A1AA] text-start"
                  key={header}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rowKeys.map((key, i) => (
              <tr
                className={`border-b ${activeIndex === i && chart === "bar" ? "bg-gray-100" : ""}`}
                key={i}
                onMouseEnter={() => {
                  if (chart === "bar") {
                    setActiveIndex(i);
                    const graphData = formatDataForGraph(key);
                    setValueArray(graphData);
                  }
                }}
              >
                <td className="p-4 text-sm">{key}</td>
                {dataValues.map((item, j) => (
                  <td className="p-4 text-sm" key={j}>
                    {item[key] ?? "-"}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <div className="lg:w-1/3 ">
        {chart === "bar" ? (
          <FinancialBalanceSheet xAxis="Financial Year" data={ValueArray} />
        ) : chart === "line" ? (
          <LineGraphForPLStatement data = {lineGraphArray} year={headers[0]} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Table1;
