import React from 'react'
import axios from "axios";
import { SECTOR_SEARCH_LOADING, SECTOR_DATA_SUCCESS, SECTOR_SEARCH_FAIL } from '../store/slices/sectorReducer';
import { useDispatch } from 'react-redux';
import { GET_SECTOR } from '../constant/api';

const useFetchSectorAction = () => {
    const dispatch = useDispatch();

    const getSector = async (sector) => {
        const options4 = {
            url: GET_SECTOR + sector,
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/txt;charset=UTF-8'
            },
            data: {}
        };
        try {

            dispatch(SECTOR_SEARCH_LOADING());
            const sectorResponse = await axios(options4)
            dispatch(SECTOR_DATA_SUCCESS({ data: sectorResponse.data, sectorName: sector }));
        }
        catch (e) {
            dispatch(SECTOR_SEARCH_FAIL());
        }
    }
    return getSector;
}

export default useFetchSectorAction;