import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const CarouselSlider = ({ data }) => {
  const predefinedColors = [
    "#E8F5FF",
    "#F5FFF8",
    "#FFFFE6",
    "#FFECEC",
    "#F4E8FF",
  ];
  let previousColorIndex = -1;

  // Function to get a random color from the predefined colors array
  const getRandomColor = () => {
    let randomIndex;
    do {
      randomIndex = Math.floor(Math.random() * predefinedColors.length);
    } while (randomIndex === previousColorIndex); // Keep generating until it's different from the previous color
    previousColorIndex = randomIndex; // Update previous color index
    return predefinedColors[randomIndex];
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    //   slidesToSlide: 2, // optional, default to 1.
      partialVisibilityGutter: 40
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    //   slidesToSlide: 2, // optional, default to 1.
      partialVisibilityGutter: 30
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    //   slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 30
    },
  };
  return (
    <Carousel
      keyBoardControl={true}
      removeArrowOnDeviceType={["tablet", "mobile"]}
    //   showDots={true}
      responsive={responsive}
      swipeable={true}
      draggable={true}
      itemClass="carousel-item-padding-40-px"
      partialVisible={true}
    >
      {data &&
        data.Patent_SOS && data.Patent_SOS.length > 0 &&
        data.Patent_SOS.map((sos) => (
          <div className="itemClass p-3 gap-3 w-full h-full rounded-xl">
            <p
              className="px-5 py-4 text-gray-700 font-semibold h-full rounded-xl shadow-xl"
              style={{
                backgroundColor: getRandomColor(),
                boxShadow:
                  "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
              }}
            >
              {sos}
            </p>
          </div>
        ))}
    </Carousel>
  );
};

export default CarouselSlider;

