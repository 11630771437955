import React from "react";
import { useEffect } from "react";

const useAddMetaTags = (props) => {
  useEffect(() => {
    // title
    document.getElementsByTagName("title")[0].innerText = `${props?.props?.name} - ${props.title}`;

    // meta
    document
      .querySelector('meta[name="title"]')
      .setAttribute("content", `${props?.props?.name} - ${props.title} - Factacyinsights`);
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", props?.props?.keywords);
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", `${props.summary} - Startup Data Provided by AI driven Financial Intelligence Platform for Unlisted Companies, Factacy Insights`);

    // og: meta
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", `${props?.props?.name} - ${props.title} - Factacyinsights`);
    document.querySelector('meta[property="og:description"]').setAttribute(
      "content",
      `${props.summary} - Startup Data Provided by AI driven Financial Intelligence Platform for Unlisted Companies, Factacy Insights`
    );
    document
      .querySelector('meta[property="og:url"]')
      .setAttribute("content", `${window.location.href}`);

    // canonical
    const linkTag = document.createElement("link");
    linkTag.setAttribute("rel", "canonical");
    linkTag.href = window.location.href;
    document.head.appendChild(linkTag);

    return () => {
      linkTag.remove();
    };
  }, [window.location.search, props]);
};

export default useAddMetaTags;

//     document.getElementsByTagName("title")[0].innerText = `${
//       dataToMap?.Brand ?? dataToMap.Company_Name
//     } - Competitors, Revenue, Number of Employees, Funding, Acquisitions & News - Factacyinsights`;

//     document
//       .querySelector('meta[name="description"]')
//       .setAttribute(
//         "content",
//         `${dataToMap?.Brand}’s Profile - Financials, Valuation, and Growth. ${dataToMap?.Summary}`
//       );
//     document
//       .querySelector('meta[name="keywords"]')
//       .setAttribute("content", dataToMap?.Keywords);
//     document
//       .querySelector('meta[name="title"]')
//       .setAttribute(
//         "content",
//         `${
//           dataToMap?.Brand ?? dataToMap.Company_Name
//         } - Competitors, Revenue, Number of Employees, Funding, Acquisitions & News - Factacyinsights`
//       );

//     document
//       .querySelector('meta[property="og:title"]')
//       .setAttribute(
//         "content",
//         `${
//           dataToMap?.Brand ?? dataToMap.Company_Name
//         } - Competitors, Revenue, Number of Employees, Funding, Acquisitions & News - Factacyinsights`
//       );

//     document
//       .querySelector('meta[property="og:description"]')
//       .setAttribute(
//         "content",
//         `${dataToMap?.Brand}’s Profile - Financials, Valuation, and Growth. ${dataToMap?.Summary}`
//       );

//       document
//       .querySelector('meta[property="og:url"]')
//       .setAttribute(
//         "content",
//         `${window.location.href}`
//       );

// ${props?.props?.name}’s Profile - Financials, Valuation, and Growth.
