import React from 'react';
import InvestorSectorChart from "../../charts/investorSectorChart";
import { useSelector } from 'react-redux';
import useAddMetaTagsInvestor from '../../../hooks/MetaTagsAdd/useAddMetaTagsInvestor';

const InvestorSectors = ({investorId, investor}) => {
    const valueArray = useSelector(store => store?.investor?.investorSectorsChartData?.[investorId]);
  useAddMetaTagsInvestor(investor, "Sectors of Investments", `Discover ${investor}'s investment activity across ${valueArray?.map(val => val.sector)}`)
  

    return (
        <div
            className={`section key_people bg-white sm:w-full rounded-b-xl gap-4 flex flex-col sm:p-4 lg:p-8`}
            style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
        >
            <p className="font-medium text-[25px] tracking-tight leading-9">
                Sector wise deals
            </p>
            <div className="lg:w-1/2 sm:w-full pt-8 px-4 bg-[#F4F5F7]">
                <InvestorSectorChart data={valueArray} />
            </div> 
        </div>
    )
}

export default InvestorSectors;