export const LOGIN_API = "https://factacyinsights.in/inv_login"; //login
export const REGISTER_API = "https://factoq.com/backend/cdb/inv_regist"; //register
// export const REGISTER_API = "https://factoq.com/backend/user/inv_regist"; //register
export const SEARCH_API_WITH_REFRENCE_ID ="https://factoq.com/backend/solr/grootsearch?input=";
export const SEARCH_API_WITH_REFRENCE_ID_BRAND_ID = "https://factoq.com/backend/solr/brand_search?name="
export const PATENT_SEARCH = "https://factacyinsights.in/searchpatent?input="; // solr
export const PATENT_SEARCH_BY_SECTOR ="https://factoq.com/backend/solr/factacy_patent?name="; // solr
export const LEADING_SECTORS = "https://factoq.com/backend/cdb/leading_sectors";
export const FUNDING_NEWS = "https://factacyinsights.in/funding"; 
export const DEALS_BY_ART_IDS ="https://factacyinsights.in/investor_alt?input=";
export const PATENT_SEARCH_IDS ="https://factacyinsights.in/patentsearch?input=";
export const KEY_PEOPLE_TOP_25 = "https://factacyinsights.in/leading_keypeople";
export const COMPANY_SEARCH_BY_CIN = "https://factacyinsights.in/?input=";
export const PATENT_SEARCH_BY_CIN = "https://factacyinsights.in/patents?input="; 
export const SONICORNS = "https://factacyinsights.in/allinone?input1=cdb-L1&input2=Soonicorn";
export const DEAL90 = "https://factacyinsights.in/deal90"; // deals of 90 days
export const GETARTS = "https://factacyinsights.in/getarts?input="; // sends articles for ids sent comma separated
export const GROOT_FINANCIALS = "https://factacyinsights.in/groot-financial"; //sends financials data for tearsheet
export const GROOT_DEAL_ID = "https://factacyinsights.in/groot_dealID?input="; //takes reference id input of either mca or brand and finds in deal-id container to respond with deals of that particular company or brand
export const GET_INVESTORS = "https://factacyinsights.in/investor?input="; // takes multiple comma separated investor ids and fetch investor data from investor-id container
export const GET_ALL_DEALS = "https://factacyinsights.in/investor_alt?input="; //takes multiple deal ids comma separsted and responds with deal data fetched from deal-id container
export const GROOT_BRAND_DATA = "https://factacyinsights.in/groot_brand_data?input="; //takes multiple brand ids and responds with brand data fetched from brands container in groot-db. this is for competitors page
export const INDUSTRIAL_PORTFOLIO_NEWS = "https://factacyinsights.in/industrial_portfolio?input="; //takes sector name and returns news
export const HOME_NEWS = "https://factacyinsights.in/news"; 
export const ESG_NEWS = "https://factacyinsights.in/news_intell"
export const GETIDS = "https://factacyinsights.in/getids?input="
export const SEED_INFO_DETAIL = `https://factacyinsights.in/seed_info_detail?input="`;
export const GET_SECTOR = "https://factacyinsights.in/getone?input1=cdb-L1&input2=All-Sectors&input3=id&input4="
export const GET_PERSON = "https://factacyinsights.in/getone?input1=Groot-db&input2=key-people&input3=id&input4="
export const GET_INVESTOR = "https://factacyinsights.in/getone?input1=heimdall-db&input2=Investor-Id&input3=id&input4="
export const AI_PROFILE = "https://factacyinsights.in/ai-profile"

export const FORGET_PASSWORD_REQUEST = "https://factacyinsights.in/forgotpassword";
export const RESET_PASSWORD = "https://factacyinsights.in/updatepassword";
export const DEAL30 = "https://factacyinsights.in/deal30";
export const DEAL60 = "https://factacyinsights.in/deal60";
export const DEAL_30_60_90 = "https://factacyinsights.in/deal";
export const HISTORIAL_DEALS = "https://factacyinsights.in/filter";
export const SINGLE_DEAL_BY_ID = "https://factacyinsights.in/getone?input1=heimdall-db&input2=Deal-Id&input3=id&input4=";
export const GET_MAIN_SECTORS = "https://factacyinsights.in/main_sector";
export const LEADING_INVESTOR = "https://factoq.com/backend/heimdall/leading_investor";
export const SHARK_TANK_COMPANIES = "https://factacyinsights.in/allinone?input1=cdb-L1&input2=Shark-Tank";
export const UNICORNS_COMPANIES = "https://factacyinsights.in/allinone?input1=cdb-L1&input2=Unicorn";
export const RECENT_NEWS = "https://factacyinsights.in/api/recentnews";
export const SOLRCORE_SECTOR_NEWS = "https://factacyinsights.in/solrcore2/lax?val=";
export const COMPETITOR_INFO = "https://factacyinsights.in/getone?input1=heimdall-db&input2=heimdall-v2&input3=AKA_Brand_Name&input4=";
export const AI_PROFILE_STATUS = "https://factacyinsights.in/mca_cin_info?input=";
export const ALT_COMPANY_DEALS = "https://factoq.com/backend/heimdall/company_deals?input=";
export const COHORT_BY_INVESTOR_NAME = "https://factoq.com/cohort/investor/";
export const INVESTOR_SUMMARY_SEARCH = "https://factoq.com/backend/groot/investor_summary_search?id=";
export const DEALS_OFFSET = "https://factoq.com/backend/heimdall/deal_offset";
export const SINGLE_REPORT = "https://factoq.com/backend/groot/summary_search";
export const REPORT_OFFSET = "https://factoq.com/backend/groot/daily_summary";
export const LOGIN_API_OLD = "https://factoq.com/backend/cdb/inv_login";
export const TRACK_USER_API = "https://factoq.com/backend/user/track"
export const GET_CREDIT_HISTORY = "https://factoq.com/backend/user/credit_history"
export const GET_EOD_REPORTS = "https://factoq.com/backend/financial/eod_report?input="

export const GET_COMPETITORS_FROM_BRAND_ID = "https://factoq.com/backend/groot/getcompetitors?input="

    // oldApis
    // export const LOGIN_API = "https://factacyinsights.in/ilogin";
    // export const REGISTER_API = "https://factacyinsights.in/iregister";

    // const respones = https://factacyinsights.in/getone?input1=heimdall-db&input2=heimdall-v2&input3=AKA_Brand_Name&input4=${comp}
    // const respones = https://factacyinsights.in/getone?input1=heimdall-db&input2=Deal-Id&input3=id&input4=${dealId}
    // const respones = https://factacyinsights.in/getone?input1=heimdall-db&input2=heimdall-v2&input3=AKA_Brand_Name&input4=${comp}
    // const respones = https://factacyinsights.in/getone?input1=cdb-L1&input2=Business-News&input3=Output_CIN&input4=${input}
    // const respones = https://factacyinsights.in/getone?input1=heimdall-db&input2=heimdall-v2&input3=AKA_Brand_Name&input4=${comp}
    // const respones = https://factacyinsights.in/getone?input1=heimdall-db&input2=Deal-Id&input3=id&input4=${id.id}`);
    // const respones = https://factacyinsights.in/getone?input1=heimdall-db&input2=Deal-Id&input3=Output_CIN&input4=${input}`);
    // const respones = https://factacyinsights.in/getone?input1=cdb-L1&input2=Business-News&input3=Output_CIN&input4=${cin}

    // const respones = https://factacyinsights.in/?input=${cin}`);

    // const respones = https://factacyinsights.in/filter?investee=${investee}&edate=${edate}&sdate=${sdate}&sector=${sector}`);

    // const respones = https://factacyinsights.in/investor_alt?input=${formattedIds}`);

    // const respones = https://factacyinsights.in/main_sector");

    // const respones = https://factacyinsights.in/investor?input=${formattedInv}` );

    // const respones = https://factacyinsights.in/seed_information");

    // const respones = https://factacyinsights.in/allinone?input1=cdb-L1&input2=Shark-Tank`);
    // const respones = https://factacyinsights.in/allinone?input1=cdb-L1&input2=Unicorn`)

    // const respones = https://factacyinsights.in?input=${cin}`);

    // const respones = https://factacyinsights.in/getarts?input=${id.id}`)
    // const respones = https://factacyinsights.in/getarts?input=${formattedIds}`);
    // const respones = https://factacyinsights.in/getarts
    // const respones = https://factacyinsights.in/getarts

    // const respones = https://factacyinsights.in/search

    // const respones = https://factacyinsights.in/deal30
    // const respones = https://factacyinsights.in/deal60
    // const respones = https://factacyinsights.in/deal90
    // const respones = https://factacyinsights.in/getids

    // const respones = https://factacyinsights.in/industrial_portfolio?input='${sector}'`);

    // const respones =     ${formattedIds}`);

    // const respones = https://factacyinsights.in/ai-profile;

    // const respones = https://factacyinsights.in/mca_cin_info?input=${cin}

    // const respones = https://factoq.com/investor/${investor}`);

    // const respones = https://factacyinsights.in/solrcore2/lax?&val=${sector}

    // const respones = https://factacyinsights.in/searchpatent?input=${inputSearch}

    // const News = https://factacyinsights.in/news;
    // const ESG_NEWS = https://factacyinsights.in/news_intell;
// ]
