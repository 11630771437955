import React, { useEffect, useState } from "react";
import arrow from "../../assets/sidebar assets/arrow.svg";
import factacy from "../../assets/Factacy-grayLogomark (1).png";
import { useAuth } from "../auth/authContext";
import LoginButton from "../auth/loginButton";
import {
  goToCompanyPage,
  handleSectorClick,
  sector_classification_formatter,
} from "../../constant/function";
import { SONICORNS } from "../../constant/api";
import Loader from "../tearSheet/loader";

const Item = ({ data, loading }) => {
  const [isOpen, setIsOpen] = useState(false);
  const sector = sector_classification_formatter(data?.SECTOR_ID);

  return (
    <div className=" lg:flex w-full px-1 py-2 pl-2 lg:py-3 lg:px-4 border-b-[1px] hover:bg-gray-100 bg-white">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="flex-1 flex justify-between">
            <div className="flex gap-2 w-full">
              <img
                src={data?.LOGO_URL ?? factacy}
                className="w-12 h-6 object-contain"
                alt="company logo"
              />
              <p className="w-full hover:text-primaryBlue hover:underline cursor-pointer"
                onClick={() => data?.Reference_Id && data?.MCA_COMPANY_NAME && goToCompanyPage(data?.Reference_Id, [data?.MCA_COMPANY_NAME])}
              >
                {data?.MCA_COMPANY_NAME}
              </p>
            </div>
            <p className="w-full sm:text-xs lg:text-base">{data?.CITY_STATE}</p>
            <img
              src={arrow}
              className={`h-6 lg:hidden cursor-pointer ${isOpen ? "rotate-180" : ""
                }`}
              onClick={() => setIsOpen((pre) => !pre)}
              alt={"dropdownIcon"}
            />
          </div>

          <div className="flex-1 justify-between sm:hidden lg:flex">
            <p className={`w-full`}>{data?.FOUNDING_YEAR}</p>
            {sector.slice(0, 1)?.map((item) => (
              <p
                className="w-full hover:text-primaryBlue hover:underline cursor-pointer "
                onClick={() => item[0] && handleSectorClick(item[0])}
              >
                {item[1] ? item[1] : ""}
              </p>
            ))}
          </div>

          {isOpen && (
            <div className="mt-4 sm:flex flex-col lg:hidden flex-1 justify-between">
              <p className="">
                <span className="font-medium pr-2">
                  Entery in Unicron club:
                </span>
                {data?.UNICORN_ENTRY_YEAR}
              </p>
              {sector.slice(0, 1)?.map((item) => (
                <p
                  className="w-full hover:text-primaryBlue hover:underline cursor-pointer "
                  onClick={() => item[0] && handleSectorClick(item[0])}
                >
                  <span className="font-medium pr-2">Sector: </span> {item[1]}
                </p>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

const Soonicorn = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await fetch(SONICORNS);
        if (res.ok) {
          const data = await res.json();
          setData(data);
        }
      } catch (err) { }
      finally {
        setLoading(false);
      }
    })();
  }, []);

  // style={{
  //   boxShadow: "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
  // }}

  return (

    <div className="lg:pt-0 px-4 sm:pt-20 sm:w-screen lg:w-full flex flex-col">
      <div
        className="flex flex-col w-full bg-[#F4F5F7] rounded-xl lg:px-5 sm:px-4 lg:py-3 sm:py-3 justify-between gap-5"
        style={{
          boxShadow: "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
        }}
      >
        <p className="font-medium text-[25px] tracking-tight leading-9">
          Soonicorns
        </p>

        {user.isAuthenticated === true ?
          loading ? (<div className=" w-full h-12 flex items-center justify-center"><Loader /></div>) :

            (
              <div className="border-2 rounded-xl">
                <div className="flex w-full justify-evenly py-3 px-4 bg-slate-200 rounded-t-xl font-semibold">
                  <p className="w-full">Name</p>
                  <p className="w-full">Location</p>
                  <p className="w-full hidden lg:block">Year</p>
                  <p className="w-full hidden lg:block">Industry Classification</p>
                </div>

                {data.map((unicorn, index) => (
                  <Item key={index} data={unicorn} loading={loading} />
                ))}
              </div>
            ) : (
            <div className="flex items-center justify-center flex-col gap-3 h-screen">
              Please login first <LoginButton className="w-5" />
            </div>
          )}
      </div>
    </div>
  );
};

export default Soonicorn;
