import React, {useState} from 'react';
import DealModal from "../../modals/dealModal";
import IndividualDealCard from "../../../pages/IndividualDealCard";
import FinancialBalanceSheet from "../../charts/financialChart";
import { useSelector } from 'react-redux';
import { getCurrentMonth } from "../../../constant/function";

const Funding_Information = ({ referenceId }) => {

  const deals = useSelector((state) => state.company).dealsData[referenceId]
  const sortedData = deals?.filter((deal) => deal.Status === "Confirmed" || deal.Status === "Updated")?.sort((a, b) => a.Deal_Date - b.Deal_Date);

  const dealDataForChart = sortedData?.map((deal) => {
    const valueString = deal?.Corrected_Amount?.replace("$", "").trim();

    const value = valueString?.includes("K")
      ? parseFloat(valueString?.replace("K", "")?.replace(" ", "")) / 1000
      : parseFloat( valueString?.replace("M", "")?.replace("K", "")?.replace(" ", ""));

    return (
      {
        name:
          getCurrentMonth(deal?.Deal_Date)?.month +
          " " +
          getCurrentMonth(deal.Deal_Date)?.year,
        Value: value
      }
    )
  });


  const [selectedDeal, setSelectedDeal] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const openModal = (deal) => {
    setSelectedDeal([deal]);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <>
    {deals && deals.length > 0 && (

    <div className="flex flex-col gap-8 bg-white sm:w-full rounded-b-xl lg:p-8 p-2 ">
        <div className="flex flex-col gap-4">
          <p className="font-medium text-[25px] tracking-tight leading-9">
            Funding Information
          </p>
          <div className="flex flex-col w-full lg:flex-row gap-4">
            {dealDataForChart && (
              <div
                className={`${deals.length > 12 ? "lg:w-1/2" : "lg:w-1/3"
                  } w-full rounded-lg p-[1px]`}
                style={{
                  boxShadow:
                    "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
                }}
              >
                <FinancialBalanceSheet data={dealDataForChart} xAxis="Date" />
              </div>
            )}
            <div
              className={`flex gap-5 overflow-x-auto  p-1 ${deals.length > 12 ? "lg:w-1/2 w-full" : "lg:w-2/3 w-full"
                } transition-all duration-300 delay-150 ease-linear`}
            >
              {sortedData &&
                sortedData
                  .filter(
                    (deal) =>
                      deal.Status === "Confirmed" || deal.Status === "Updated"
                  )
                  .map((deal, index) => (
                    <IndividualDealCard
                      key={index}
                      deal={deal}
                      index={index}
                    />
                  ))}
            </div>
          </div>
        </div>
      <DealModal isOpen={isOpen} onClose={closeModal} deal={selectedDeal} />
    </div>
  )}
  </>

  )
}

export default Funding_Information;