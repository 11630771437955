import React from "react";
import { useState } from "react";
import axios from "axios";
import close from "../../assets/close-line - Copy.svg";
import factacy from "../../assets/factacy-logo-1.png";
import { useAuth } from "../auth/authContext";
import LoginButton from "../auth/loginButton";
import useDebounce from "../../hooks/useDebounce";
import PatentCard from "../../pages/PatentCard";

const PatentSearch = ({ api, heading, placeholder }) => {
  const [inputSearch, setInputSearch] = useState("");
  const [searchSuggestionsData, setSearchSuggestionsData] = useState([]);
  const { user } = useAuth();


  const handleChangeSearch = (inputSearch) => {
    setInputSearch(inputSearch);
    fetchSearchSuggestions(inputSearch);
  };

  const fetchSearchSuggestions = useDebounce(async (inputSearch) => {
    try {
      const response = await axios(
        `${api}${inputSearch}`
      );
      setTimeout(() => {
        const res = response.data;
        setSearchSuggestionsData(res);
      }, 200);
    } catch {
      setSearchSuggestionsData([]);
    }
  }, 300);

  // const fetchSearchSuggestions = ;



  const closeSearch = () => {
    setInputSearch("");
  };
  return (
    <div className="lg:pt-2 sm:pt-10 sm:w-full lg:w-full flex flex-col min-h-0">
      <div className="flex flex-col w-full lg:px-5 sm:px-4 lg:py-3 sm:py-3 gap-5 min-h-0">

        <p className="font-medium text-[25px] tracking-tight leading-9">
          {heading}
        </p>

        <div
          className="w-full bg-white rounded-xl flex justify-center h-14 px-4 gap-3 items-center"
          style={{
            boxShadow:
              "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)",
          }}
        >
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.4197 14.3912L20.6361 18.6076L19.2438 20L15.0274 15.7836C13.5114 16.9965 11.5887 17.7221 9.49757 17.7221C4.60648 17.7221 0.636902 13.7525 0.636902 8.8614C0.636902 3.97031 4.60648 0.000732422 9.49757 0.000732422C14.3887 0.000732422 18.3582 3.97031 18.3582 8.8614C18.3582 10.9525 17.6327 12.8752 16.4197 14.3912ZM14.4445 13.6607C15.6484 12.42 16.3892 10.7277 16.3892 8.8614C16.3892 5.05378 13.3052 1.96977 9.49757 1.96977C5.68995 1.96977 2.60594 5.05378 2.60594 8.8614C2.60594 12.669 5.68995 15.753 9.49757 15.753C11.3638 15.753 13.0562 15.0122 14.2969 13.8083L14.4445 13.6607Z"
              fill="#71717A"
            />
          </svg>
          <form className="w-full h-full">
            <input
              className="w-full lg:text-base sm:text-sm h-full appearance-none focus:outline-none "
              required
              type="text"
              onChange={(e) => handleChangeSearch(e.target.value)}
              value={inputSearch}
              placeholder={placeholder}
            ></input>
          </form>
          {inputSearch.length > 2 ? (
            <img
              src={close}
              className="w-7 h-7 cursor-pointer"
              onClick={closeSearch}
            />
          ) : (
            ""
          )}
        </div>

        <div>
          {handleChangeSearch && inputSearch.length > 2 && (
            <div
              className="w-full  gap-2"
            // style={{boxShadow:"0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)"}}
            >
              <div className="overflow-y-auto h-full gap-4 p-1">
                {searchSuggestionsData?.length > 0 && <p className="font-semibold text-lg font-roboto p-2">Search Results</p>}
                {user.isAuthenticated == true ? (
                  <div className="flex flex-col gap-8">
                    {searchSuggestionsData && searchSuggestionsData?.map((search, index) => (
                      <div key={index} className="rounded-lg bg-white"
                        style={{
                          boxShadow:
                            "0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16)",
                        }}
                        >
                     <PatentCard search={search}  input={inputSearch} />
                     </div>
                    ))}
                  </div>
                )
                  :
                  (
                    <div className="flex items-center justify-center flex-col gap-3 h-screen">
                      Please login first <LoginButton className="w-5" />
                    </div>
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PatentSearch;
