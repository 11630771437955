import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import DealCard from "../../pages/dealCard";
import Loader from "../tearSheet/loader";
import InvestorCard from "../../pages/InvestorCard";

const SimilarInvestors = ({
  invDetails,
  sortedSimilarInvestorData,
  isLoading,
  dealIdsRecord
}) => {
  return (
    <div className="flex flex-col gap-8">
      {isLoading ? (
        <div className="h-screen flex items-center w-full justify-center">
          {" "}
          <Loader />{" "}
        </div>
      ) : (
        <>
          {invDetails &&
            sortedSimilarInvestorData &&
            invDetails.map((data, index) => (
              <InvestorCard
                dealIds = {dealIdsRecord[index]}
                data={data}
                sortedData={sortedSimilarInvestorData}
                index={index}
              />
            ))}
        </>
      )}
    </div>
  );
};

export default SimilarInvestors;
