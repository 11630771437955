import React from "react";
import { useEffect, useState, useCallback } from "react";
import axios from "axios";
// import { getSector } from "../../redux/ActionCreators/sectorActions";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { connect } from "react-redux";
import Loader from "../tearSheet/loader";
import info from "../../assets/information.svg";
import source from "../../assets/arrow-down-double-line.png";
import calender from "../../assets/calender.svg";
import DealCard from "../../pages/dealCard";
import CTA from "../../utils/CTA";
import CarouselSlider from "../../pages/carouselSlider";
import Patents from "../tearSheet/patents";
import useFetchSectorAction from "../../hooks/useFetchSectorAction";
import IndividualNews from "../IndividualNews";
import { GET_ALL_DEALS, INDUSTRIAL_PORTFOLIO_NEWS, PATENT_SEARCH_IDS, SOLRCORE_SECTOR_NEWS } from "../../constant/api";

const SectorPage = ({sectorId}) => {
  const { sector } = useParams();
  const getSector = useFetchSectorAction();
  const dispatch = useDispatch();
  const sectorState = useSelector((state) => state.sector);
  const [activeSection, setActiveSection] = useState("patents");
  const sectorData = sectorState.sectorData[sectorId ?? sector];
  const [deals, setDeals] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [activeStatement, setActiveStatement] = useState("investorProfile");
  const [allArticlesData, setAllArticlesData] = useState([]);
  const [isNewsLoading, setNewsLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);

  // GET SECTOR FUNCTION
  useEffect(() => {
    getSector(sectorId ?? sector)
  }, [sector, sectorId]);

  const fetchSectorDeals = async () => {
    const dealIds = sectorData && sectorData[0].Deal_Id?.slice(-50);
    const formattedIds = dealIds && dealIds.join(",");
    try {
      setLoading(true);
      const responses = await axios.get(GET_ALL_DEALS + formattedIds);
      const sortedData = responses.data?.slice().sort((a, b) => {
        const lastDateA =
          a.Unique_date_time && a.Unique_date_time.length > 0
            ? a.Unique_date_time[a.Unique_date_time.length - 1]
            : null;
        const lastDateB =
          b.Unique_date_time && b.Unique_date_time.length > 0
            ? b.Unique_date_time[b.Unique_date_time.length - 1]
            : null;

        if (lastDateA !== null && lastDateB !== null) {
          return lastDateB - lastDateA; // Sort in descending order based on epoch time
        } else if (lastDateA === null && lastDateB !== null) {
          return 1; // Put empty or non-existent dates at the end
        } else if (lastDateA !== null && lastDateB === null) {
          return -1; // Put empty or non-existent dates at the end
        }
        return 0;
      });
      setLoading(false);
      setSortedData(sortedData);
    } catch { }
  };

  const handleSectionClick = useCallback(
    (section) => {
      setActiveSection(section);
    },
    [activeSection]
  );

  const fetchSectorNews = async () => {
    try {
      setNewsLoading(true);
      const sector = sectorData.map((sector) => sector.Sectors);
      if (sectorData.map((marker) => marker.Sec1 !== null && (marker.Sec1).filter(Boolean))) {
        const response = await axios.get( SOLRCORE_SECTOR_NEWS + sector);
        const sortedData = response.data.sort((a, b) => {
          return b.Unique_date_time - a.Unique_date_time;
        });
        setAllArticlesData(sortedData);
      } else {
        const response = await axios.get(INDUSTRIAL_PORTFOLIO_NEWS + sector);
       
        const sortedData = response.data.sort((a, b) => {
          return b.Unique_date_time - a.Unique_date_time;
        });
        setAllArticlesData(sortedData);
      }
      setNewsLoading(false);
    } catch {
      setNewsLoading(false);
      setAllArticlesData([]);
    }
  };

  const [patentsData, setPatents] = useState([]);

  useEffect(() => {
    const fetchPatents = async () => {
      try {
        setLoading(true);
        const patentId = sectorData.map((sector) => sector.Application_No_List);
        const patentIdObject = Object.values(patentId[0]);
        const formattedIds = patentIdObject && patentIdObject.join(",");
        const response = await axios.get(PATENT_SEARCH_IDS + formattedIds);
        setPatents(response.data);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    };
    fetchPatents();
  }, [sectorData]);

  const showData = () => {
    const sectorData = sectorState.sectorData[sectorId ??  sector];

    if (sectorState.sectorData[sectorId ?? sector]) {
      return (
        <div>
          {sectorData?.map((sector, index) => (
            <div className="flex flex-col w-full gap-4" key={index}>
              <div
                className="flex w-full items-center rounded-xl bg-white lg:px-8 sm:px-4 lg:py-6 sm:py-3 justify-between"
                style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
              >
                <div className="flex items-center gap-6">
                  {sector.Sectors_Url && (
                    <img
                      className="lg:w-[70px] lg:h-[70px] sm:w-[60px] sm:h-[60px] object-contain"
                      src={sector.Sectors_Url}
                    />
                  )}
                  <div className="flex flex-col ">
                    <p className="font-roboto lg:text-3xl sm:text-lg font-bold leading-9">
                      {sector.Sectors}
                    </p>
                  </div>
                </div>
                <div></div>
              </div>

              {/* details */}
              <div
                className={`w-full h-max bg-[#FAFAFA] rounded-xl ${activeStatement === "investorProfile" ? "active" : "hidden"
                  }`}
              >
                <div className="flex justify-between text-center w-full sm:overflow-auto lg:overflow-auto">
                  <div
                    className={`flex flex-col w-full h-full item lg:text-sm sm:text-xs items-center px-4 py-4 font-roboto rounded-ss-xl hover:bg-white ${activeSection === "patents"
                      ? "bg-white text-primaryBlue font-bold"
                      : "text-[#A1A1AA]"
                      }`}
                    onClick={() => handleSectionClick("patents")}
                  >
                    <div className="cursor-pointer sm:w-max lg:w-full">
                      Sector Innovations
                    </div>
                  </div>

                  <div
                    className={`flex flex-col w-full item lg:text-sm sm:text-xs items-center px-4 py-4  font-roboto hover:bg-white ${activeSection === "deals"
                      ? "bg-white text-primaryBlue font-bold"
                      : "text-[#A1A1AA]"
                      } `}
                    onClick={() => {
                      handleSectionClick("deals");
                      fetchSectorDeals();
                    }}
                  >
                    <div className="cursor-pointer sm:w-max lg:w-full">
                      Deals
                    </div>
                  </div>

                  <div
                    className={`flex flex-col w-full h-full item lg:text-sm sm:text-xs items-center  px-4 py-4  font-roboto hover:bg-white ${activeSection === "news"
                      ? "bg-white text-primaryBlue font-bold"
                      : "text-[#A1A1AA]"
                      } `}
                    onClick={() => {
                      handleSectionClick("news");
                      fetchSectorNews();
                    }}
                  >
                    <div className="cursor-pointer sm:w-max lg:w-full">
                      News
                    </div>
                  </div>
                </div>

                {/* deals */}
                <div
                  className={`section ${activeSection === "deals" ? "active" : "hidden"
                    } bg-white sm:w-full rounded-b-xl flex lg:p-8 sm:p-2 gap-5 flex-wrap w-full`}
                  style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
                >
                  {isLoading && (
                    <div className="w-full flex justify-center">
                      <Loader />
                    </div>
                  )}
                  <DealCard sortedData={sortedData.flat()} />
                </div>

                {/* news */}

                <div
                  className={`section ${activeSection === "news" ? "active" : "hidden"
                    } bg-white w-full rounded-b-xl`}
                  style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
                >
                  {isNewsLoading && (
                    <div className="flex items-center justify-center h-full">
                      <Loader />
                    </div>
                  )}
                  {!isNewsLoading && (
                    <div className="flex flex-col w-full gap-5">
                      {allArticlesData
                        ? allArticlesData.map((news, index) => {
                          if (!news || !news?.Unique_date_time) {
                            return null;
                          }
                          return (<IndividualNews news={news} />)
                        })
                        : "No results found :("}
                      <div className="flex justify-center p-3"></div>
                    </div>
                  )}
                </div>

                {/* patents */}
                <div
                  className={`section ${activeSection === "patents" ? "active" : "hidden"
                    } key_people bg-white w-full rounded-b-xl gap-8 flex flex-col sm:p-4 lg:p-8`}
                  style={{ boxShadow: "0px 0px 40px 0px rgba(0, 0, 0, 0.06)" }}
                >
                  <div
                    className="flex flex-col p-5 rounded-xl shadow-lg w-full"
                    style={{
                      boxShadow:
                        "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
                    }}
                  >
                    <p className="font-medium text-[25px] tracking-tight leading-9">
                      Innovations this week
                    </p>
                    <div className="overflow-hidden">
                    {sectorData && sectorData[0].Patent_SOS && (
                      <CarouselSlider data={sectorData[0]} />
                    )}
                    </div>
                  
                  </div>

                  {isLoading && (
                    <div className="w-full flex justify-center">
                      <Loader />
                    </div>
                  )}




                  <Patents patentData={patentsData} />

                  <a
                    className="flex flex-col items-center"
                    href="/patent_intelligence/patent_search"
                  >
                    <CTA text={"Search for more Patents"} />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    }

    if (sectorState.loading) {
      return (
        <div className="flex flex-col items-center h-screen">
          {" "}
          <Loader />{" "}
        </div>
      );
    }

    if (sectorState.errorMsg !== "") {
      return <p>{sectorState.errorMsg}</p>;
    }

    return <p>Error getting sectors Data</p>;
  };

  return <div className="px-4 py-0 sm:w-full lg:w-full">{showData()}</div>;
};

const mapStateToProps = (state) => ({
  sectorState: state.sector.sectorName,
});

// export default connect(mapStateToProps, { getSector })(SectorPage);
export default SectorPage;