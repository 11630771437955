import React from "react";
import linkedin from "../../assets/Icons/linkedin.svg";
import { goToCompanyPage } from "../../constant/function";

const Persion_Card = ({ persion }) => {
  const linkedin_url =
    persion?.Person_Data?.Linkedin !== "No"
      ? persion?.Person_Data?.Linkedin
      : undefined;
  const CIN = persion?.Person_Data?.Registration_Number;

  return (
    <div
      className="min-w-[450px] flex flex-col gap-4 bg-white py-3 px-4 items-center rounded-md hover:bg-gray-50 "
      style={{
        boxShadow: "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
      }}
    >
      <div className=" w-full flex gap-4 flex-row justify-between">
        <h1 className=" font-bold text-xl">{persion?.Person_Data?.Name}</h1>
        {linkedin_url && (
          <a
            href={linkedin_url}
            target="_blank"
            className=" cursor-pointer hover:scale-105 duration-200"
          >
            {" "}
            <img src={linkedin} alt="linkedin_url" />
          </a>
        )}
      </div>
      <div className="flex w-full flex-col items-start">
        {persion?.Person_Data?.Company_Name && (
          <h2
            className={` ${
              CIN && " cursor-pointer hover:text-primaryBlue hover:underline"
            } text-lg font-semibold`}
            onClick={() =>
              CIN &&
              goToCompanyPage(
                persion.Reference_Id,
                [persion.Person_Data.Company_Name]
              )
            }
          >
            {persion?.Person_Data?.Company_Name}
          </h2>
        )}
        <h2>{persion?.Person_Data?.Designation}</h2>
      </div>
    </div>
  );
};

export default Persion_Card;
