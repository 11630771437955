import React from "react";
import { ResponsivePie } from "@nivo/pie"
import { CompanyTypeGraphData as data } from "../../data/graphsData";



const Pie = () => (
    <ResponsivePie
        data={data}
        margin={{ top: 10, bottom: 20 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        colors={{ scheme: 'purpleRed_green'}}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.2
                ]
            ]
        }}
        enableArcLinkLabels={false}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        enableArcLabels={false}
        arcLabelsTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    2
                ]
            ]
        }}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
        fill={[
            {
                match: {
                    id: '1'
                },
                id: 'dots'
            },
            {
                match: {
                    id: '2'
                },
                id: 'dots'
            },
            {
                match: {
                    id: '3'
                },
                id: 'dots'
            },
            {
                match: {
                    id: '4'
                },
                id: 'dots'
            },
            {
                match: {
                    id: '5'
                },
                id: 'lines'
            },
            {
                match: {
                    id: '6'
                },
                id: 'lines'
            },
            {
                match: {
                    id: '7'
                },
                id: 'lines'
            },
            {
                match: {
                    id: '8'
                },
                id: 'lines'
            }
        ]}
        // legends={[
        //     {
        //         anchor: 'bottom-right',
        //         direction: 'column',
        //         justify: false,
        //         translateX: 40,
        //         translateY: 70,
        //         itemsSpacing: 5,
        //         itemWidth: 100,
        //         itemHeight: 18,
        //         itemTextColor: '#999',
        //         itemDirection: 'left-to-right',
        //         itemOpacity: 1,
        //         symbolSize: 18,
        //         symbolShape: 'circle',
        //         effects: [
        //             {
        //                 on: 'hover',
        //                 style: {
        //                     itemTextColor: '#000'
        //                 }
        //             }
        //         ]
        //     }
        // ]}
    />
)

export default Pie