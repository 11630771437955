import React, { useEffect } from "react";
import InvestorCard from "../../pages/InvestorCard";
import Loader from "./loader";
import { useSelector } from "react-redux";



const PotentialInvestors = ({ loading, getProposedInvestors, binput }) => {

  useEffect(() => {
    if (binput) {
      getProposedInvestors(binput);
    }
  }, [binput]);

  // useAddMetaTags(company?.Brand ?? company?.Company_Name, "Investors, Proposed Investors", company?.Keywords, company?.Inv_Description)


  const investorDetails = useSelector(
    (store) => store.company.investorDataDetails
  );


  return (

    <div className="flex flex-col gap-4 w-full bg-white lg:p-8 sm:p-4">
      <p className="font-medium text-[25px] tracking-tight leading-9">
        Potential Investors
      </p>
      {loading ? (
        <div className="h-screen flex items-center w-full justify-center">
          {" "}
          <Loader />{" "}
        </div>
      ) : (
        <>
          {investorDetails &&
            investorDetails.dealIdRecords &&
            investorDetails.sortedProposedInvestorsData &&
            investorDetails.invDetails.map((data, index) => (
              <InvestorCard
                dealIds={investorDetails.dealIdRecords[index]}
                data={data}
                sortedData={investorDetails.sortedProposedInvestorsData}
                index={index}
                key={index}
              />
            ))}
          <div className="w-full flex flex-col items-center pb-3">
            <a
              className="flex justify-center gap-12 text-sm text-white py-3 px-6 font-semibold rounded-full h-max hover:underline-none w-max"
              href="https://www.startupinvestors.ai/"
              target="_blank"
              style={{ backgroundColor: "rgba(62, 145, 238, 1)" }}
            >
              Get more investors..
            </a>
          </div>
        </>
      )}
    </div>
  );
};

export default PotentialInvestors;


{/* <div className="w-full flex flex-col items-center pb-3">
  To get a curated list of potential investors for this startup, please visit
  <a
    className="flex justify-center gap-12 text-sm text-white py-3 px-6 font-semibold rounded-full h-max hover:underline-none w-max"
    href="https://www.startupinvestors.ai/"
    target="_blank"
    style={{ backgroundColor: "rgba(62, 145, 238, 1)" }}
  >
    <img src={startUpLogo} alt="" />
  </a>

</div> */}