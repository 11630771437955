import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  COMPANY_SEARCH_LOADING,
  COMPANY_SEARCH_FAIL,
  COMPANY_SEARCH_SUCCESS,
  FINANCIALS_DATA_SUCCESS,
  PATENTS_DATA_SUCCESS,
  INVESTOR_SEARCH_SUCCESS,
  INVESTOR_DETAILS_SUCCESS,
  PERSON_DATA_SUCCESS,
  ESG_CSR_SUCCESS,
  COMPETITOR_DETAILS_SUCCESS,
  DEALS_DATA_SUCCESS,
  EOD_REPORTS_DATA_SUCCESS
} from "../../store/slices/companyReducer";
import {
  GET_COMPANY,
  GET_PATENTS,
  GET_PROPOSED_INVESTORS,
  GET_KEY_PEOPLE,
  GET_ESG_CSR,
} from "../Queries/companyQueries";
import {
  GET_ALL_DEALS,
  GET_EOD_REPORTS,
  GET_INVESTORS,
  GROOT_BRAND_DATA,
  GROOT_DEAL_ID,
  GROOT_FINANCIALS,
} from "../../constant/api";

const useFetchCompany = (input, binput) => {
  const dispatch = useDispatch();
  const doesCompanyExist = useSelector(
    (store) => store.company.companyData[input]
  );

  const doesBrandExist = useSelector(
    (state) => state.company.brandData[binput]
  );

  const doesProposedInvestorExist = useSelector(
    (store) => store.company.investorData[binput]
  );

  const doesFinancialsExist = useSelector(
    (store) => store.company.financialsData[input]
  );

  const doesPatentExist = useSelector(
    (store) => store.company.patentsData[input]
  );

  const doesPersonExist = useSelector(
    (store) => store.company.keyPeopleData[input]
  );

  const doesESGExist = useSelector((store) => store.company.esgCsrData[input]);

  const getCompany = async (input, binput) => {
    const options1 = {
      url: `https://factacy.io/graphql`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": "1234",
      },
      data: {
        query: GET_COMPANY.loc.source.body,
        variables: {
          id: input ?? "null",
          // bid: binput // Pass the input as the id variable
          bid: binput ?? "null",
        },
      },
    };
    try {
      dispatch(COMPANY_SEARCH_LOADING());
      if (!doesCompanyExist && !doesBrandExist) {
        const companyResponse = await axios(options1);
        if (
          companyResponse.data.data.company ||
          companyResponse.data.data.leidata ||
          companyResponse.data.data.brands
        ) {
          dispatch(
            COMPANY_SEARCH_SUCCESS({
              companyData:
                companyResponse?.data?.data?.company ??
                companyResponse?.data?.data?.leidata,
              brandData: companyResponse?.data?.data?.brands,
              newsData: companyResponse?.data?.data?.businessnews_brand,
              competitorData:
                companyResponse?.data?.data?.competitorBrandData
                  ?.Competitors_Data,
              companyName: input,
              brandName: binput,
            })
          );
        } else {
          dispatch(COMPANY_SEARCH_FAIL());
        }

        var inputs =
          companyResponse?.data?.data?.competitorBrandData?.Competitors_Data
            ?.Recommended_Company &&
          Object.values(
            companyResponse?.data?.data?.competitorBrandData?.Competitors_Data
              ?.Recommended_Company
          )?.map((values) => values?.[0]);

        inputs = inputs?.filter((item) => item != null)?.join(",");

        try {
          const res = await axios.get(
           GROOT_BRAND_DATA + inputs
          );

          dispatch(
            COMPETITOR_DETAILS_SUCCESS({
              competitorDetails: res.data,
              companyName: input,
            })
          );
        } catch (e) {}
      }
    } catch (e) {
      // dispatch(COMPANY_SEARCH_FAIL());
    }

    return "";
  };

  const getProposedInvestors = async (binput) => {
    const options1 = {
      url: `https://factacy.io/graphql`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": "1234",
      },
      data: {
        query: GET_PROPOSED_INVESTORS.loc.source.body,
        variables: {
          bid: binput, // Pass the input as the id variable
        },
      },
    };
    if (!doesProposedInvestorExist) {
      try {
        dispatch(COMPANY_SEARCH_LOADING());
        const companyResponse = await axios(options1);

        const formattedInv =
          companyResponse?.data?.data?.proposed_Brand_investor?.Investor_Data?.Proposed_Investors.slice(
            0,
            10
          )
            .map((item) => item)
            .join(",");

        const response = await axios.get(GET_INVESTORS + formattedInv);

        if (response.data) {
          dispatch(COMPANY_SEARCH_LOADING());
          const dealIds = response.data
            ?.map((item) => item.Investor_Deal_Ids.slice(0, 10))
            .filter(Boolean);

          const formattedIds = dealIds && dealIds.flat(1).join(",");
          try {
            const responses = await axios.get(GET_ALL_DEALS + formattedIds);

            const sortedData = responses.data?.slice().sort((a, b) => {
              const lastDateA =
                a.Unique_date_time && a.Unique_date_time.length > 0
                  ? a.Unique_date_time[a.Unique_date_time.length - 1]
                  : null;
              const lastDateB =
                b.Unique_date_time && b.Unique_date_time.length > 0
                  ? b.Unique_date_time[b.Unique_date_time.length - 1]
                  : null;

              if (lastDateA !== null && lastDateB !== null) {
                return lastDateB - lastDateA; // Sort in descending order based on epoch time
              } else if (lastDateA === null && lastDateB !== null) {
                return 1; // Put empty or non-existent dates at the end
              } else if (lastDateA !== null && lastDateB === null) {
                return -1; // Put empty or non-existent dates at the end
              }
              return 0;
            });

            const store = {};
            sortedData?.forEach((item) => (store[item.id] = item));

            dispatch(
              INVESTOR_SEARCH_SUCCESS({
                investorData:
                  companyResponse?.data?.data?.proposed_Brand_investor
                    ?.Investor_Data,
                companyName: input,
              })
            );
            dispatch(INVESTOR_DETAILS_SUCCESS({ dealIdRecords: dealIds }));

            dispatch(
              INVESTOR_DETAILS_SUCCESS({ sortedProposedInvestorsData: store })
            );
            dispatch(INVESTOR_DETAILS_SUCCESS({ invDetails: response.data }));
          } catch (err) {
            console.log(err);
          }
        }
      } catch (e) {}
    }
  };

  const getFinancials = async (input) => {
    const options2 = {
      url: GROOT_FINANCIALS,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/txt;charset=UTF-8",
      },
      params: { input },
      data: {},
    };
    if (!doesFinancialsExist) {
      try {
        dispatch(COMPANY_SEARCH_LOADING());
        const financialsResponse = await axios(options2);

        const dealIdsResponse = await axios.get(GROOT_DEAL_ID + input);

        const eodReports = await axios.get(GET_EOD_REPORTS + input)

        dispatch(
          EOD_REPORTS_DATA_SUCCESS({
            eodReportsData: eodReports.data,
            companyName: input
          })
        )

        
        dispatch(
          DEALS_DATA_SUCCESS({
            dealsData: dealIdsResponse.data,
            companyName: input,
          })
        );

        dispatch(
          FINANCIALS_DATA_SUCCESS({
            financialsData: financialsResponse?.data,
            companyName: input,
          })
        );
      } catch (e) {
        dispatch(COMPANY_SEARCH_FAIL());
      }
    }
  };

  const getPatentsData = async (input) => {
    const options3 = {
      url: `https://factacy.io/graphql`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": "1234",
      },
      data: {
        query: GET_PATENTS.loc.source.body,
        variables: {
          id: input, // Pass the input as the id variable
        },
      },
    };
    if (!doesPatentExist) {
      try {
        dispatch(COMPANY_SEARCH_LOADING());
        const patentResponse = await axios(options3);
        dispatch(
          PATENTS_DATA_SUCCESS({
            data: patentResponse.data.data?.getPatent,
            companyName: input,
          })
        );
      } catch (e) {}
    }
  };

  const getPersonData = async (input) => {
    const options3 = {
      url: `https://factacy.io/graphql`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": "1234",
      },
      data: {
        query: GET_KEY_PEOPLE.loc.source.body,
        variables: {
          id: input, // Pass the input as the id variable
        },
      },
    };
    if (!doesPersonExist) {
      try {
        dispatch(COMPANY_SEARCH_LOADING());
        const personResponse = await axios(options3);
        dispatch(
          PERSON_DATA_SUCCESS({
            keyPeopleData: personResponse.data.data.persondata,
            companyName: input,
          })
        );
      } catch (e) {}
    }
  };

  const getEsgData = async (input) => {
    const options3 = {
      url: `https://factacy.io/graphql`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": "1234",
      },
      data: {
        query: GET_ESG_CSR.loc.source.body,
        variables: {
          id: input, // Pass the input as the id variable
        },
      },
    };

    if (!doesESGExist) {
      try {
        dispatch(COMPANY_SEARCH_LOADING());
        const esgResponse = await axios(options3);
        dispatch(
          ESG_CSR_SUCCESS({
            esgCsrData: esgResponse.data.data.esgcompany,
            companyName: input,
          })
        );
      } catch (e) {}
    }
  };

  return {
    getCompany,
    getPatentsData,
    getFinancials,
    getProposedInvestors,
    getPersonData,
    getEsgData,
  };
};

export default useFetchCompany;
