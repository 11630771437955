import axios from "axios";
import React, { useEffect, useState } from "react";
import arrow from "../../assets/sidebar assets/arrow.svg";
import factacy from "../../assets/Factacy-grayLogomark (1).png";
import { useAuth } from "../auth/authContext";
import LoginButton from "../auth/loginButton";
import { goToCompanyPage } from "../../constant/function";
import { SHARK_TANK_COMPANIES } from "../../constant/api";

const Item = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);

  const logo = data?.Logo ?? factacy;
  const Reference_Id =  data?.Reference_Id;
  const MCA_COMPANY_NAME =  data?.MCA_COMPANY_NAME;
  const AKA_BRAND_NAME =  data?.AKA_BRAND_NAME;
  const MCA_CIN =  data?.MCA_CIN;
  const Website_URL = data?.Website_URL;

  return (
    <div
      className="lg:flex w-full justify-evenly py-3 px-4 border-b-[1px] hover:bg-gray-100 bg-white">
      <div className="flex-1 flex justify-between">
        <div className="flex gap-2 w-full">
          <img
            src={data?.Logo ?? factacy}
            className="w-12 h-6 object-contain"
          />
          <p
            className="w-full hover:text-primaryBlue hover:underline cursor-pointer"
            onClick={() => data?.Reference_Id && data?.MCA_COMPANY_NAME && goToCompanyPage(data?.Reference_Id, [data?.MCA_COMPANY_NAME])}
          >
            {data?.AKA_BRAND_NAME}
          </p>
        </div>
        <p
          className="w-full px-3 hover:text-primaryBlue hover:underline cursor-pointer"
          onClick={() => data?.Reference_Id && data?.MCA_COMPANY_NAME && goToCompanyPage(data?.Reference_Id, [data?.MCA_COMPANY_NAME])}
        >
          {data?.MCA_COMPANY_NAME !== "No"
            ? data?.MCA_COMPANY_NAME
            : "-"}
        </p>
        <img src={arrow} className={`h-6 lg:hidden cursor-pointer ${isOpen ? "rotate-180" : ""}`} onClick={() => setIsOpen(pre => !pre)} alt={"dropdownIcon"} />
      </div>
      <div className="flex-1 justify-between sm:hidden lg:flex">
        <p className="w-full px-3">
          {data?.MCA_CIN !== "No" ? data?.MCA_CIN : "-"}
        </p>
        {/* <p className='w-full hover:text-primaryBlue hover:underline cursor-pointer'>{data?.Website_URL   !== 'No' ? data?.Website_URL : "-"}</p> */}
        <a
          className="cursor-pointer w-1/5"
          target="_blank"
          rel="noreferrer"
          href={data?.Website_URL}
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 22.9058C6.47715 22.9058 2 18.4286 2 12.9058C2 7.38291 6.47715 2.90576 12 2.90576C17.5228 2.90576 22 7.38291 22 12.9058C22 18.4286 17.5228 22.9058 12 22.9058ZM9.71002 20.5732C8.74743 18.5317 8.15732 16.28 8.02731 13.9058H4.06189C4.458 17.0823 6.71639 19.6805 9.71002 20.5732ZM10.0307 13.9058C10.1811 16.3446 10.8778 18.6355 12 20.6578C13.1222 18.6355 13.8189 16.3446 13.9693 13.9058H10.0307ZM19.9381 13.9058H15.9727C15.8427 16.28 15.2526 18.5317 14.29 20.5732C17.2836 19.6805 19.542 17.0823 19.9381 13.9058ZM4.06189 11.9058H8.02731C8.15732 9.53153 8.74743 7.27983 9.71002 5.23832C6.71639 6.13109 4.458 8.72926 4.06189 11.9058ZM10.0307 11.9058H13.9693C13.8189 9.46698 13.1222 7.17601 12 5.15375C10.8778 7.17601 10.1811 9.46698 10.0307 11.9058ZM14.29 5.23832C15.2526 7.27983 15.8427 9.53153 15.9727 11.9058H19.9381C19.542 8.72926 17.2836 6.13109 14.29 5.23832Z"
              fill="#3E91EE"
            />
          </svg>
        </a>
      </div>
      {isOpen && <div className="mt-4 sm:flex flex-col lg:hidden flex-1 justify-between">
        <p className=""><span className="font-medium pr-2">MCA CIN:</span>{data?.MCA_CIN !== "No" ? data?.MCA_CIN : "-"}</p>
        <div className=" flex gap-1">
          <span className="font-medium pr-2">Website:</span>
          <a
            className="cursor-pointer w-1/5"
            target="_blank"
            rel="noreferrer"
            href={data?.Website_URL}
          >
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 22.9058C6.47715 22.9058 2 18.4286 2 12.9058C2 7.38291 6.47715 2.90576 12 2.90576C17.5228 2.90576 22 7.38291 22 12.9058C22 18.4286 17.5228 22.9058 12 22.9058ZM9.71002 20.5732C8.74743 18.5317 8.15732 16.28 8.02731 13.9058H4.06189C4.458 17.0823 6.71639 19.6805 9.71002 20.5732ZM10.0307 13.9058C10.1811 16.3446 10.8778 18.6355 12 20.6578C13.1222 18.6355 13.8189 16.3446 13.9693 13.9058H10.0307ZM19.9381 13.9058H15.9727C15.8427 16.28 15.2526 18.5317 14.29 20.5732C17.2836 19.6805 19.542 17.0823 19.9381 13.9058ZM4.06189 11.9058H8.02731C8.15732 9.53153 8.74743 7.27983 9.71002 5.23832C6.71639 6.13109 4.458 8.72926 4.06189 11.9058ZM10.0307 11.9058H13.9693C13.8189 9.46698 13.1222 7.17601 12 5.15375C10.8778 7.17601 10.1811 9.46698 10.0307 11.9058ZM14.29 5.23832C15.2526 7.27983 15.8427 9.53153 15.9727 11.9058H19.9381C19.542 8.72926 17.2836 6.13109 14.29 5.23832Z"
                fill="#3E91EE"
              />
            </svg>
          </a>
        </div>

      </div>}
    </div>
  )
}

const SharkTank = () => {
  const [sharkData, setsharkData] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    const fetchsharkData = async () => {
      try {
        const response = await axios.get(SHARK_TANK_COMPANIES);
        setsharkData(response.data);
      } catch { }
    };
    fetchsharkData();
  }, []);


  return (
    <div className="lg:pt-0 px-4 sm:pt-20 sm:w-screen lg:w-full flex flex-col">
      <div
        className="flex flex-col w-full bg-[#F4F5F7] rounded-xl lg:px-5 sm:px-4 lg:py-3 sm:py-3 justify-between gap-5"
        style={{
          boxShadow: "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
        }}
      >
        <p className="font-medium text-[25px] tracking-tight leading-9">
          Sharks Tank Companies
        </p>
        {user.isAuthenticated === true ? (
          <div className="border-2 rounded-xl">
            <div className="flex w-full justify-evenly py-3 px-4 bg-slate-200 rounded-t-xl font-semibold">
              <p className="w-full">Name</p>
              <p className="w-full px-3">Legal Name</p>
              <p className="w-full hidden lg:block px-3">MCA CIN</p>
              <p className="w-1/5 hidden lg:block">Website</p>
            </div>

            {sharkData.map((shark, index) => <Item key={index} data={shark} />)}
          </div>
        ) : (
          <div className="flex items-center justify-center flex-col gap-3 h-screen">
            Please login first <LoginButton className="w-5" />
          </div>
        )}
      </div>
    </div>
  );
};

export default SharkTank;
