import { createSlice } from "@reduxjs/toolkit";

// initialState: {
//     news: { "art-id": news-objs},
//     fundingNews: ["art-ids-list"]],
//     companyNews: {
//         "companyName": ["art-ids-list"],
//     },
//     esgNews: ["art-ids-list"]],
//     sectorsNews: {
//         "sectorName": ["art-ids-list"],
//     }
// }

const news = createSlice({
    name: "news_intelligence",
    initialState: {
        news: {},
        homeNews: [],
        fundingNews: [],
        dealsNews: [],
        companyNews: {
        },
        esgNews: [],
        sectorsNews: {
        }
    },
    reducers: {
        ADD_HOME_NEWS: (state, action) => {
            const { newslist } = action.payload;
            const NewsListAsObject = {};
            const Art_Ids = newslist?.map((item) => {
                NewsListAsObject[item?.Art_Id] = item;
                return item?.Art_Id
            });
            return { ...state, news: { ...state.news, ...NewsListAsObject }, homeNews: [...Art_Ids] };
        },
        ADD_FUNDING_NEWS: (state, action) => {
            const { newslist } = action.payload;
            const NewsListAsObject = {};
            const Art_Ids = newslist?.map((item) => {
                NewsListAsObject[item?.Art_Id] = item;
                return item?.Art_Id
            });

            return { ...state, news: { ...state.news, ...NewsListAsObject }, fundingNews: [...Art_Ids] };
        },
        ADD_DEALS_NEWS: (state, action) => {
            const { newslist } = action.payload;
            const Art_Ids = newslist?.map(item => item?.Art_Id);
            return { ...state, dealsNews: [...Art_Ids] };
        },
        ADD_COMPANY_NEWS: (state, action) => {
            const { company, newslist } = action.payload;
            return { ...state, companyNews: { ...state.companyNews, [company]: [...newslist] } }
        },
        ADD_ESG_NEWS: (state, action) => {

            const { newslist } = action.payload;
            const NewsListAsObject = {};
            const Art_Ids = newslist?.map((item) => {
                NewsListAsObject[item?.Art_Id] = item;
                return item?.Art_Id
            });

            return { ...state, news: { ...state.news, ...NewsListAsObject }, esgNews: [...Art_Ids] };
        },
        ADD_SECTOR_NEWS: (state, action) => {
            const { sectorName, newslist } = action.payload;

            const NewsListAsObject = {};
            const Art_Ids = newslist?.map((item) => {
                NewsListAsObject[item?.Art_Id] = item;
                return item?.Art_Id
            });

            return { ...state, news: { ...state.news, ...NewsListAsObject }, sectorsNews: { ...state.sectorsNews, [sectorName]: [...Art_Ids] } }
        },
        ADD_INDIVIDUAL_NEWS: (state, action) => {
            const { Art_Id, news } = action.payload;
            return { ...state, news: { ...state.news, [Art_Id]: news } }
        },
        ADD_MULTIPLE_NEWS: (state, action) => {
            const { newslist } = action.payload;
            const NewsListAsObject = {};
            newslist?.map((item) => {
                NewsListAsObject[item?.Art_Id] = item;
            });
            return { ...state, news: { ...state.news, ...NewsListAsObject } }
        }
    }
});

export const { ADD_FUNDING_NEWS, ADD_DEALS_NEWS, ADD_COMPANY_NEWS, ADD_ESG_NEWS, ADD_SECTOR_NEWS, ADD_HOME_NEWS, ADD_INDIVIDUAL_NEWS, ADD_MULTIPLE_NEWS } = news.actions;
export default news.reducer;