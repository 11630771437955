import { createSlice } from "@reduxjs/toolkit";

const companyReducer = createSlice({
  name: "companyReducer",
  initialState: {
    loading: false,
    patentLoading: false,
    companyCinLoading: false,
    data: {},
    news: [],
    errorMsg: "",
    input: "",
    companyData: [],
    brandData: [],
    newsData: [],
    competitorData: [],
    investorData: [],
    investorDataDetails: {
      invDetails: [],
      dealIdRecords: [],
      sortedProposedInvestorsData: [],
    },
    keyPeopleData: [],
    financialsData: [],
    dealsData: [],
    eodReportsData: [],
    patentsData: [],
    esgCsrData: [],
    competitorDetails: [],
  },
  reducers: {
    COMPANY_SEARCH_LOADING: (state) => {
      return { ...state, loading: true, errorMsg: "" };
    },
    COMPANY_SEARCH_FAIL: (state) => {
      return { ...state, loading: false, errorMsg: "Unable to find company" };
    },
    COMPANY_SEARCH_SUCCESS: (state, action) => {
      const {
        companyData,
        newsData,
        companyName,
        competitorData,
        brandData,
        brandName,
      } = action.payload;
      return {
        ...state,
        loading: false,
        errorMsg: "",
        companyData: { ...state.companyData, [companyName]: companyData },
        brandData: { ...state.brandData, [brandName]: brandData },
        newsData: { ...state.newsData, [companyName]: newsData },
        competitorData: {
          ...state.competitorData,
          [companyName]: competitorData,
        },
      };
    },

    STORE_NEWS_INSIGHTS: (state, action) => {
      const { news } = action.payload;
      return {
        ...state,
        loading: false,
        errorMsg: "",
        news: [...state.news, news],
      };
    },

    FINANCIALS_DATA_SUCCESS: (state, action) => {
      const { financialsData, companyName } = action.payload;
      return {
        ...state,
        loading: false,
        errorMsg: "",
        financialsData: {
          ...state.financialsData,
          [companyName]: [financialsData],
        },
      };
    },

    DEALS_DATA_SUCCESS: (state, action) => {
      const { companyName, dealsData } = action.payload;
      return {
        ...state,
        loading: false,
        errorMsg: "",
        dealsData: {
          ...state.dealsData,
          [companyName]: dealsData,
        },
      };
    },

    EOD_REPORTS_DATA_SUCCESS: (state, action) => {
      const { companyName, eodReportsData } = action.payload;
      return {
        ...state,
        loading: false,
        errorMsg: "",
        eodReportsData: {
          ...state.eodReportsData,
          [companyName]: eodReportsData,
        },
      };
    },

    

    INVESTOR_SEARCH_SUCCESS: (state, action) => {
      const { companyName, investorData } = action.payload;
      return {
        ...state,
        loading: false,
        errorMsg: "",
        investorData: { ...state.investorData, [companyName]: investorData },
      };
    },
    INVESTOR_DETAILS_SUCCESS: (state, action) => {
      const { invDetails, dealIdRecords, sortedProposedInvestorsData } =
        action.payload;
      return {
        ...state,
        loading: false,
        errorMsg: "",
        investorDataDetails: {
          ...state.investorDataDetails,
          invDetails: invDetails || state.investorDataDetails.invDetails,
          dealIdRecords:
            dealIdRecords || state.investorDataDetails.dealIdRecords,
          sortedProposedInvestorsData:
            sortedProposedInvestorsData ||
            state.investorDataDetails.sortedProposedInvestorsData,
        },
      };
    },

    PATENTS_DATA_SUCCESS: (state, action) => {
      const { data, companyName } = action.payload;
      return {
        ...state,
        loading: false,
        errorMsg: "",
        patentsData: { ...state.patentsData, [companyName]: data },
      };
    },

    PERSON_DATA_SUCCESS: (state, action) => {
      const { companyName, keyPeopleData } = action.payload;
      return {
        ...state,
        loading: false,
        errorMsg: "",
        keyPeopleData: { ...state.keyPeopleData, [companyName]: keyPeopleData },
      };
    },

    ESG_CSR_SUCCESS: (state, action) => {
      const { companyName, esgCsrData } = action.payload;
      return {
        ...state,
        loading: false,
        errorMsg: "",
        esgCsrData: { ...state.esgCsrData, [companyName]: esgCsrData },
      };
    },

    COMPETITOR_DETAILS_SUCCESS: (state, action) => {
      const { companyName, competitorDetails } = action.payload;
      return {
        ...state,
        loading: false,
        errorMsg: "",
        competitorDetails: {
          ...state.competitorDetails,
          [companyName]: competitorDetails,
        },
      };
    },
  },
});

export const {
  COMPANY_SEARCH_LOADING,
  COMPANY_SEARCH_FAIL,
  COMPANY_SEARCH_SUCCESS,
  FINANCIALS_DATA_SUCCESS,
  PATENTS_DATA_SUCCESS,
  DEALS_DATA_SUCCESS,
  EOD_REPORTS_DATA_SUCCESS,
  STORE_NEWS_INSIGHTS,
  INVESTOR_SEARCH_SUCCESS,
  INVESTOR_DETAILS_SUCCESS,
  PERSON_DATA_SUCCESS,
  ESG_CSR_SUCCESS,
  COMPETITOR_DETAILS_SUCCESS,
} = companyReducer.actions;

export default companyReducer.reducer;

